import Http from '../libs/http';

class QrMenuCategoryApi extends Http {
   index() {
      return super.get('panel/qrmenu/category');
   }

   store(payload) {
      return super.post('panel/qrmenu/category', payload);
   }

   update(id, payload) {
      return super.put(`panel/qrmenu/category/${id}`, payload);
   }

   delete(id) {
      return super.delete(`panel/qrmenu/category/${id}`);
   }
}

export default new QrMenuCategoryApi();
