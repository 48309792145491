import Form from '@/libs/form';

const fields = [
   'id',
   'title',
   'description',
   'description_long',
   'title_en',
   'description_en',
   'description_long_en',
   'allergens',
   'ingredients',
   'allergens_en',
   'ingredients_en',
   'product_time',
   'calorie',
   'status',
   'category_ids',
];

const rules = {
   title: 'required',
};

export default Form.create(fields)
   .validate(rules)
   .defaults({ status: 1, category_ids: [] });
