<template>
   <ObiPage>
      <ObiToolbar />

      <ObiCard class="my-4">
         <template #header>
            <ObiTitle :icon="IconLocation" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalLocationCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>

         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataLocations.get('data')"
               v-if="!isLoading && dataLocations.get('meta.total')"
            >
               <template #address="{ item: location }">{{ location.getLocationFormatted() }}</template>
               <template #created_at="{ item: location }">{{ trFormatDate(location.getCreatedAt()) }}</template>
               <template #actions="{ item: location }">
                  <ObiButton
                     size="sm"
                     color="lighter"
                     as="router-link"
                     :text="$t('actions.edit')"
                     :to="{ name: 'locations-edit', params: { locationId: location.getId() } }"
                  />
                  <ObiButton
                     size="sm"
                     color="danger"
                     :text="$t('actions.delete')"
                     v-confirm="{
                        loader: true,
                        okText: 'Evet',
                        cancelText: 'Hayır',
                        ok: (dialog) => deleteLocation(dialog, location),
                        message: 'Lokasyonu silmek istediğinize emin misiniz?',
                     }"
                  />
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoading && !dataLocations.get('meta.total')" icon="locations">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalLocationCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoading" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataLocations.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalLocationCreate ref="modalLocationCreate" @success="loadLocations" />
   </ObiPage>
</template>

<script>
import merge from 'lodash/merge';

import IconLocation from '@/components/Icons/general/IconLocation';
import ModalLocationCreate from '@/views/pages/Location/_Modals/Create';

import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';

import Location from '@/api/Models/Location';

import PanelLocationApi from '@/api/PanelLocationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

export default {
   name: 'LocationIndex',
   i18n: {
      messages: {
         tr: {
            page_title: 'Konumlarım',
            actions: {
               create: 'Konum Ekle',
               edit: 'Düzenle',
               delete: 'Sil',
            },
            fields: {
               name: 'Konum Adı',
               address: 'Lokasyon',
               portal: 'Portal',
               created_at: 'Oluşturma Tarihi',
               actions: 'İşlemler',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               googlemap_autocomplete_placeholder: 'Haritada arayın..',
            },
         },
         en: {
            page_title: 'My Locations',
            actions: {
               create: 'Add Location',
               edit: 'Edit',
               delete: 'Delete',
            },
            fields: {
               name: 'Location Name',
               address: 'Location Address',
               portal: 'Portal',
               created_at: 'Creation Date',
               actions: 'Actions',
               country: 'Country',
               city: 'City',
               district: 'District',
               googlemap_autocomplete_placeholder: 'Search on the map...',
            },
         },
      },
   },
   components: {
      ModalLocationCreate,
   },
   data() {
      return {
         IconLocation,
         isLoading: false,
         dataLocations: PaginationResponse.create().map(Location),
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.address'),
               value: 'address',
               sortable: true,
            },
            {
               text: this.$t('fields.portal'),
               value: 'portal.name',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               width: 200,
               class: 'text-end',
            },
         ],
      };
   },
   async mounted() {
      await this.loadLocations();
   },
   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.$router.push({ query: { ...this.$route.query, page } });
      },

      async loadLocations(params) {
         try {
            this.isLoading = true;

            params = merge(this.$route.query, params);

            this.dataLocations.merge(await PanelLocationApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async deleteLocation(dialog, location) {
         try {
            this.isLoading = true;
            await PanelLocationApi.delete(location.getId(), null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            dialog.close();
            this.loadLocations();
         }
      },
   },
   watch: {
      async $route(to) {
         await this.loadLocations({ page: to.query.page });
      },
   },
};
</script>

<style lang="scss" scoped></style>
