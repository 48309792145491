import LayoutAuth from '@/views/layouts/Auth';
import AuthLogin from '@/views/pages/Auth/Login';
import AuthRegister from '@/views/pages/Auth/Register';
import AuthComplete from '@/views/pages/Auth/Complete';

import AuthForgotPassword from '@/views/pages/Auth/ForgotPassword';

export default {
   path: '/auth',
   component: LayoutAuth,
   children: [
      {
         path: 'login',
         name: 'auth-login',
         component: AuthLogin,
      },
      {
         path: 'register',
         name: 'auth-register',
         component: AuthRegister,
      },
      {
         path: 'complete',
         name: 'auth-complete',
         component: AuthComplete,
      },
      {
         path: 'forgot-password',
         name: 'auth-forgot-password',
         component: AuthForgotPassword,
      },
   ],
};
