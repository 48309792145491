import Http from '@/libs/http';

class PanelReportApi extends Http {
   multipleSessions(params) {
      return super.get('panel/reports/multiple-sessions', { params });
   }
   sessions(params) {
      return super.get('panel/reports/sessions', { params });
   }
   salesByMonth(params) {
      return super.get('panel/reports/total-sales-by-month', { params });
   }

   salesCurrentMonth(params) {
      return super.get('panel/reports/total-sales-current-month', { params });
   }

   salesCurrentWeek(params) {
      return super.get('panel/reports/total-sales-week', { params });
   }

   salesToday(params) {
      return super.get('panel/reports/total-sales-today', { params });
   }

   salesYear(params) {
      return super.get('panel/reports/total-sales-current-year', { params });
   }
}

export default new PanelReportApi();
