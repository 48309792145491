<template>
   <ObiPage>
      <ObiCard>
         <template #header>
            <ObiTitle :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalLocationUserProfileCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>
         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataLocationUserProfiles.get('data')"
               v-if="!isLoadingUserProfiles && dataLocationUserProfiles.get('meta.total')"
            >
               <template #created_at="{ item: userProfile }">{{ trFormatDate(userProfile.getCreatedAt()) }}</template>
               <template #actions="{ item: userProfile }">
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiButton
                           size="sm"
                           color="lighter"
                           :text="$t('actions.edit')"
                           @click="openEditUserProfileModal(userProfile)"
                        />
                     </ObiCol>

                     <ObiCol col="6">
                        <v-popover>
                           <ObiButton :loading="isDeleting" size="sm" color="danger" :text="$t('actions.delete')" />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_to_delete')" />
                              <ObiButton
                                 class="me-2"
                                 :loading="isDeleting"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 @click="deleteUserProfile(userProfile)"
                                 v-close-popover
                              />
                              <ObiButton
                                 :loading="isDeleting"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoadingUserProfiles && !dataLocationUserProfiles.get('meta.total')" icon="location">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalLocationUserProfileCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoadingUserProfiles" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataLocationUserProfiles.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalLocationUserProfileCreate ref="modalLocationUserProfileCreate" @success="loadLocationUserProfiles" />
      <ModalLocationUserProfileUpdate ref="modalLocationUserProfileUpdate" @success="loadLocationUserProfiles" />
   </ObiPage>
</template>

<script>
import map from 'lodash/map';
import join from 'lodash/join';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import { trFormatDate } from '@/libs/date-utils';

import LocationUserProfile from '@/api/Models/LocationUserProfile';

import PanelLocationApi from '@/api/PanelLocationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconLocation from '@/components/Icons/general/IconLocation';
import ModalLocationUserProfileCreate from '@/views/pages/Location/_Modals/UserProfileCreate';
import ModalLocationUserProfileUpdate from '@/views/pages/Location/_Modals/UserProfileUpdate';
//import UserProfileFormData from '@/views/pages/Location/FormData/form-data-userProfile';

export default {
   components: {
      ModalLocationUserProfileCreate,
      ModalLocationUserProfileUpdate,
   },

   i18n: {
      messages: {
         tr: {
            page_title: 'Kullanıcı Profilleri Listesi',
            are_you_sure_to_delete: 'Kullanıcı Profilini tanımını silmek istediğinize emin misiniz?',
            fields: {
               name: 'Adı',
               start_ip: 'IP',
               created_at: 'Oluşturulma Tarihi',
               actions: 'İşlemler',
               is_default: 'Varsayılan?',
            },
            actions: {
               create: 'Profil Ekle',
               edit: 'Düzenle',
               cancel: 'İptal Et',
               delete: 'Sil',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
         en: {
            page_title: 'User Profile List',
            are_you_sure_to_delete: 'Are you sure you want to delete the user profile definition?',
            fields: {
               name: 'Name',
               start_ip: 'IP',
               created_at: 'Creation Date',
               actions: 'Actions',
               is_default: 'Default?',
            },
            actions: {
               create: 'Add Profile',
               edit: 'Edit',
               cancel: 'Cancel',
               delete: 'Delete',
               yes: 'Yes',
               no: 'No',
            },
         },
      },
   },
   data() {
      return {
         IconLocation,
         isLoadingUserProfiles: false,
         isLoadingStore: false,
         showAddUserModal: false,
         isDeleting: false,
         isChanginDefaultProfile: false,
         editingUserProfile: null,
         dataLocationUserProfiles: PaginationResponse.create().map(LocationUserProfile),
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.is_default'),
               value: 'is_default',
               class: 'text-center',
               sortable: false,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
      };
   },

   async mounted() {
      this.loadLocationUserProfiles();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadLocationUserProfiles({ page });
      },

      async loadLocationUserProfiles() {
         this.isLoadingUserProfiles = true;

         try {
            this.dataLocationUserProfiles.merge(await PanelLocationApi.userProfiles.index());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingUserProfiles = false;
         }
      },
      async deleteUserProfile(userProfile) {
         this.isDeleting = true;

         try {
            await PanelLocationApi.userProfiles.delete(userProfile.getId());
            Notify.success(this.$t('UserProfile silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isDeleting = false;
            this.loadLocationUserProfiles();
         }
      },

      async hidePopup() {
         this.$refs['delete_popper'].doClose();
      },

      openEditUserProfileModal(userProfile) {
         this.$refs.modalLocationUserProfileUpdate.show(userProfile);
      },

      formatRoles(roles) {
         return join(
            map(roles, (role) => role.getName()),
            ', '
         );
      },
   },
};
</script>

<style lang="scss" scoped></style>
