import BaseModel from './BaseModel';

export default class DeviceNetwork extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getStartIp() {
      return this.get('start_ip');
   }

   getSubnet() {
      return this.get('subnet');
   }

   getPortal() {
      return this.get('portal');
   }

   getProfile() {
      return this.get('profile');
   }
}
