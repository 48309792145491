<template>
   <div class="page-login">
      <ObiImage class="tenant-logo" :src="tenant.login.image.src" />
      <ObiText as="h1" class="login-title" v-html="tenant.login.title[language]" />
      <ObiText class="login-description" v-html="tenant.login.description[language]" />

      <ObiForm @submit.prevent="submitLogin">
         <div class="my-4">
            <ObiFloatingInput
               type="email"
               name="email"
               :icon="IconMail"
               :disabled="isLoading"
               v-model="formDataLogin.email"
               :label="$t('login.fields.email')"
               :placeholder="$t('login.fields.email')"
               :errors="formDataLogin.errors.get('email')"
            />

            <ObiFloatingInput
               compact
               type="password"
               name="password"
               :icon="IconPassword"
               :disabled="isLoading"
               v-model="formDataLogin.password"
               :label="$t('login.fields.password')"
               :placeholder="$t('login.fields.password')"
               :errors="formDataLogin.errors.get('password')"
            />
         </div>

         <ObiText class="d-flex justify-content-between">
            <RouterLink
               class="btn btn-link"
               v-html="$t('login.forgot_password')"
               :to="{ name: 'auth-forgot-password' }"
            />

            <ObiButton
               icon-right
               type="submit"
               :loading="isLoading"
               icon="mdi mdi-arrow-right"
               :text="$t('login.login_button_title')"
            />
         </ObiText>
      </ObiForm>
   </div>
</template>

<script>
import IconMail from '@/components/Icons/form/IconMail';
import IconPassword from '@/components/Icons/form/IconPassword';

import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';

export default {
   name: 'PageLogin',
   data() {
      return {
         IconMail,
         IconPassword,
         isLoading: false,
         formDataLogin: Form.create(['email', 'password']).validate({ email: 'required|email', password: 'required' }),
      };
   },
   methods: {
      async submitLogin() {
         if (!this.formDataLogin.isValid({ disableDirtyCheck: true })) {
            return;
         }

         this.isLoading = true;

         try {
            const { data } = await this.$store.dispatch('auth/login', this.formDataLogin.toObject());

            if (!data.user.can_create_location) {
               await this.$router.push({ name: 'dashboard-index' });
            } else {
               await this.$router.push({ name: 'dashboard-admin' });
            }
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.page-login {
   text-align: center;

   .obi-form {
      margin: 0 auto;
      max-width: 400px;
   }

   .tenant-logo {
      max-width: 200px;
   }

   .login-title {
      color: #2f71f2;
      font-size: 20px;
      margin-top: 20px;
   }

   .login-description {
      color: #777d96;
      font-size: 13px;
   }

   .form-login {
      margin-top: 30px;
   }
}
</style>
