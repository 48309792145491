import Form from '@/libs/form';

const fields = [
   'landing_logo',
   'landing_background',
   'landing_background_image',
   'primary_color',
   'brand_name',
   'brand_long_name',
   'landing_title',
   'landing_copyright',
   'landing_slogan',
];

const rules = {
   brand_name: 'required',
   brand_long_name: 'required',
};

export default Form.create(fields).validate(rules);
