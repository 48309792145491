import Http from '../libs/http';

class QrMenuDeskApi extends Http {
   index() {
      return super.get('panel/qrmenu/desk');
   }

   store(payload) {
      return super.post('panel/qrmenu/desk', payload);
   }

   update(id, payload) {
      return super.put(`panel/qrmenu/desk/${id}`, payload);
   }

   delete(id) {
      return super.delete(`panel/qrmenu/desk/${id}`);
   }
}

export default new QrMenuDeskApi();
