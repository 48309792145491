<template>
   <ObiPage>
      <ObiRow>
         <ObiCol class="col-md-6">
            <ObiCard>
               <template #header>
                  <ObiTitle class="mb-4" title="İzinli MAC Listesi">
                     <template #actions>
                        <ObiButton size="lg" outline wider text="Yeni Ekle" @click="addNewBypassedMac" />
                     </template>
                  </ObiTitle>
                  <ObiRow>
                     <ObiInput
                        clean
                        compact
                        placeholder="Filtrele"
                        icon="mdi mdi-search"
                        @input="formatMAC"
                        @change="loadDeviceMacs"
                        v-model="filterMac"
                     />
                  </ObiRow>
               </template>
               <template #body>
                  <ObiTable
                     :headers="tableHeaders"
                     :data="dataFiltered('bypassed')"
                     v-if="!isLoadingMacs && dataFiltered('bypassed').length > 0"
                  >
                     <template #created_at="{ item: mac }">{{ trFormatDateLong(mac.getCreatedAt()) }}</template>
                     <template #actions="{ item: mac }">
                        <v-popover>
                           <ObiButton
                              compact
                              :loading="deletingMacs.indexOf(mac.getId()) > -1"
                              color="danger"
                              text="Sil"
                           />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 v-close-popover
                                 @click="deleteMac(mac)"
                              />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </template>
                  </ObiTable>
                  <Obi404 v-else-if="!isLoadingMacs && dataFiltered('bypassed').length === 0" icon="device" />
                  <ObiLoading v-if="isLoadingMacs" />
               </template>
            </ObiCard>
         </ObiCol>
         <ObiCol class="col-md-6">
            <ObiCard>
               <template #header>
                  <ObiTitle class="mb-4" title="Yasaklı MAC Listesi">
                     <template #actions>
                        <ObiButton size="lg" outline wider text="Yeni Ekle" @click="addNewBlockedMac" />
                     </template>
                  </ObiTitle>
                  <ObiRow>
                     <ObiInput
                        clean
                        compact
                        placeholder="Filtrele"
                        icon="mdi mdi-search"
                        @input="formatMACBlocked"
                        @change="loadDeviceMacs"
                        v-model="filterMacBlocked"
                     />
                  </ObiRow>
               </template>
               <template #body>
                  <ObiTable
                     :headers="tableHeaders"
                     :data="dataFiltered('blocked')"
                     v-if="!isLoadingMacs && dataFiltered('blocked').length > 0"
                  >
                     <template #created_at="{ item: mac }">{{ trFormatDateLong(mac.getCreatedAt()) }}</template>
                     <template #actions="{ item: mac }">
                        <v-popover>
                           <ObiButton
                              compact
                              :loading="deletingMacs.indexOf(mac.getId()) > -1"
                              color="danger"
                              text="Sil"
                           />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 v-close-popover
                                 @click="deleteMac(mac)"
                              />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </template>
                  </ObiTable>
                  <Obi404 v-else-if="!isLoadingMacs && dataFiltered('blocked').length === 0" icon="device" />
                  <ObiLoading v-if="isLoadingMacs" />
               </template>
            </ObiCard>
         </ObiCol>
      </ObiRow>
      <ModalAddMac ref="modalAddMac" @success="loadDeviceMacs" />
   </ObiPage>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';

import { trFormatDateLong } from '@/libs/date-utils';

import DeviceMac from '@/api/Models/DeviceMac';

import PanelDeviceApi from '@/api/PanelDeviceApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconDevice from '@/components/Icons/general/IconDevice';
import DeleteIcon from '@/components/Icons/portals/DeleteIcon.svg';
import ObiRow from '../../../../components/UI/ObiRow.vue';

import ModalAddMac from '@/views/pages/Device/_Modals/AddMac';

export default {
   components: { ObiRow, ModalAddMac },
   i18n: {
      messages: {
         tr: {
            page_title: 'MAC Yönetimi',
            are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
            fields: {
               created_at: 'Oluşturulma Tarihi',
               type: 'Davranış Türü',
               actions: 'İşlemler',
               logging_types: 'Davranış Türü',
               log_range: 'Tarih Aralığı',
               mac: 'MAC',
               comment: 'Açıklama',
            },
            actions: {
               create: 'Log Ekle',
               download: 'İndir',
               cancel: 'İptal Et',
               delete: 'Sil',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
         en: {
            page_title: 'MAC Management',
            are_you_sure_delete: 'Are you sure you want to delete?',
            fields: {
               created_at: 'Creation Date',
               type: 'Behavior Type',
               actions: 'Actions',
               logging_types: 'Behavior Types',
               log_range: 'Date Range',
               mac: 'MAC',
               comment: 'Comment',
            },
            actions: {
               create: 'Add Log',
               download: 'Download',
               cancel: 'Cancel',
               delete: 'Delete',
               yes: 'Yes',
               no: 'No',
            },
         },
      },
   },
   computed: {
      getSelectedDateRange() {
         return this.formDataQuery.log_range
            ? `${this.formDataQuery.log_range.start}-${this.formDataQuery.log_range.end}`
            : 'Bugün';
      },
   },
   data() {
      return {
         IconDevice,
         DeleteIcon,
         isLoadingMacs: false,
         isLoadingStore: false,
         showAddUserModal: false,
         editingLog: null,
         filterMac: null,
         filterMacBlocked: null,
         deletingMacs: [],
         dataDeviceBlockedMac: PaginationResponse.create().map(DeviceMac),
         dataDeviceBypassedMac: PaginationResponse.create().map(DeviceMac),
         shortcuts: [
            { key: 'thisWeek', label: 'Bu Hafta', value: 'isoWeek' },
            { key: 'lastWeek', label: 'Geçen Hafta', value: '-isoWeek' },
            { key: 'last7Days', label: 'Son 7 Gün', value: 7 },
            { key: 'last30Days', label: 'Son 30 Gün', value: 30 },
            { key: 'thisMonth', label: 'Bu Ay', value: 'month' },
            { key: 'lastMonth', label: 'Geçen Ay', value: '-month' },
            { key: 'thisYear', label: 'Bu Yıl', value: 'year' },
            { key: 'lastYear', label: 'Geçen Yıl', value: '-year' },
         ],
         formDataQuery: {
            types: ['bypassed', 'blocked'],
         },
         tableHeaders: [
            {
               text: this.$t('fields.mac'),
               value: 'mac',
               sortable: true,
            },
            {
               text: this.$t('fields.comment'),
               value: 'comment',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
         typeOptions: [
            {
               label: 'İzinli',
               id: 'bypassed',
            },
            {
               label: 'Yasaklı',
               id: 'blocked',
            },
         ],
      };
   },

   async mounted() {
      this.loadDeviceMacs();
   },

   methods: {
      trFormatDateLong,
      formatMAC(value) {
         if (value.length <= 17) {
            this.filterMac = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.filterMac = this.filterMac.substr(0, 17);
         }
      },
      formatMACBlocked(value) {
         if (value.length <= 17) {
            this.filterMacBlocked = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.filterMacBlocked = this.filterMacBlocked.substr(0, 17);
         }
      },
      dataFiltered(type) {
         let result = this.dataDeviceBypassedMac;
         if (type === 'blocked') {
            result = this.dataDeviceBlockedMac;
         }

         return result.get('data');
      },
      async loadDeviceMacs() {
         this.isLoadingMacs = true;

         try {
            const deviceId = this.$route.params.deviceId;

            this.dataDeviceBlockedMac.merge(
               await PanelDeviceApi.macs.index(deviceId, { type: 'blocked', mac: this.filterMacBlocked })
            );
            this.dataDeviceBypassedMac.merge(
               await PanelDeviceApi.macs.index(deviceId, { type: 'bypassed', mac: this.filterMac })
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingMacs = false;
         }
      },
      async deleteMac(mac) {
         try {
            this.deletingMacs.push(mac.getId());
            const deviceId = this.$route.params.deviceId;

            await PanelDeviceApi.macs.delete(deviceId, mac.getId());
            await this.loadDeviceMacs();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.deletingMacs.splice(this.deletingMacs.indexOf(mac.getId()), 1);
         }
      },
      async downloadLogFile(logFile) {
         window.open(logFile, '_blank');
      },
      async addNewBypassedMac() {
         this.$refs.modalAddMac.show('bypassed');
      },
      async addNewBlockedMac() {
         this.$refs.modalAddMac.show('blocked');
      },
   },
};
</script>

<style lang="scss" scoped></style>
