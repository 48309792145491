import BaseModel from './BaseModel';

export default class LocationUserProfile extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }
}
