<template>
   <ObiPage>
      <ObiToolbar hideActionButtons>
         <template #search>
            <ObiInput
               clean
               compact
               :icon="IconSearch"
               @change="applyFilter"
               wrapper-class="for-toolbar"
               v-model="formDataSearch.query"
               :placeholder="$t('search_input_placeholder')"
               :errors="formDataSearch.errors.get('query')"
            />
            <ObiButtonSwitch multi :items="dataProviders" v-model="formDataSearch.providers" />

            <ObiButton color="primary" @click="applyFilter" icon="mdi mdi-magnify" :text="$t('button_search_label')" />
         </template>
      </ObiToolbar>

      <ObiCard class="my-4">
         <template #header>
            <ObiTitle :icon="IconVisitor" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.download')"
                     icon="mdi mdi-download"
                     @click="downloadList()"
                  />
                  <ObiButton
                     v-if="authUser.permissions.includes('store-clients')"
                     color="lighter"
                     text="Toplu Kullanıcı"
                     icon="mdi mdi-plus"
                     @click="$refs.modalBulkVisitorCreate.show()"
                  />
                  <ObiButton
                     v-if="authUser.permissions.includes('store-clients')"
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalVisitorCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>

         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataVisitors.get('data')"
               v-if="!isLoading && dataVisitors.get('meta.total')"
            >
               <template #address="{ item: visitor }">{{ visitor.getName() }}</template>
               <template #created_at="{ item: visitor }">{{ trFormatDate(visitor.getCreatedAt()) }}</template>
               <template #provider_type="{item: visitor}">
                  {{
                     `${$t(`fields.${visitor.get('provider_type')}`)},
                     ${visitor.get('provider_id') || $t('not_logged_in')}`
                  }}
               </template>
               <template #actions="{ item: visitor }">
                  <ObiButton
                     v-if="authUser.permissions.includes('update-clients')"
                     size="sm"
                     color="primary"
                     outline
                     :text="$t('actions.edit')"
                     @click="$refs.modalVisitorUpdate.show(visitor)"
                  />
                  <ObiButton
                     v-if="authUser.permissions.includes('delete-clients')"
                     size="sm"
                     color="danger"
                     outline
                     class="ms-2"
                     :text="$t('actions.delete')"
                     v-confirm="{
                        loader: true,
                        okText: $t('actions.yes'),
                        cancelText: $t('actions.no'),
                        ok: (dialog) => deleteVisitor(dialog, visitor),
                        message: $t('actions.delete_confirm'),
                     }"
                  />
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoading && !dataVisitors.get('meta.total')" icon="visitors">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalVisitorCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoading" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataVisitors.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalVisitorCreate ref="modalVisitorCreate" @success="loadVisitors" />
      <ModalBulkVisitorCreate ref="modalBulkVisitorCreate" @success="loadVisitors" />
      <ModalVisitorUpdate ref="modalVisitorUpdate" @success="loadVisitors" />
   </ObiPage>
</template>

<script>
import Form from '@/libs/form';

import IconVisitor from '@/components/Icons/general/IconVisitor';
import ModalVisitorCreate from '@/views/pages/Visitor/_Modals/Create';
import ModalBulkVisitorCreate from '@/views/pages/Visitor/_Modals/BulkCreate';
import ModalVisitorUpdate from '@/views/pages/Visitor/_Modals/Update';

import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';

import Visitor from '@/api/Models/Visitor';

import PanelVisitorApi from '@/api/PanelVisitorApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconSearch from '@/components/Icons/header/IconSearch.svg';
import { mapGetters } from 'vuex';

export default {
   name: 'VisitorIndex',
   i18n: {
      messages: {
         tr: {
            button_search_label: 'Ara',
            search_input_placeholder: 'Arama',
            page_title: 'Kullanıcılar',
            actions: {
               create: 'Kullanıcı Ekle',
               edit: 'Düzenle',
               delete: 'Sil',
               delete_confirm: 'Kullanıcıyı silmek istediğinize emin misiniz?',
               yes: 'Evet',
               no: 'Hayır',
               download: 'İndir',
            },
            fields: {
               name: 'Kullanıcı Adı',
               address: 'Lokasyon',
               portal: 'Cihaz',
               created_at: 'Oluşturma Tarihi',
               actions: 'İşlemler',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               location: 'Konum',
               device: 'Cihaz',
               provider_type: 'Doğrulama Yöntemi',
               'citizen-id': 'TC No',
               username: 'Kullanıcı Adı & Parola',
               'room-number': 'Oda Numarası',
               sms: 'SMS',
               not_logged_in: 'Oturum açılmamış',
               temp: 'Geçici Bağlantı',
               email: 'E-Posta',
               phone: 'Telefon',
            },
         },
         en: {
            button_search_label: 'Search',
            search_input_placeholder: 'Search',
            page_title: 'Users',
            actions: {
               create: 'Add User',
               edit: 'Edit',
               delete: 'Delete',
               delete_confirm: 'Are you sure to delete the user?',
               yes: 'Yes',
               no: 'No',
               download: 'Download',
            },
            fields: {
               name: 'Username',
               address: 'Location',
               portal: 'Device',
               created_at: 'Creation Date',
               actions: 'Actions',
               country: 'Country',
               city: 'City',
               district: 'District',
               location: 'Location',
               device: 'Device',
               provider_type: 'Authentication Method',
               'citizen-id': 'Citizen ID',
               username: 'Username & Password',
               'room-number': 'Room Number',
               sms: 'SMS',
               not_logged_in: 'Not Logged In',
               temp: 'Temporary Connection',
               email: 'E-Mail',
               phone: 'Phone',
            },
         },
      },
   },
   components: {
      ModalVisitorCreate,
      ModalVisitorUpdate,
      ModalBulkVisitorCreate,
   },
   computed: {
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      ...mapGetters('auth', ['authUser', '']),
   },
   data() {
      return {
         IconVisitor,
         isLoading: false,
         IconSearch,
         formDataSearch: Form.create(['query', 'providers']).defaults({
            providers: ['room-number', 'citizen-id', 'username', 'sms'],
         }),
         dataProviders: [
            { id: 'room-number', label: 'Oda Numarası' },
            { id: 'citizen-id', label: 'TC Kimlik' },
            { id: 'username', label: 'Kullanıcı Adı' },
            { id: 'sms', label: 'SMS Doğrulama' },
         ],
         dataVisitors: PaginationResponse.create().map(Visitor),
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.email'),
               value: 'email',
               sortable: true,
            },
            {
               text: this.$t('fields.phone'),
               value: 'phone',
               sortable: true,
            },
            {
               text: this.$t('fields.device'),
               value: 'device.name',
               sortable: true,
            },
            {
               text: this.$t('fields.provider_type'),
               value: 'provider_type',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               width: 200,
               class: 'text-end',
            },
         ],
      };
   },
   async mounted() {
      await this.loadVisitors();
   },
   methods: {
      trFormatDate,

      applyFilter() {
         this.loadVisitors(this.formDataSearch.only(['query', 'providers']));
      },
      async onPageChanged(page) {
         await this.$router.push({ query: { ...this.$route.query, page } });
      },

      async loadVisitors(params) {
         try {
            this.isLoading = true;
            this.dataVisitors.merge(await PanelVisitorApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async deleteVisitor(dialog, visitor) {
         try {
            this.isLoading = true;
            await PanelVisitorApi.delete(visitor.getId(), null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            dialog.close();
            this.loadVisitors();
         }
      },
      async downloadList() {
         try {
            this.isLoading = true;
            const response = await PanelVisitorApi.export();

            var fileURL = window.URL.createObjectURL(new Blob([response]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'user-list.csv');
            document.body.appendChild(fileLink);
            fileLink.click();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
   watch: {
      async $route(to) {
         await this.loadVisitors({ page: to.query.page });
      },
   },
};
</script>

<style lang="scss" scoped></style>
