<template>
   <ObiModal lg as="form" ref="modal" @submit.prevent="submitForm" :hide-cancel="true">
      <template #header>
         <ObiTitle :icon="IconDeviceWhite" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiText v-if="currentStep === 1">
         <ObiTitle class="mb-3" color="primary" :title="$t('labels.select_brand')" />
         <ObiRow v-if="!isLoadingBrands && dataDeviceBrands.get('meta.total')">
            <ObiCol sm="3" v-for="brand in dataDeviceBrands.get('data')" :key="brand.getId()">
               <ObiCard
                  class="border mb-4 brand-item"
                  @click="selectBrand(brand)"
                  :class="{ 'border-primary': isSelectedBrand(brand) }"
               >
                  <ObiImage
                     fit-contain
                     height="60"
                     width="100%"
                     :src="brand.getLogo()"
                     :grayscale="!isSelectedBrand(brand)"
                  />
               </ObiCard>
            </ObiCol>
         </ObiRow>
         <ObiButton
            wider
            icon-right
            @click="nextStep"
            :loading="isLoading"
            :disabled="!selectedBrand._data.id"
            eklendi
            class="float-end"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.next')"
         />
      </ObiText>
      <ObiText v-if="currentStep === 2">
         <ObiFloatingInput
            v-if="selectedBrand._data.slug !== 'watchguard'"
            :label="$t('fields.mac_address')"
            :disabled="isLoading"
            :placeholder="$t('fields.mac_address')"
            v-model="formDataDevice.mac_address"
            @input="formatMAC"
            :errors="formDataDevice.errors.get('mac_address')"
         />

         <ObiFloatingInput
            v-else
            :label="$t('fields.serial_number')"
            :disabled="isLoading"
            :placeholder="$t('fields.serial_number')"
            v-model="formDataDevice.mac_address"
            :errors="formDataDevice.errors.get('mac_address')"
         />

         <ObiFloatingInput
            v-if="selectedBrand._data.slug === 'watchguard'"
            :label="$t('fields.secret')"
            :disabled="true"
            :placeholder="$t('fields.secret')"
            v-model="formDataDevice.secret"
            :errors="formDataDevice.errors.get('secret')"
         />

         <ObiFloatingInput
            :label="$t('fields.name')"
            :disabled="isLoading"
            :placeholder="$t('fields.name')"
            v-model="formDataDevice.name"
            :errors="formDataDevice.errors.get('name')"
         />
         <ObiButton
            wider
            color="lighter"
            @click="prevStep"
            :loading="isLoading"
            :disabled="currentStep <= 1"
            icon="mdi mdi-arrow-left"
            :text="$t('actions.prev')"
         />

         <ObiButton
            wider
            icon-right
            @click="submitForm"
            :loading="isLoading"
            :disabled="!formDataDevice.mac_address || !formDataDevice.name"
            eklendi
            class="float-end"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.save')"
         />
      </ObiText>
      <ObiText v-if="currentStep === 3">
         <ObiRow>
            <ObiCol sm="12" class="text-center">
               <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="47" cy="47" r="45.5" stroke="#2F71F2" stroke-width="3" />
                  <path
                     d="M68.2352 29L41.7646 59"
                     stroke="#2F71F2"
                     stroke-width="3"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M25 45.7647L41.7647 59"
                     stroke="#2F71F2"
                     stroke-width="3"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <h2 style="font-weight: 500; color: #2f71f2; text-align: center;" class="mt-1">
                  Cihaz Başarıyla Eklendi
               </h2>
               <p
                  class="mt-1"
                  style="width: 328px; font-weight: 300; color: #777d96; text-align: center; display: inline-block;"
               >
                  Aşağıdaki kurulum dökümanını kullanarak cihazında gerekli ayarlamaları yapabilirsiniz.
               </p>
            </ObiCol>
            <ObiCol sm="12" class="mt-3">
               <p class="fs-4 fw-light lh-1" v-for="guide in selectedBrand._data.guides" :key="guide.id">
                  <a :href="guideUrl(guide)" target="_blank"
                     >{{ guide.title }}
                     <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M18.75 21.75H2.25V4.875H11.25V3.375H0.75V23.25H20.25V12.75H18.75V21.75Z"
                           fill="#2F71F2"
                        />
                        <path
                           d="M14.25 0.75V2.25H20.6894L8.84473 14.0947L9.90532 15.1553L21.75 3.31064V9.75H23.25V0.75H14.25Z"
                           fill="#2F71F2"
                        />
                     </svg>
                  </a>
               </p>
            </ObiCol>
            <ObiCol sm="12" class="mt-3">
               <ObiRow class="mt-1">
                  <ObiCol
                     sm="3"
                     class="py-3 px-2 text-end"
                     style="background-color: black; color: white; font-size: 1.2rem;"
                     >RADIUS Server</ObiCol
                  >
                  <ObiCol sm="3" class="my-auto fs-5" style="color: black;">138.197.180.226</ObiCol>
               </ObiRow>
               <ObiRow class="mt-1">
                  <ObiCol
                     sm="3"
                     class="py-3 px-2 text-end"
                     style="background-color: black; color: white; font-size: 1.2rem;"
                     >Radius Anahtarı</ObiCol
                  >
                  <ObiCol sm="3" class="my-auto fs-5" style="color: black;">YeT2dXVs6D</ObiCol>
               </ObiRow>
               <ObiRow class="mt-1">
                  <ObiCol
                     sm="3"
                     class="py-3 px-2 text-end"
                     style="background-color: black; color: white; font-size: 1.2rem;"
                     >Portal URL</ObiCol
                  >
                  <ObiCol v-if="getSubdomain() === 'quedra'" sm="5" class="my-auto fs-5" style="color: black;"
                     >http://captive.quedra.com</ObiCol
                  >
                  <ObiCol v-else-if="getSubdomain() === 'wifibulut'" sm="5" class="my-auto fs-5" style="color: black;"
                     >http://captive.wifibulut.com</ObiCol
                  >
                  <ObiCol v-else sm="5" class="my-auto fs-5" style="color: black;">http://portal.obifi.io</ObiCol>
               </ObiRow>
            </ObiCol>
            <ObiCol v-if="selectedBrand._data.name === 'Mikrotik'">
               <ObiRow class="mt-3">
                  <ObiAlert dark justify="center">
                     <ObiText v-html="$t('login_html')" />
                     <template slot="actions">
                        <ObiButton
                           :text="$t('actions.download')"
                           :loading="isFileDownloading"
                           class="ms-2"
                           @click="downloadMikrotikLogin"
                        />
                     </template>
                  </ObiAlert>
               </ObiRow>
               <ObiRow>
                  <ObiCol col="6">
                     <ObiFloatingInput
                        :label="$t('fields.nat_interface')"
                        :placeholder="$t('fields.nat_interface')"
                        v-model="formDataMikrotikDevice.gateway_interface"
                        :errors="[]"
                     />
                  </ObiCol>
                  <ObiCol col="6">
                     <ObiFloatingInput
                        :label="$t('fields.nat_ip')"
                        :placeholder="$t('fields.nat_ip')"
                        v-model="formDataMikrotikDevice.gateway_ip"
                        :errors="[]"
                     />
                  </ObiCol>
                  <ObiCol col="6">
                     <ObiFloatingInput
                        :label="$t('fields.hotspot_dhcp_ip')"
                        :placeholder="$t('fields.hotspot_dhcp_ip')"
                        v-model="formDataMikrotikDevice.hotspot_address"
                        :errors="[]"
                     />
                  </ObiCol>
                  <ObiCol col="6">
                     <ObiFloatingInput
                        :label="$t('fields.hotspot_dhcp_subnet')"
                        :placeholder="$t('fields.hotspot_dhcp_subnet')"
                        v-model="formDataMikrotikDevice.hotspot_subnet"
                        :errors="[]"
                     />
                  </ObiCol>
                  <ObiCol col="12">
                     <ObiTagsInput v-model="formDataMikrotikDevice.guestInterfaces" />
                  </ObiCol>
               </ObiRow>
               <ObiRow class="mt-3">
                  <ObiCol class="d-flex justify-content-end">
                     <ObiButton
                        wider
                        type="button"
                        @click="createScript"
                        :loading="isLoading"
                        color="success"
                        :text="$t('actions.createScript')"
                     />
                  </ObiCol>
               </ObiRow>
               <ObiRow v-if="createdScript">
                  <ObiCol cols="12">
                     <ObiAlert light class="mt-4 d-flex align-items-start">
                        <ObiIcon class="me-2" icon="mdi mdi-alert-circle" />
                        <ObiText>
                           Aşağıdaki scripti kopyaladıktan sonra, mikrotik cihazınızda oturum açınız. Mevcut
                           konfigürasyonu resetlediğinizden emin olduktan sonra Terminal bölümüne yapıştırıp ENTER ile
                           çalıştırabilirsiniz. Her şeyin yolunda olduğunu oturum açarak test edebilirsiniz.
                        </ObiText>
                     </ObiAlert>
                  </ObiCol>
                  <ObiText class="mt-3">
                     <ObiText as="pre" class="script-pre" id="mikrotikScript">
                        <ObiText as="code">
                           {{ createdScript }}
                        </ObiText>
                     </ObiText>
                  </ObiText>
                  <ObiText class="d-flex justify-content-end mb-4">
                     <ObiButton color="dark" wider @click="copyScript">Scripti Kopyala</ObiButton>
                  </ObiText>
               </ObiRow>
            </ObiCol>
         </ObiRow>
         <ObiText
            v-if="selectedBrand._data.name === 'Mikrotik' ? createdScript : true"
            class="d-flex justify-content-center mt-4"
         >
            <ObiButton
               wider
               @click="hide"
               :loading="isLoading"
               eklendi
               color="primary"
               :text="$t('actions.deviceList')"
            />
         </ObiText>
      </ObiText>
   </ObiModal>
</template>

<script>
import { getSubdomain } from '@/libs/url-utils';
import IconDeviceWhite from '@/components/Icons/general/IconDeviceWhite.svg';
import ErrorHandler from '@/libs/error-handler';
import Form from '@/libs/form';
import Brand from '@/api/Models/Brand';
import Location from '@/api/Models/Location';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import PanelLocationApi from '@/api/PanelLocationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import formDataDevice from '@/views/pages/Device/FormData/form-data-device';

export default {
   name: 'ModalDeviceCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Yeni Cihaz Ekle',
            subtitle: 'Yeni cihaz eklemek için bilgileri doldurun',
            form_title: 'Lokasyon Bilgileri',
            login_html: 'Mikrotik Login Dosyasını İndirin',
            labels: {
               select_brand: 'Donanımınızı Seçin',
            },
            fields: {
               location_id: 'Cihaz Lokasyonu',
               mac_address: 'Cihaz Mac Adresi',
               serial_number: 'Seri Numarası',
               secret: 'Gizli Anahtar',
               name: 'Cihaz Adı',
               nat_interface: 'Nat Interface',
               nat_ip: 'Nat IP',
               hotspot_dhcp_ip: 'Hotspot DHCP IP',
               hotspot_dhcp_subnet: 'Hotspot DHCP Subnet',
            },
            actions: {
               prev: 'Geri',
               next: 'İleri',
               save: 'Kaydet',
               deviceList: 'Cihaz Listesi',
               createScript: 'Script Oluştur',
               download: 'indir',
            },
         },
         en: {
            title: 'Add New Device',
            subtitle: 'Fill in the information to add a new device',
            form_title: 'Location Information',
            login_html: 'Download Mikrotik Login File',
            labels: {
               select_brand: 'Select Your Hardware',
            },
            fields: {
               location_id: 'Device Location',
               mac_address: 'Device MAC Address',
               serial_number: 'Serial Number',
               secret: 'Secret Key',
               name: 'Device Name',
               nat_interface: 'NAT Interface',
               nat_ip: 'NAT IP',
               hotspot_dhcp_ip: 'Hotspot DHCP IP',
               hotspot_dhcp_subnet: 'Hotspot DHCP Subnet',
            },
            actions: {
               prev: 'Previous',
               next: 'Next',
               save: 'Save',
               deviceList: 'Device List',
               createScript: 'Create Script',
               download: 'Download',
            },
         },
      },
   },

   computed: {
      locationSelectOptions() {
         return this.dataLocations
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
   },

   async mounted() {
      await this.loadDeviceBrands();
      await this.loadLocations();
   },

   data() {
      return {
         IconDeviceWhite,
         currentStep: 1,
         formDataDevice,
         isLoading: false,
         isLoadingBrands: false,
         isLoadingLocations: false,
         isFileDownloading: false,
         savedDevice_id: '',
         formDataMikrotikDevice: Form.create([
            'gateway_interface',
            'gateway_ip',
            'hotspot_address',
            'hotspot_subnet',
            'guestInterfaces',
         ]).validate({
            gateway_interface: 'required',
            gateway_ip: 'required',
            hotspot_address: 'required',
            hotspot_subnet: 'required',
            guestInterfaces: 'required',
         }),
         createdScript: '',
         selectedBrand: new Brand({}),
         dataLocations: PaginationResponse.create().map(Location),
         dataDeviceBrands: PaginationResponse.create().map(Brand),
      };
   },

   methods: {
      getSubdomain,
      show() {
         this.$refs.modal.show();
         this.clearModal();
      },
      clearModal() {
         this.currentStep = 1;
         this.createdScript = '';
         this.selectedBrand = new Brand({});
         this.isLoading = false;
         this.isLoadingBrands = false;
         this.isLoadingLocations = false;
         this.formDataDevice.reset();
         this.formDataMikrotikDevice.gateway_interface = '';
         this.formDataMikrotikDevice.gateway_ip = '';
         this.formDataMikrotikDevice.hotspot_address = '';
         this.formDataMikrotikDevice.hotspot_subnet = '';
         this.formDataMikrotikDevice.guestInterfaces = '';
      },
      hide() {
         this.$refs.modal.hide();
         this.clearModal();
      },
      formatMAC(value) {
         if (this.formDataDevice.mac_address.length <= 17) {
            this.formDataDevice.mac_address = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.formDataDevice.mac_address = this.formDataDevice.mac_address.substr(0, 17);
         }
      },
      async submitForm() {
         if (!this.formDataDevice.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelDeviceApi.store(this.formDataDevice.toObject());
            this.savedDevice_id = response.data.id;
            this.formDataDevice.reset();

            this.currentStep++;
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async loadLocations() {
         try {
            this.isLoadingLocations = true;

            this.dataLocations.merge(await PanelLocationApi.index({ per_page: 9999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingLocations = false;
         }
      },
      async downloadMikrotikLogin() {
         this.isFileDownloading = true;
         try {
            const response = await PanelDeviceApi.downloadLoginHtml(this.savedDevice_id, null);

            var fileURL = window.URL.createObjectURL(new Blob([response]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'login.html');
            document.body.appendChild(fileLink);
            fileLink.click();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isFileDownloading = false;
         }
      },
      async createScript() {
         this.isLoading = true;
         try {
            this.createdScript = await PanelDeviceApi.mikrotikScriptGenerate(
               this.savedDevice_id,
               this.formDataMikrotikDevice.toObject()
            );
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      copyScript() {
         console.log('script kopyalama');
      },
      async loadDeviceBrands() {
         try {
            this.isLoadingBrands = true;

            this.dataDeviceBrands.merge(await PanelDeviceApi.brands());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingBrands = false;
         }
      },

      selectBrand(brand) {
         this.selectedBrand = brand;
         this.formDataDevice.set('brand_id', brand.getId());
      },

      isSelectedBrand(brand) {
         return brand.getId() === this.selectedBrand.getId();
      },

      prevStep() {
         if (this.currentStep === 1) return;

         this.currentStep = this.currentStep - 1;
      },

      nextStep() {
         this.currentStep = this.currentStep + 1;
      },
      guideUrl(guide) {
         return `https://obifi.com/guide/${guide.slug}`;
      },
   },
};
</script>
<style scoped>
.border-primary {
   color: #2f71f2 !important;
}
.brand-item:hover {
   border: 1px solid #2f71f2 !important;
}
.script-pre {
   height: 200px;
}
</style>
