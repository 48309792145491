import Form from '@/libs/form';

const fields = ['id', 'first_name', 'last_name', 'email', 'password'];

const rules = {
   email: 'required|email',
   first_name: 'required',
   last_name: 'required',
   password: 'required|min:6',
};

export default Form.create(fields).validate(rules);
