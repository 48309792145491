var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ObiPage',[_c('ObiToolbar'),_c('ObiCard',{staticClass:"my-4",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ObiTitle',{attrs:{"icon":_vm.IconLocation,"title":_vm.$t('page_title')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ObiButton',{attrs:{"color":"lighter","text":_vm.$t('actions.create'),"icon":"mdi mdi-plus"},on:{"click":function($event){return _vm.$refs.modalLocationCreate.show()}}})]},proxy:true}])})]},proxy:true},{key:"body",fn:function(){return [(!_vm.isLoading && _vm.dataLocations.get('meta.total'))?_c('ObiTable',{attrs:{"headers":_vm.tableHeaders,"data":_vm.dataLocations.get('data')},scopedSlots:_vm._u([{key:"address",fn:function({ item: location }){return [_vm._v(_vm._s(location.getLocationFormatted()))]}},{key:"created_at",fn:function({ item: location }){return [_vm._v(_vm._s(_vm.trFormatDate(location.getCreatedAt())))]}},{key:"actions",fn:function({ item: location }){return [_c('ObiButton',{attrs:{"size":"sm","color":"lighter","as":"router-link","text":_vm.$t('actions.edit'),"to":{ name: 'locations-edit', params: { locationId: location.getId() } }}}),_c('ObiButton',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                     loader: true,
                     okText: 'Evet',
                     cancelText: 'Hayır',
                     ok: (dialog) => _vm.deleteLocation(dialog, location),
                     message: 'Lokasyonu silmek istediğinize emin misiniz?',
                  }),expression:"{\n                     loader: true,\n                     okText: 'Evet',\n                     cancelText: 'Hayır',\n                     ok: (dialog) => deleteLocation(dialog, location),\n                     message: 'Lokasyonu silmek istediğinize emin misiniz?',\n                  }"}],attrs:{"size":"sm","color":"danger","text":_vm.$t('actions.delete')}})]}}],null,false,358079284)}):(!_vm.isLoading && !_vm.dataLocations.get('meta.total'))?_c('Obi404',{attrs:{"icon":"locations"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ObiButton',{attrs:{"size":"lg","outline":"","wider":"","text":_vm.$t('actions.create')},on:{"click":function($event){return _vm.$refs.modalLocationCreate.show()}}})]},proxy:true}])}):_vm._e(),(_vm.isLoading)?_c('ObiLoading'):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('ObiPagination',{attrs:{"meta":_vm.dataLocations.get('meta')},on:{"page-changed":_vm.onPageChanged}})]},proxy:true}])}),_c('ModalLocationCreate',{ref:"modalLocationCreate",on:{"success":_vm.loadLocations}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }