import Http from '@/libs/http';

class PanelVisitorApi extends Http {
   index(params) {
      return super.get('panel/client', { params });
   }

   export() {
      return super.post('panel/client/export', {
         responseType: 'blob',
      });
   }

   store(payload, params) {
      return super.post('panel/client', payload, { params });
   }

   bulk(payload, params) {
      return super.post('panel/client/bulk', payload, { params });
   }

   show(id, params) {
      return super.get(`panel/client/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`panel/client/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`panel/client/${id}`, { params });
   }
}

export default new PanelVisitorApi();
