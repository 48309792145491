import BaseModel from './BaseModel';

export default class StatisticOverview extends BaseModel {
   getReturnedUserCount() {
      return this.get('returned_users', 0);
   }

   getOnlineUserCount() {
      return this.get('onlines', 0);
   }

   getFailedLoginCount() {
      return this.get('failed_logins', 0);
   }

   getDeviceCount() {
      return this.get('devices', 0);
   }

   getUserCount() {
      return this.get('users', 0);
   }

   getLocationCount() {
      return this.get('locations', 0);
   }

   getNotificationCount() {
      return this.get('notifications', 0);
   }

   getIntegrationCount() {
      return this.get('integrations', 0);
   }
}
