import BaseModel from './BaseModel';

export default class Role extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getGuardname() {
      return this.get('guard_name');
   }
}
