import BaseModel from './BaseModel';

export default class OnlineVisitor extends BaseModel {
   getClient() {
      return this.get('client');
   }

   getDevice() {
      return this.get('device');
   }

   getNetwork() {
      return this.get('network');
   }

   getMac() {
      return this.get('client_mac');
   }

   getIp() {
      return this.get('client_ip');
   }

   getSessionStartTime() {
      return this.get('session_start_time');
   }

   getRequestHid() {
      return this.get('rhid');
   }
}
