<template>
   <ObiRow v-if="!isLoading">
      <ObiCol col="6">
         <ObiInput
            clean
            :icon="IconLocation"
            v-model="formDataLocation.name"
            :label="$t('fields.name')"
            :errors="formDataLocation.errors.get('name')"
         />

         <ObiInput
            clean
            :icon="IconLocation"
            v-model="formDataLocation.country"
            :label="$t('fields.country')"
            :errors="formDataLocation.errors.get('country')"
         />

         <ObiInput
            clean
            :icon="IconLocation"
            v-model="formDataLocation.city"
            :label="$t('fields.city')"
            :errors="formDataLocation.errors.get('city')"
         />

         <ObiInput
            clean
            :icon="IconLocation"
            v-model="formDataLocation.district"
            :label="$t('fields.district')"
            :errors="formDataLocation.errors.get('district')"
         />
      </ObiCol>
      <ObiCol sm="6">
         <ObiSelect
            :label="$t('fields.portal')"
            v-model="formDataLocation.portal_id"
            :options="portalSelectOptions"
            :placeholder="$t('fields.portal')"
            :errors="formDataLocation.errors.get('portal_id')"
         />
         <ObiSelect
            :label="$t('fields.default_profile')"
            v-model="formDataLocation.default_profile_id"
            :options="locationProfileOptions"
            :placeholder="$t('fields.default_profile')"
            :errors="formDataLocation.errors.get('default_profile_id')"
         />

         <v-popover>
            <ObiButton color="danger" class="px-4 m-1" :text="$t('actions.permanent_delete')" />

            <template slot="popover">
               <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
               <ObiButton
                  class="ms-2"
                  size="sm"
                  color="danger"
                  :text="$t('actions.yes')"
                  @click="$refs.modal.show()"
                  v-close-popover
               />
               <ObiButton class="ms-2" size="sm" color="warning" :text="$t('actions.no')" v-close-popover />
            </template>
         </v-popover>
         <ObiModal ref="modal" :title="`Dikkat !!!`">
            <ObiText as="p" class="text-danger">
               {{ $t('are_you_sure_delete_permanent') }}
            </ObiText>
            <template #actions>
               <ObiButton @click="deleteLocation()" color="danger" :text="$t('actions.yes')" v-close-popover />
            </template>
         </ObiModal>
      </ObiCol>
   </ObiRow>
   <ObiLoading v-else />
</template>

<script>
import IconLocation from '@/components/Icons/general/IconLocation.svg';

import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';
import Notify from '@/libs/notify';
import PanelLocationApi from '@/api/PanelLocationApi';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import Portal from '@/api/Models/Portal';
import PanelPortalApi from '@/api/PanelPortalApi';

import LocationUserProfile from '@/api/Models/LocationUserProfile';

export default {
   i18n: {
      messages: {
         tr: {
            are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
            are_you_sure_delete_permanent: 'Lokasyonu kalıcı silmek istediğinize emin misiniz?',
            actions: {
               permanent_delete: 'Kalıcı Sil',
               yes: 'Evet',
               no: 'Hayır',
            },
            fields: {
               name: 'Konum Adı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               portal: 'Karşılama Portalı',
               default_profile: 'Varsayılan Oturum Profili',
            },
         },
         en: {
            are_you_sure_delete: 'Are you sure you want to delete?',
            are_you_sure_delete_permanent: 'Are you sure you want to permanently delete the location?',
            actions: {
               permanent_delete: 'Permanently Delete',
               yes: 'Yes',
               no: 'No',
            },
            fields: {
               name: 'Location Name',
               country: 'Country',
               city: 'City',
               district: 'District',
               portal: 'Welcome Portal',
               default_profile: 'Default Session Profile',
            },
         },
      },
   },

   props: {
      formDataLocation: {
         type: Form,
      },
   },

   data() {
      return {
         IconLocation,
         isLoading: false,
         dataLocation: SingleResourceResponse.create(),
         dataUserProfiles: PaginationResponse.create().map(LocationUserProfile),
         dataPortals: PaginationResponse.create().map(Portal),
      };
   },

   computed: {
      portalSelectOptions() {
         return this.dataPortals
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
      locationProfileOptions() {
         return this.dataUserProfiles
            .get('data')
            .map((profile) => ({ label: profile.getName(), value: profile.getId() }));
      },
   },

   async mounted() {
      await this.loadUserProfiles();
      await this.loadPortals();
      await this.loadLocationDetail();
   },

   methods: {
      submitLocationPut() {
         return 'asd';
      },

      async loadPortals() {
         this.isLoading = true;

         try {
            this.dataPortals.merge(await PanelPortalApi.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async loadLocationDetail() {
         this.isLoading = true;

         try {
            const locationId = this.$route.params.locationId;

            const { data } = await PanelLocationApi.show(locationId);

            this.dataLocation.merge(data);

            const formData = this.dataLocation.only(
               'name',
               'country',
               'city',
               'district',
               'portal_id',
               'default_profile_id'
            );

            this.formDataLocation.merge(formData);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async loadUserProfiles() {
         this.isLoading = true;

         try {
            this.dataUserProfiles.merge(await PanelLocationApi.userProfiles.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async submitLocationUpdate() {
         if (!this.formDataLocation.isValid()) return;

         this.isLoading = true;

         try {
            const locationId = this.$route.params.locationId;

            await PanelLocationApi.update(locationId, this.formDataLocation.toObject());

            await this.$router.push({ name: 'locations-index' });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async deleteLocation() {
         const locationId = this.$route.params.locationId;
         try {
            console.log(locationId);
            await PanelLocationApi.delete(locationId);
            Notify.success(this.$t('alert.success'));
            this.$refs.modal.hide();
            await this.$router.push({ name: 'dashboard-index' }).catch(() => {});
            window.location.reload();
         } catch (err) {
            ErrorHandler.handle(err);
         }
      },
   },
};
</script>

<style lang="scss" scoped></style>
