import Vue from 'vue';
import get from 'lodash/get';
import VueRouter from 'vue-router';

import Store from '@/store';
import RoutesAuth from '@/router/auth';
import RoutesDashboard from '@/router/dashboard';
import RoutesInvitation from '@/router/invitation';
import RoutesVisitorConfirm from '@/router/confirm-email';
import RoutesAdmin from '@/router/admin';
import RoutesOnboarding from '@/router/onboarding';

Vue.use(VueRouter);

const router = new VueRouter({
   mode: 'history',
   routes: [
      RoutesAuth,
      RoutesDashboard,
      RoutesInvitation,
      RoutesVisitorConfirm,
      RoutesAdmin,
      RoutesOnboarding,
      { path: '*', redirect: '/' },
   ],
   scrollBehavior: (to) => {
      if (to.hash) {
         return { selector: to.hash };
      } else {
         return { x: 0, y: 0 };
      }
   },
});

router.beforeEach(async (to, from, next) => {
   await Store.dispatch('app/tenant');

   if (to.query.reloadAuthentication) {
      await Store.dispatch('auth/me');
      const query = Object.assign({}, to.query);
      delete query['reloadAuthentication'];

      await router.replace({ ...to, query });
   }

   const isLoggedIn = Store.getters['auth/isLoggedIn'];

   const guestRoutes = [
      'auth-login',
      'auth-register',
      'auth-complete',
      'auth-forgot-password',
      'auth-reset-password',
      'invitation-accept',
      'confirm-email-accept',
   ];

   if (!isLoggedIn && guestRoutes.indexOf(to.name) === -1) return next('/auth/login');

   if (isLoggedIn && guestRoutes.indexOf(to.name) !== -1) {
      return next('/');
   }

   if (isLoggedIn) Store.dispatch('auth/me');

   const authUser = Store.getters['auth/authUser'];

   const onBoardingRoutes = [
      'onboarding-create-device',
      'onboarding-create-portal',
      'onboarding-subscribe',
      'onboarding-subscribe-payment',
   ];

   if (
      isLoggedIn &&
      get(authUser, 'total_devices') === 0 &&
      get(authUser, 'total_portals') === 0 &&
      !get(authUser, 'can_create_location') &&
      onBoardingRoutes.indexOf(to.name) === -1
   )
      return next('onboarding/device');

   return next();
});

export default router;
