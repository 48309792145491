import Form from '@/libs/form';

const fields = ['db_host', 'db_port', 'db_username', 'db_password', 'db_name', 'db_table', 'type'];

const rules = {
   db_host: 'required',
   db_port: 'required',
   db_username: 'required',
   db_password: 'required',
   db_name: 'required',
   db_table: 'required',
};

export default Form.create(fields).validate(rules);
