<template>
   <ObiPage>
      <ObiCard>
         <template #header>
            <ObiTitle class="mb-4" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalLocationUserCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>
         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataLocationUsers.get('data')"
               v-if="!isLoadingUsers && dataLocationUsers.get('meta.total')"
            >
               <template #authority="{ item: user }">{{ formatRoles(user.getRoles()) }}</template>
               <template #created_at="{ item: user }">{{ trFormatDate(user.getCreatedAt()) }}</template>
               <template #actions="{ item: user }">
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiButton size="sm" color="lighter" :text="$t('actions.edit')" @click="user.getId()" />
                     </ObiCol>

                     <ObiCol col="6">
                        <v-popover>
                           <ObiButton :loading="isRevokingUser" size="sm" color="danger" :text="$t('actions.revoke')" />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_to_revoke')" />
                              <ObiButton
                                 class="ms-2"
                                 :loading="isRevokingUser"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 @click="revokeAccess(user)"
                                 v-close-popover
                              />
                              <ObiButton
                                 class="ms-2"
                                 :loading="isRevokingUser"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoadingUsers && !dataLocationUsers.get('meta.total')" icon="locations">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalLocationUserCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoadingUsers" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataLocationUsers.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>
      <ObiCard class="mt-4">
         <template #header>
            <ObiTitle class="mb-4" :title="$t('invites_page_title')" />
         </template>
         <template #body>
            <ObiTable
               :headers="invitesTableHeaders"
               :data="dataInvitedUsers.get('data')"
               v-if="!isLoadingInvites && dataInvitedUsers.get('meta.total')"
            >
               <template #created_at="{ item: invite }">{{ trFormatDate(invite.getCreatedAt()) }}</template>
               <template #actions="{ item: invite }">
                  <ObiButton
                     :loading="isWorkingInvites"
                     size="sm"
                     color="primary"
                     :text="$t('actions.resend')"
                     @click="resendInvite(invite)"
                  />
                  <ObiButton
                     class="ms-2"
                     :loading="isWorkingInvites"
                     size="sm"
                     color="warning"
                     :text="$t('actions.cancel')"
                     @click="cancelInvite(invite)"
                  />
               </template>
            </ObiTable>
            <Obi404
               v-else-if="!isLoadingInvites && !dataInvitedUsers.get('meta.total')"
               :title="$t('invites_404_title')"
               desc=""
               icon="locations"
            />
            <ObiLoading v-if="isLoadingInvites" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataInvitedUsers.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalLocationUserCreate ref="modalLocationUserCreate" @success="loadLocationInvites" />
   </ObiPage>
</template>

<script>
import map from 'lodash/map';
import join from 'lodash/join';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import { trFormatDate } from '@/libs/date-utils';

import PanelLocationApi from '@/api/PanelLocationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconLocation from '@/components/Icons/general/IconLocation';
import ModalLocationUserCreate from '@/views/pages/Location/_Modals/UserCreate';
import User from '@/api/Models/User';
import Invite from '@/api/Models/Invite';

export default {
   components: {
      ModalLocationUserCreate,
   },

   i18n: {
      messages: {
         tr: {
            page_title: 'Yönetici Listesi',
            invites_page_title: 'Bekleyen Davetiyeler',
            invites_404_title: 'Bekleyen Davetiyeniz Yok',
            are_you_sure_to_revoke: 'Kullanıcıyı kaldırmak istediğinize emin misiniz?',
            fields: {
               full_name: 'Adı Soyadı',
               email: 'Mail Adresi',
               authority: 'Yetki',
               created_at: 'Oluşturulma Tarihi',
               actions: 'İşlemler',
               invited_at: 'Davet Tarihi',
            },
            actions: {
               create: 'Yönetici Ekle',
               edit: 'Düzenle',
               resend: 'Tekrar Gönder',
               cancel: 'İptal Et',
               revoke: 'Kaldır',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
         en: {
            page_title: 'Administrator List',
            invites_page_title: 'Pending Invitations',
            invites_404_title: 'You have no pending invitations',
            are_you_sure_to_revoke: 'Are you sure you want to remove the user?',
            fields: {
               full_name: 'Full Name',
               email: 'Email Address',
               authority: 'Authority',
               created_at: 'Creation Date',
               actions: 'Actions',
               invited_at: 'Invitation Date',
            },
            actions: {
               create: 'Add Administrator',
               edit: 'Edit',
               resend: 'Resend',
               cancel: 'Cancel',
               revoke: 'Revoke',
               yes: 'Yes',
               no: 'No',
            },
         },
      },
   },
   data() {
      return {
         IconLocation,
         isLoadingUsers: false,
         isLoadingStore: false,
         showAddUserModal: false,
         isWorkingInvites: false,
         isLoadingInvites: false,
         isRevokingUser: false,
         dataLocationUsers: PaginationResponse.create().map(User),
         dataInvitedUsers: PaginationResponse.create().map(Invite),
         tableHeaders: [
            {
               text: this.$t('fields.full_name'),
               value: 'full_name',
               sortable: true,
            },
            {
               text: this.$t('fields.email'),
               value: 'email',
               sortable: true,
            },
            {
               text: this.$t('fields.authority'),
               value: 'authority',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
         invitesTableHeaders: [
            {
               text: this.$t('fields.email'),
               value: 'email',
               sortable: true,
            },
            {
               text: this.$t('fields.invited_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
            },
         ],
      };
   },

   async mounted() {
      this.loadLocationUsers();
      this.loadLocationInvites();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadLocationUsers({ page });
      },

      async loadLocationUsers() {
         this.isLoadingUsers = true;

         try {
            const locationId = this.$route.params.locationId;

            this.dataLocationUsers.merge(await PanelLocationApi.users.index(locationId));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingUsers = false;
         }
      },

      async loadLocationInvites() {
         this.isLoadingInvites = true;

         try {
            const locationId = this.$route.params.locationId;

            this.dataInvitedUsers.merge(await PanelLocationApi.users.invites(locationId));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingInvites = false;
         }
      },

      async resendInvite(invite) {
         this.isWorkingInvites = true;

         try {
            await PanelLocationApi.users.resendInvite(invite.getId());
            Notify.success(this.$t('Davetiye tekrar gönderildi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isWorkingInvites = false;
         }
      },

      async cancelInvite(invite) {
         this.isWorkingInvites = true;

         try {
            await PanelLocationApi.users.cancelInvite(invite.getId());
            Notify.success(this.$t('Davetiye iptal edildi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isWorkingInvites = false;
            this.loadLocationInvites();
         }
      },
      async revokeAccess(user) {
         this.isRevokingUser = true;

         try {
            const locationId = this.$route.params.locationId;
            await PanelLocationApi.users.delete(locationId, user.getId());
            Notify.success(this.$t('Davetiye iptal edildi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isRevokingUser = false;
            this.loadLocationInvites();
         }
      },

      async hidePopup() {
         console.log(this.$refs.revoke_popper);
         this.$refs['revoke_popper'].doClose();
      },

      formatRoles(roles) {
         return join(
            map(roles, (role) => role.getName()),
            ', '
         );
      },
   },
};
</script>

<style lang="scss" scoped></style>
