<template>
   <ObiRow justify-center>
      <ObiCol col="12" sm="10" v-if="formDataSettings">
         <ObiRow justify-between align-start class="mb-3">
            <ObiCol col="12" lg="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.logo')" />
               <ObiText as="small" v-html="$t('fields.logo_desc')" />
               <ObiFileUpload
                  :currentImageUrl="formDataSettings.landing_logo"
                  :collection="'tenant-brands'"
                  v-model="formDataSettings.landing_logo"
                  :url="uploadURL"
                  class="mt-2"
               />
            </ObiCol>

            <ObiCol col="12" lg="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.background_image')" />
               <ObiFileUpload
                  :url="uploadURL"
                  :currentImageUrl="formDataSettings.landing_background_image"
                  v-model="formDataSettings.landing_background_image"
                  :collection="'tenant-brands'"
                  class="mt-2"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow justify-between align-start class="mb-3">
            <ObiCol col="12" md="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.brand_primary_color')" />
               <ObiColorPicker class="mt-2" v-model="formDataSettings.primary_color" />
            </ObiCol>

            <ObiCol col="12" md="5" v-if="background_colors != null">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.landing_background')" />
               <ObiText
                  :style="
                     `width: 100%;
                     height: 150px;
                     background: ${landing_background}`
                  "
               />
               <ObiText class="d-flex justify-content-evenly flex-wrap gap-2 py-2">
                  <ObiColorPicker
                     v-model="background_colors[1]"
                     @input="set(formDataSettings, 'landing_background', landing_background)"
                  />
                  <ObiColorPicker
                     v-model="background_colors[0]"
                     @input="set(formDataSettings, 'landing_background', landing_background)"
                  />
               </ObiText>
            </ObiCol>
         </ObiRow>

         <ObiRow justify-between align-start class="mb-3">
            <ObiCol col="12" lg="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.landing_title')" />
               <MultiLanguageInput reverse :value="formDataSettings" name="landing_title" />
            </ObiCol>

            <ObiCol col="12" lg="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.landing_slogan')" />
               <MultiLanguageInput reverse :value="formDataSettings" name="landing_slogan" />
            </ObiCol>
         </ObiRow>

         <ObiRow justify-between align-start class="mb-3">
            <ObiCol col="12" lg="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.brand_info')" />
               <ObiFloatingInput
                  class="my-2"
                  :label="$t('fields.brand_name')"
                  v-model="formDataSettings.brand_name"
                  :errors="formDataSettings.errors.get('brand_name')"
               />
               <ObiFloatingInput
                  class="my-2"
                  :label="$t('fields.brand_long_name')"
                  v-model="formDataSettings.brand_long_name"
                  :errors="formDataSettings.errors.get('brand_long_name')"
               />
            </ObiCol>
            <ObiCol col="12" lg="5">
               <ObiTitle class="my-2" color="primary" :title="$t('fields.copyright_info')" />
               <MultiLanguageInput reverse :value="formDataSettings" name="landing_copyright" />
            </ObiCol>
         </ObiRow>
      </ObiCol>
   </ObiRow>
</template>

<script>
import Form from '@/libs/form';
import set from 'lodash/set';
export default {
   i18n: {
      messages: {
         tr: {
            fields: {
               logo: 'Logo',
               logo_desc: 'Portal için logonuzu özelleştirin Tavsiye Edilen 600 x 150 px',
               background_image: 'Arkaplan Resmi',
               brand_info: 'Marka Bilgileri',
               brand_name: 'Marka Adı',
               brand_long_name: 'Marka Adı (uzun)',
               brand_primary_color: 'Markanızın Ana Rengi',
               landing_background: 'Açılış Sayfası Arkaplanı',
               landing_slogan: 'Açılış Sayfası Sloganı',
               landing_title: 'Açılış Sayfası Başlığı',
               domain_url: "Alan Adı URL'niz",
               copyright_info: 'Telif Hakkı Bilgisi',
            },
         },
         en: {
            fields: {
               logo: 'Logo',
               logo_desc: 'Customize your logo for the portal. Recommended size: 600 x 150 px',
               background_image: 'Background Image',
               brand_info: 'Brand Information',
               brand_name: 'Brand Name',
               brand_long_name: 'Brand Name (long)',
               brand_primary_color: 'Primary Color of Your Brand',
               landing_background: 'Landing Page Background',
               landing_slogan: 'Landing Page Slogan',
               landing_title: 'Landing Page Title',
               domain_url: 'Your Domain URL',
               copyright_info: 'Copyright Information',
            },
         },
      },
   },
   data() {
      return {
         background_colors: null,
      };
   },
   methods: {
      set,
   },
   props: {
      formDataSettings: {
         type: Form,
      },
   },
   beforeMount() {
      set(this, 'background_colors', this.formDataSettings.landing_background.match(/#.{6}/g));
   },
   computed: {
      uploadURL() {
         return `/panel/tenant/images`;
      },
      landing_background() {
         const bg = this.background_colors;
         return `linear-gradient(328.72deg,${bg[0]} -4.93%,${bg[1]} 53.25%) 50%/cover`;
      },
   },
};
</script>

<style></style>
