<template>
   <ObiPage>
      <ObiToolbar hideActionButtons />

      <ObiCard class="my-4">
         <template #header>
            <ObiTitle :icon="IconIntegration" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalInregrationCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>

         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataIntegrations.get('data')"
               v-if="!isLoading && dataIntegrations.get('meta.total')"
            >
               <template #type="{ item: integration }">
                  <ObiImage width="65" :src="integration.getLogo()" />
               </template>
               <template #location="{ item: integration }">{{ integration.location }}</template>
               <template #created_at="{ item: integration }">{{ integration.created_at }}</template>
               <template #last_worked_at="{ item: integration }">
                  <ObiText class="d-flex align-items-center">
                     <ObiText
                        class="obi-integration-status-icon me-2"
                        :class="integration.getLastWorkedAt() === null ? 'offline' : 'online'"
                     />
                     <ObiText v-if="integration.getLastWorkedAt() !== null">
                        <timeago :datetime="integration.getLastWorkedAt()" locale="tr" :auto-update="60"></timeago
                     ></ObiText>
                     <ObiText v-else>{{ $t('integration_never_connected') }}</ObiText>
                  </ObiText>
               </template>
               <template #actions="{ item: integration }">
                  <ObiText class="d-flex">
                     <ObiText style="display: none;">
                        <ObiButton
                           size="sm"
                           color="primary"
                           outline
                           as="router-link"
                           :text="$t('actions.edit')"
                           :to="{ name: 'integrations-edit', params: { integrationId: integration.id } }"
                        />
                     </ObiText>
                     <ObiText>
                        <v-popover>
                           <ObiButton
                              class="ms-2"
                              size="sm"
                              compact
                              color="danger"
                              :icon="DeleteIconWhite"
                              :text="$t('actions.delete')"
                           />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_delete')" />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 v-close-popover
                                 @click="deleteIntegration(integration)"
                              />
                              <ObiButton
                                 class="ms-2"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </ObiText>
                  </ObiText>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoading && !dataIntegrations.get('meta.total')" icon="integration">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalInregrationCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoading" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataIntegrations.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalIntegrationCreate ref="modalInregrationCreate" @success="loadIntegrations" />
   </ObiPage>
</template>

<script>
import merge from 'lodash/merge';

import IconIntegration from '@/components/Icons/general/IconIntegration';
import ModalIntegrationCreate from '@/views/pages/Integration/_Modals/Create';

import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';

import Integration from '@/api/Models/Integration';
import PanelIntegrationApi from '@/api/PanelIntegrationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';
import DeleteIconWhite from '@/components/Icons/portals/DeleteIconWhite.svg';

export default {
   name: 'PageIntegrationIndex',

   i18n: {
      messages: {
         tr: {
            page_title: 'Entegrasyonlarım',
            are_you_sure_delete: 'Entegrasyonu Silmek istediğinize emin misiniz?',
            last_seen: 'Son Çalışma',
            integration_never_connected: 'Hiç Çalışmadı',
            fields: {
               brand: 'Cihaz Marka',
               model: 'Model Adı',
               name: 'Adı',
               status: 'Son Çalışma',
               location: 'Konum',
               mac_address: 'MAC',
               type: 'Türü',
               actions: 'İşlemler',
            },
            actions: {
               create: 'Entegrasyon Ekle',
               edit: 'Düzenle',
               delete: 'Sil',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
         en: {
            page_title: 'My Integrations',
            are_you_sure_delete: 'Are you sure you want to delete the integration?',
            last_seen: 'Last Activity',
            integration_never_connected: 'Never Connected',
            fields: {
               brand: 'Device Brand',
               model: 'Model Name',
               name: 'Name',
               status: 'Last Activity',
               location: 'Location',
               mac_address: 'MAC Address',
               type: 'Type',
               actions: 'Actions',
            },
            actions: {
               create: 'Add Integration',
               edit: 'Edit',
               delete: 'Delete',
               yes: 'Yes',
               no: 'No',
            },
         },
      },
   },

   components: {
      ModalIntegrationCreate,
   },

   data() {
      return {
         IconIntegration,
         DeleteIconWhite,
         isLoading: false,
         dataIntegrations: PaginationResponse.create().map(Integration),
         tableHeaders: [
            {
               text: this.$t('fields.type'),
               value: 'type',
               sortable: true,
               width: 200,
            },
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.status'),
               value: 'last_worked_at',
               sortable: false,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
            },
         ],
      };
   },

   async mounted() {
      await this.loadIntegrations();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadIntegrations({ page });
      },

      async loadIntegrations(params) {
         try {
            this.isLoading = true;

            params = merge(this.$route.query, params);

            this.dataIntegrations.merge(await PanelIntegrationApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async deleteIntegration(integration) {
         try {
            console.log(integration);
            this.isLoading = true;
            await PanelIntegrationApi.delete(integration.getId(), null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            this.loadIntegrations();
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-integration-status-icon {
   width: 12px;
   height: 12px;
   border-radius: 12px;

   &.online {
      background: #31ba3f;
   }

   &.offline {
      background: #ff8e8e;
   }
}
</style>
