import Http from '@/libs/http';

class PanelIntegrationApi extends Http {
   index(params) {
      return super.get('panel/integration', { params });
   }

   available(params) {
      return super.get('panel/integration/available', { params });
   }

   test(params) {
      return super.post('panel/integration/test', params);
   }

   store(payload, params) {
      return super.post('panel/integration', payload, { params });
   }

   show(id, params) {
      return super.get(`panel/integration/${id}`, { params });
   }

   update(id, payload, params) {
      return super.put(`panel/integration/${id}`, payload, { params });
   }

   delete(id, params) {
      return super.delete(`panel/integration/${id}`, { params });
   }
}

export default new PanelIntegrationApi();
