<template>
   <div class="obi-auth">
      <div class="obi-landing" :style="{ background: backgroundColor }">
         <div class="obi-landing--top">
            <img class="obi-landing-logo" :src="tenant.landing.logo.src" alt="alt" />
            <h1 class="obi-landing-title">{{ tenant.landing.title[language] }}</h1>
            <div class="obi-landing-description">{{ tenant.landing.description[language] }}</div>
         </div>
         <div class="obi-landing--bottom">
            <img
               v-if="tenant.landing.bottom.image.src !== null"
               class="obi-landing-image"
               :src="tenant.landing.bottom.image.src"
               alt="alt"
            />
            <div class="obi-landing-copyright">{{ tenant.landing.bottom.copyright[language] }}</div>
         </div>
      </div>
      <div class="obi-router-view">
         <div class="language-bar d-flex align-items-center">
            <IconFlagTr v-if="currentLanguage.key == 'tr'" class="mr-4" />
            <IconFlagEn v-else-if="currentLanguage.key == 'en'" class="mr-4" />
            <ObiDropdown class="obi-team-dropdown ms-2" :title="currentLanguage.name" as="team-list">
               <a
                  class="dropdown-item"
                  href="#"
                  v-for="language in languages"
                  :key="language.key"
                  @click="changeLocale(language)"
               >
                  <IconFlagTr v-if="language.key == 'tr'" class="mr-2" />
                  <IconFlagEn v-else-if="language.key == 'en'" class="mr-2" />
                  <span> {{ language.name }}</span>
               </a>
            </ObiDropdown>
         </div>

         <router-view />
      </div>
   </div>
</template>
<script>
export default {
   name: 'ObiAuth',
   computed: {
      backgroundColor() {
         return this.tenant.landing.background;
      },
      currentLanguage() {
         const locales = this.languages.filter((x) => x.key == this.$i18n.locale);

         return locales && locales.length > 0 ? locales[0] : this.locales[0];
      },
   },
   data() {
      return {
         lang: 'tr',
         languages: [
            {
               name: 'Türkçe',
               key: 'tr',
            },
            {
               name: 'English',
               key: 'en',
            },
         ],
      };
   },
   methods: {
      changeLocale(lang) {
         localStorage.setItem('last-locale', lang.key);

         this.$i18n.locale = lang.key;
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-auth {
   height: 100vh;
   display: grid;
   grid-template-columns: 800px 1fr;

   @media (max-width: 1200px) {
      display: block;
   }

   .language-bar {
      right: 40px;
      position: absolute;
      top: 20px;
   }

   .obi-router-view {
      height: 100%;
      display: grid;
      overflow-x: hidden;
      align-items: center;
   }

   .obi-landing {
      color: #fff;
      height: 100%;
      overflow-x: hidden;
      padding: 180px 120px 0 120px;

      @media (max-width: 1200px) {
         display: none;
      }

      .obi-landing--top {
         width: 350px;
         margin: 0 auto;
      }

      .obi-landing--bottom {
         margin-top: 25px;
      }

      .obi-landing-logo {
         margin-bottom: 2rem;
         max-width: 200px;
      }

      .obi-landing-title {
         font-size: 20px;
         font-weight: 600;
         margin-bottom: 1rem;
      }

      .obi-landing-description {
         color: #ccc;
         font-size: 15px;
      }

      .obi-landing-image {
         width: 500px;
      }

      .obi-landing-copyright {
         margin: 50px 0 10px;
         font-size: 10px;
         text-align: center;
      }
   }
}
</style>
