import '@/registerServiceWorker';

import Vue from 'vue';
import App from '@/App';
import i18n from '@/i18n';
import store from '@/store';
import router from '@/router';

import '@/plugins/globalComponents';

import '@/plugins/vue-ip';
import '@/plugins/vue-clipboard2';
import '@/plugins/vue-googlemaps';
import '@/plugins/vue-notification';
import '@/plugins/vue-draggable';
import '@/plugins/vue-confirm-dialog';
import '@/plugins/vue-timeago';
import '@/plugins/vue-tooltip';
import '@/plugins/vue-ctk-datetimepicker';
import '@/plugins/vue-intercom';

import '@/assets/scss/main.scss';
import mixins from './plugins/mixins';

Vue.config.productionTip = false;
Vue.config.disableNoTranslationWarning = false;
Vue.mixin(mixins);

new Vue({
   router,
   store,
   i18n,
   render: (h) => h(App),
}).$mount('#app');
