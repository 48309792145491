<template>
   <ObiPage>
      <ObiCard>
         <template #header>
            <ObiTitle class="mb-4" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalDeviceNetworkCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>
         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataDeviceNetworks.get('data')"
               v-if="!isLoadingNetworks && dataDeviceNetworks.get('meta.total')"
            >
               <template #portal="{ item: network }">
                  <ObiText v-if="network.getPortal()">
                     {{ network.getPortal().name }}
                  </ObiText>
               </template>
               <template #profile="{ item: network }">
                  <ObiText v-if="network.getProfile()">
                     {{ network.getProfile().name }}
                  </ObiText>
               </template>
               <template #created_at="{ item: network }">{{ trFormatDate(network.getCreatedAt()) }}</template>
               <template #actions="{ item: network }">
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiButton
                           size="sm"
                           color="lighter"
                           :text="$t('actions.edit')"
                           @click="openEditNetworkModal(network)"
                        />
                     </ObiCol>

                     <ObiCol col="6">
                        <v-popover>
                           <ObiButton :loading="isDeleting" size="sm" color="danger" :text="$t('actions.delete')" />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_to_delete')" />
                              <ObiButton
                                 class="me-2"
                                 :loading="isDeleting"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 @click="deleteNetwork(network)"
                                 v-close-popover
                              />
                              <ObiButton
                                 :loading="isDeleting"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoadingNetworks && !dataDeviceNetworks.get('meta.total')" icon="device">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalDeviceNetworkCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoadingNetworks" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataDeviceNetworks.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalDeviceNetworkCreate ref="modalDeviceNetworkCreate" @success="loadDeviceNetworks" />
      <ModalDeviceNetworkUpdate ref="modalDeviceNetworkUpdate" @success="loadDeviceNetworks" />
   </ObiPage>
</template>

<script>
import map from 'lodash/map';
import join from 'lodash/join';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import { trFormatDate } from '@/libs/date-utils';

import DeviceNetwork from '@/api/Models/DeviceNetwork';

import PanelDeviceApi from '@/api/PanelDeviceApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconDevice from '@/components/Icons/general/IconDevice';
import ModalDeviceNetworkCreate from '@/views/pages/Device/_Modals/NetworkCreate';
import ModalDeviceNetworkUpdate from '@/views/pages/Device/_Modals/NetworkUpdate';
//import networkFormData from '@/views/pages/Device/FormData/form-data-network';

export default {
   components: {
      ModalDeviceNetworkCreate,
      ModalDeviceNetworkUpdate,
   },

   i18n: {
      messages: {
         tr: {
            page_title: 'Tanımlı Network Listesi',
            are_you_sure_to_delete: 'Network tanımını silmek istediğinize emin misiniz?',
            fields: {
               name: 'Adı',
               start_ip: 'IP',
               created_at: 'Oluşturulma Tarihi',
               actions: 'İşlemler',
               portal: 'Portal',
               Profil: 'Profil',
            },
            actions: {
               create: 'Network Ekle',
               edit: 'Düzenle',
               cancel: 'İptal Et',
               delete: 'Sil',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
         en: {
            page_title: 'List of Defined Networks',
            are_you_sure_to_delete: 'Are you sure you want to delete the network definition?',
            fields: {
               name: 'Name',
               start_ip: 'IP',
               created_at: 'Creation Date',
               actions: 'Actions',
               portal: 'Portal',
               Profile: 'Profile',
            },
            actions: {
               create: 'Add Network',
               edit: 'Edit',
               cancel: 'Cancel',
               delete: 'Delete',
               yes: 'Yes',
               no: 'No',
            },
         },
      },
   },
   data() {
      return {
         IconDevice,
         isLoadingNetworks: false,
         isLoadingStore: false,
         showAddUserModal: false,
         isDeleting: false,
         editingNetwork: null,
         dataDeviceNetworks: PaginationResponse.create().map(DeviceNetwork),
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.start_ip'),
               value: 'start_ip',
               sortable: true,
            },
            {
               text: this.$t('fields.portal'),
               value: 'portal',
               sortable: true,
            },
            {
               text: this.$t('fields.profile'),
               value: 'profile',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
      };
   },

   async mounted() {
      this.loadDeviceNetworks();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadDeviceNetworks({ page });
      },

      async loadDeviceNetworks() {
         this.isLoadingNetworks = true;

         try {
            const deviceId = this.$route.params.deviceId;

            this.dataDeviceNetworks.merge(await PanelDeviceApi.networks.index(deviceId));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingNetworks = false;
         }
      },
      async deleteNetwork(network) {
         this.isDeleting = true;

         try {
            const deviceId = this.$route.params.deviceId;
            await PanelDeviceApi.networks.delete(deviceId, network.getId());
            Notify.success(this.$t('Network silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isDeleting = false;
            this.loadDeviceNetworks();
         }
      },

      async hidePopup() {
         this.$refs['delete_popper'].doClose();
      },

      openEditNetworkModal(network) {
         this.$refs.modalDeviceNetworkUpdate.show(network);
      },

      formatRoles(roles) {
         return join(
            map(roles, (role) => role.getName()),
            ', '
         );
      },
   },
};
</script>

<style lang="scss" scoped></style>
