<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiRow>
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.title')"
               :disabled="isLoading"
               :placeholder="$t('fields.title')"
               v-model="formDataCategory.title"
               :errors="formDataCategory.errors.get('title')"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.description')"
               :disabled="isLoading"
               :placeholder="$t('fields.description')"
               v-model="formDataCategory.description"
               :errors="formDataCategory.errors.get('description')"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.title_en')"
               :disabled="isLoading"
               :placeholder="$t('fields.title_en')"
               v-model="formDataCategory.title_en"
               :errors="formDataCategory.errors.get('title_en')"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.description_en')"
               :disabled="isLoading"
               :placeholder="$t('fields.description_en')"
               v-model="formDataCategory.description_en"
               :errors="formDataCategory.errors.get('description_en')"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiTitle :title="$t('fields.status')"> </ObiTitle>
            <ObiButtonSwitch :items="statusTypes" v-model="formDataCategory.status" />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import QrMenuApi from '@/api/QrMenuApi';

import IconLayers from '@/components/Icons/general/IconLayers.svg';

import formDataCategory from '@/views/pages/QrMenu/FormData/form-data-category';

export default {
   name: 'ModalCategoryUpdate',
   i18n: {
      messages: {
         tr: {
            title: 'Kategori Ekleyin',
            subtitle: 'Kategori girerek yeni kategori ekleyebilirsiniz',
            form_title: 'Kategori Bilgilerini Giriniz',
            fields: {
               parent: 'Üst Kategori',
               title: 'Başlık (TR)',
               description: 'Açıklama (TR)',
               title_en: 'Başlık (EN)',
               description_en: 'Açıklama (EN)',
               status: 'Durum',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Category',
            subtitle: 'You can add a new category by entering the category details.',
            form_title: 'Enter Category Information',
            fields: {
               parent: 'Parent Category',
               title: 'Title (TR)',
               description: 'Description (TR)',
               title_en: 'Title (EN)',
               description_en: 'Description (EN)',
               status: 'Status',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formDataCategory,
         statusTypes: [
            {
               label: 'Göster',
               id: 1,
            },
            {
               label: 'Gösterme',
               id: 0,
            },
         ],
      };
   },
   methods: {
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formDataCategory.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await QrMenuApi.categories.store(this.formDataCategory.toObject());

            this.formDataCategory.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
