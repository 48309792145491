<template>
   <ObiPage>
      <ObiCard class="my-4 no-border">
         <template #header>
            <ObiTitle :icon="IconDevice" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalDeviceCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>

         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataDevices.get('data')"
               v-if="!isLoading && dataDevices.get('meta.total')"
               @rowClick="$router.push({ name: 'devices-show', params: { deviceId: $event.getId() } })"
            >
               <template #brand="{ item: device }">
                  <ObiImage width="65" :src="device.getBrand().getLogo()" />
               </template>
               <template #location="{ item: device }">{{ device.getLocationFormatted() }}</template>
               <template #created_at="{ item: device }">{{ device.getMacAddress() }}</template>
               <template #actions="{ item: device }">
                  <ObiButton
                     size="sm"
                     color="primary"
                     outline
                     :text="$t('actions.edit')"
                     @click.stop="$router.push({ name: 'devices-show', params: { deviceId: device.getId() } })"
                  />
                  <ObiButton
                     size="sm"
                     color="danger"
                     outline
                     class="ms-2"
                     :text="$t('actions.delete')"
                     v-confirm="{
                        loader: true,
                        okText: 'Evet',
                        cancelText: 'Hayır',
                        ok: (dialog) => deleteDevice(dialog, device),
                        message: 'Cihazı silmek istediğinize emin misiniz?',
                     }"
                  />
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoading && !dataDevices.get('meta.total')" icon="devices">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalDeviceCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoading" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataDevices.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalDeviceCreate ref="modalDeviceCreate" @success="loadDevices" />
   </ObiPage>
</template>

<script>
import merge from 'lodash/merge';

import IconDevice from '@/components/Icons/general/IconDevice.svg';
import ModalDeviceCreate from '@/views/pages/Device/_Modals/Create';

import ErrorHandler from '@/libs/error-handler';
import { trFormatDate } from '@/libs/date-utils';

import Device from '@/api/Models/Device';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

export default {
   name: 'PageDeviceIndex',

   i18n: {
      messages: {
         tr: {
            page_title: 'Cihazlarım',
            fields: {
               brand: 'Cihaz Marka',
               model: 'Model Adı',
               name: 'Adı',
               status: 'Durumu',
               location: 'Konum',
               mac_address: 'MAC',
               actions: 'İşlemler',
               last_seen_at: 'Son Görülme',
            },
            actions: {
               create: 'Cihaz Ekle',
               edit: 'Düzenle',
               delete: 'Sil',
            },
         },
         en: {
            page_title: 'My Devices',
            fields: {
               brand: 'Device Brand',
               model: 'Model Name',
               name: 'Name',
               status: 'Status',
               location: 'Location',
               mac_address: 'MAC',
               actions: 'Actions',
               last_seen_at: 'Last Seen At',
            },
            actions: {
               create: 'Add Device',
               edit: 'Edit',
               delete: 'Delete',
            },
         },
      },
   },

   components: {
      ModalDeviceCreate,
   },

   data() {
      return {
         IconDevice,
         isLoading: false,
         dataDevices: PaginationResponse.create().map(Device),
         tableHeaders: [
            {
               text: this.$t('fields.brand'),
               value: 'brand',
               sortable: true,
               width: 200,
            },
            {
               text: this.$t('fields.name'),
               value: 'name',
               sortable: true,
            },
            {
               text: this.$t('fields.last_seen_at'),
               value: 'last_seen_at',
               sortable: true,
            },
            {
               text: this.$t('fields.mac_address'),
               value: 'mac_address',
               sortable: true,
            },
            {
               text: this.$t('fields.status'),
               value: 'status',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-end',
               width: 200,
            },
         ],
      };
   },

   async mounted() {
      await this.loadDevices();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadDevices({ page });
      },

      async loadDevices(params) {
         try {
            this.isLoading = true;

            params = merge(this.$route.query, params);

            this.dataDevices.merge(await PanelDeviceApi.index(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async deleteDevice(dialog, device) {
         try {
            this.isLoading = true;
            await PanelDeviceApi.delete(device.getId(), null);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
            dialog.close();
            this.loadDevices();
         }
      },
   },
};
</script>

<style lang="scss" scoped></style>
