<template>
   <ObiModal lg as="form" ref="modal" @submit.prevent="submitForm" :hide-cancel="true">
      <template #header>
         <ObiTitle :icon="IconIntegration" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiText v-if="currentStep === 1">
         <ObiTitle class="mb-3" color="primary" :title="$t('labels.integration_type')" />
         <ObiRow>
            <ObiCol sm="3" v-for="integrationType in dataIntegrationTypes" :key="integrationType.slug">
               <ObiCard
                  selectable
                  class="integration-card border mb-4 brand-item p-4 d-flex justify-content-center align-items-center"
                  @click="selectIntegrationType(integrationType)"
                  :class="{
                     'border-primary': isIntegrationTypeSelected(integrationType),
                     'bg-primary': isIntegrationTypeSelected(integrationType),
                  }"
               >
                  <ObiImage :src="integrationType.logo" />
                  <ObiText
                     class="integration-type-text"
                     :class="{ 'text-white': isIntegrationTypeSelected(integrationType) }"
                     >{{ integrationType.name }}</ObiText
                  >
               </ObiCard>
            </ObiCol>
         </ObiRow>
         <ObiButton
            wider
            icon-right
            @click="nextStep"
            :loading="isLoading"
            :disabled="!selectedIntegrationType"
            eklendi
            class="float-end"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.next')"
         />
      </ObiText>
      <ObiText v-if="currentStep === 2">
         <ObiRow>
            <ObiFloatingInput
               :label="$t('fields.name')"
               :disabled="isLoading"
               :placeholder="$t('fields.name')"
               v-model="formDataIntegrationMain.name"
               :errors="formDataIntegrationMain.errors.get('name')"
            />
         </ObiRow>
         <ObiRow>
            <ObiCol col="6">
               <ObiFloatingInput
                  :label="$t('fields.db_host')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.db_host')"
                  v-model="formDataIntegrationConnection.db_host"
                  :errors="formDataIntegrationConnection.errors.get('db_host')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiFloatingInput
                  :label="$t('fields.db_port')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.db_port')"
                  v-model="formDataIntegrationConnection.db_port"
                  :errors="formDataIntegrationConnection.errors.get('db_port')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow>
            <ObiCol col="6">
               <ObiFloatingInput
                  :label="$t('fields.db_username')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.db_username')"
                  v-model="formDataIntegrationConnection.db_username"
                  :errors="formDataIntegrationConnection.errors.get('db_username')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiFloatingInput
                  :label="$t('fields.db_password')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.db_password')"
                  v-model="formDataIntegrationConnection.db_password"
                  :errors="formDataIntegrationConnection.errors.get('db_password')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow>
            <ObiCol col="6">
               <ObiFloatingInput
                  :label="$t('fields.db_name')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.db_name')"
                  v-model="formDataIntegrationConnection.db_name"
                  :errors="formDataIntegrationConnection.errors.get('db_name')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiFloatingInput
                  :label="$t('fields.db_table')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.db_table')"
                  v-model="formDataIntegrationConnection.db_table"
                  :errors="formDataIntegrationConnection.errors.get('db_table')"
               />
            </ObiCol>
         </ObiRow>

         <ObiButton
            wider
            color="lighter"
            @click="prevStep"
            :loading="isLoading"
            :disabled="currentStep <= 1"
            icon="mdi mdi-arrow-left"
            :text="$t('actions.prev')"
         />

         <ObiButton
            wider
            icon-right
            @click="submitForm"
            :loading="isLoading"
            eklendi
            class="float-end"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.save')"
         />
      </ObiText>
      <ObiText v-if="currentStep === 3">
         <ObiRow>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.firstname')"
                  v-model="formDataIntegrationField.firstname"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.firstname')"
                  :errors="formDataIntegrationField.errors.get('firstname')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.lastname')"
                  v-model="formDataIntegrationField.lastname"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.lastname')"
                  :errors="formDataIntegrationField.errors.get('lastname')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.birth_date')"
                  v-model="formDataIntegrationField.birth_date"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.birth_date')"
                  :errors="formDataIntegrationField.errors.get('birth_date')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.room_number')"
                  v-model="formDataIntegrationField.room_number"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.room_number')"
                  :errors="formDataIntegrationField.errors.get('room_number')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.nationality')"
                  v-model="formDataIntegrationField.nationality"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.nationality')"
                  :errors="formDataIntegrationField.errors.get('nationality')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.gender')"
                  v-model="formDataIntegrationField.gender"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.gender')"
                  :errors="formDataIntegrationField.errors.get('gender')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.email')"
                  v-model="formDataIntegrationField.email"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.email')"
                  :errors="formDataIntegrationField.errors.get('email')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.idNumber')"
                  v-model="formDataIntegrationField.idNumber"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.idNumber')"
                  :errors="formDataIntegrationField.errors.get('idNumber')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.session_start_at')"
                  v-model="formDataIntegrationField.session_start_at"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.session_start_at')"
                  :errors="formDataIntegrationField.errors.get('session_start_at')"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiSelect
                  :label="$t('fields.session_end_at')"
                  v-model="formDataIntegrationField.session_end_at"
                  :options="fieldsSelectOptions"
                  :placeholder="$t('fields.session_end_at')"
                  :errors="formDataIntegrationField.errors.get('session_end_at')"
               />
            </ObiCol>
         </ObiRow>

         <ObiButton
            wider
            color="lighter"
            @click="prevStep"
            :loading="isLoading"
            :disabled="currentStep <= 1"
            icon="mdi mdi-arrow-left"
            :text="$t('actions.prev')"
         />

         <ObiButton
            wider
            icon-right
            @click="checkConnectionField"
            :loading="isLoading"
            eklendi
            class="float-end"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.next')"
         />
      </ObiText>
      <ObiText v-if="currentStep === 4">
         <ObiFloatingInput
            :label="$t('fields.name')"
            :disabled="isLoading"
            :placeholder="$t('fields.name')"
            v-model="formDataIntegrationMain.name"
            :errors="formDataIntegrationMain.errors.get('name')"
         />
         <ObiSelect
            :label="$t('fields.location_id')"
            v-model="formDataIntegrationMain.location_id"
            :options="locationSelectOptions"
            :placeholder="$t('fields.location_id')"
            :errors="formDataIntegrationMain.errors.get('location_id')"
         />

         <ObiButton
            wider
            color="lighter"
            @click="prevStep"
            :loading="isLoading"
            :disabled="currentStep <= 1"
            icon="mdi mdi-arrow-left"
            :text="$t('actions.prev')"
         />
         <ObiButton
            wider
            icon-right
            @click="submitForm"
            :loading="isLoading"
            eklendi
            class="float-end"
            icon="mdi mdi-arrow-right"
            :text="$t('actions.save')"
         />
      </ObiText>
      <ObiText v-if="currentStep === 5">
         <ObiRow>
            <ObiCol sm="12" class="text-center">
               <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="47" cy="47" r="45.5" stroke="#2F71F2" stroke-width="3" />
                  <path
                     d="M68.2352 29L41.7646 59"
                     stroke="#2F71F2"
                     stroke-width="3"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
                  <path
                     d="M25 45.7647L41.7647 59"
                     stroke="#2F71F2"
                     stroke-width="3"
                     stroke-miterlimit="10"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <h2 style="font-weight: 500; color: #2f71f2; text-align: center;" class="mt-1">
                  Entegrasyon Başarıyla Eklendi
               </h2>
               <p
                  class="mt-1"
                  style="width: 328px; font-weight: 300; color: #777d96; text-align: center; display: inline-block;"
               >
                  Şimdi kullanmaya başlayabilirsiniz.
               </p>
            </ObiCol>
         </ObiRow>
         <ObiText class="d-flex justify-content-center mt-4">
            <ObiButton
               wider
               @click="hide"
               :loading="isLoading"
               eklendi
               color="primary"
               :text="$t('actions.integrationList')"
            />
         </ObiText>
      </ObiText>
   </ObiModal>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import IconIntegration from '@/components/Icons/general/IconIntegration';
import ErrorHandler from '@/libs/error-handler';
import Form from '@/libs/form';
import Location from '@/api/Models/Location';
import PanelIntegrationApi from '@/api/PanelIntegrationApi';
import PanelLocationApi from '@/api/PanelLocationApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import formDataIntegrationConnection from '@/views/pages/Integration/FormData/form-data-pms-connection';
import formDataIntegrationField from '@/views/pages/Integration/FormData/form-data-pms-field';
import formDataIntegrationMain from '@/views/pages/Integration/FormData/form-data-pms-main';

export default {
   name: 'ModalIntegrationCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Yeni Entegrasyon Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni entegrasyon ekleyin',
            form_title: 'Lokasyon Bilgileri',
            labels: {
               integration_type: 'Entegrasyon Türü Seçin',
               select_provider: 'Sağlayıcınızı Seçin',
               give_an_name: 'Bir İsim Verin',
            },
            fields: {
               location_id: 'Entegrasyon Lokasyonu',
               name: 'Entegrasyon Adı',
               db_host: 'DB Adresi',
               db_port: 'DB Port',
               db_username: 'DB Kullanıcı Adı',
               db_password: 'DB Parola',
               db_name: 'DB Adı',
               db_table: 'DB Tablo Adı',
               firstname: 'Adı',
               lastname: 'Soyadı',
               birth_date: 'Doğum Tarihi',
               gender: 'Cinsiyet',
               nationality: 'Uyruk',
               room_number: 'Oda Numarası',
               phone: 'Telefon',
               idNumber: 'TC/Pasaport No',
               session_start_at: 'Oturum Başlangıç',
               session_end_at: 'Oturum Bitiş',
            },
            actions: {
               prev: 'Geri',
               next: 'İleri',
               save: 'Kaydet',
               integrationList: 'Entegrasyon Listesi',
               test_connection: 'Bağlantıyı Test Et',
            },
         },
         en: {
            title: 'Add New Integration',
            subtitle: 'Add a new integration by entering the information below',
            form_title: 'Location Information',
            labels: {
               integration_type: 'Select Integration Type',
               select_provider: 'Select Your Provider',
               give_an_name: 'Give It a Name',
            },
            fields: {
               location_id: 'Integration Location',
               name: 'Integration Name',
               db_host: 'DB Host',
               db_port: 'DB Port',
               db_username: 'DB Username',
               db_password: 'DB Password',
               db_name: 'DB Name',
               db_table: 'DB Table Name',
               firstname: 'First Name',
               lastname: 'Last Name',
               birth_date: 'Birth Date',
               gender: 'Gender',
               nationality: 'Nationality',
               room_number: 'Room Number',
               phone: 'Phone',
               idNumber: 'ID/Passport Number',
               session_start_at: 'Session Start',
               session_end_at: 'Session End',
            },
            actions: {
               prev: 'Previous',
               next: 'Next',
               save: 'Save',
               integrationList: 'Integration List',
               test_connection: 'Test Connection',
            },
         },
      },
   },

   computed: {
      ...mapGetters('auth', ['authUser', '']),
      locationSelectOptions() {
         return this.dataLocations
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
      fieldsSelectOptions() {
         return this.currentSchemaColumns.map((column) => ({ label: column.name, value: column.value }));
      },
      currentTeam() {
         return get(this, 'authUser.current_team', {});
      },
   },

   async mounted() {
      this.loadAvailableIntegrations();
      this.loadLocations();
   },

   data() {
      return {
         IconIntegration,
         currentStep: 1,
         formDataIntegrationMain,
         formDataIntegrationConnection,
         formDataIntegrationField,
         isLoading: false,
         isLoadingBrands: false,
         isLoadingLocations: false,
         isIntegrationLoading: false,
         savedDevice_id: '',
         selectedIntegrationType: null,
         formDataMikrotikDevice: Form.create([
            'gateway_interface',
            'gateway_ip',
            'hotspot_address',
            'hotspot_subnet',
            'guestInterfaces',
         ]).validate({
            gateway_interface: 'required',
            gateway_ip: 'required',
            hotspot_address: 'required',
            hotspot_subnet: 'required',
            guestInterfaces: 'required',
         }),
         createdScript: '',
         dataLocations: PaginationResponse.create().map(Location),
         dataIntegrationTypes: [],
         currentSchemaColumns: [],
      };
   },

   methods: {
      show() {
         this.$refs.modal.show();
         this.clearModal();
      },
      clearModal() {
         this.currentStep = 1;
         this.createdScript = '';
         this.isIntegrationLoading = false;
         this.isLoading = false;
         this.isLoadingLocations = false;
         this.formDataIntegrationConnection.reset();
         this.formDataMikrotikDevice.gateway_interface = '';
         this.formDataMikrotikDevice.gateway_ip = '';
         this.formDataMikrotikDevice.hotspot_address = '';
         this.formDataMikrotikDevice.hotspot_subnet = '';
         this.formDataMikrotikDevice.guestInterfaces = '';
      },
      hide() {
         this.$refs.modal.hide();
         this.clearModal();
      },
      formatMAC(value) {
         if (this.formDataIntegrationConnection.mac_address.length <= 17) {
            this.formDataIntegrationConnection.mac_address = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.formDataIntegrationConnection.mac_address = this.formDataIntegrationConnection.mac_address.substr(
               0,
               17
            );
         }
      },
      async testConnection() {
         if (!this.formDataIntegrationConnection.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            let params = this.formDataIntegrationConnection.toObject();
            params.type = this.selectedIntegrationType.connection_type;
            const response = await PanelIntegrationApi.test(params);
            this.currentSchemaColumns = response.data;

            this.currentStep++;
         } catch (err) {
            ErrorHandler.handle(err);
            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
      async submitForm() {
         this.formDataIntegrationMain.location_id = this.currentTeam.id;

         if (!this.formDataIntegrationConnection.isValid({ disableDirtyCheck: true })) return;
         if (!this.formDataIntegrationMain.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            this.formDataIntegrationMain.merge({
               integration_id: this.selectedIntegrationType.id,
               params: {
                  connection: this.formDataIntegrationConnection.toObject(),
                  fields: {},
               },
            });
            const response = await PanelIntegrationApi.store(this.formDataIntegrationMain.toObject());

            this.formDataIntegrationMain.reset();
            this.formDataIntegrationConnection.reset();
            this.formDataIntegrationField.reset();

            this.currentStep = 5;
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async loadLocations() {
         try {
            this.isLoadingLocations = true;

            this.dataLocations.merge(await PanelLocationApi.index({ per_page: 9999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingLocations = false;
         }
      },
      async loadAvailableIntegrations() {
         try {
            this.isIntegrationLoading = true;

            const data = await PanelIntegrationApi.available({ per_page: 9999, type: 'pms' });
            this.dataIntegrationTypes = data.data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isIntegrationLoading = false;
         }
      },

      selectIntegrationType(integrationType) {
         this.selectedIntegrationType = integrationType;
      },

      isIntegrationTypeSelected(integration_type) {
         return this.selectedIntegrationType !== null
            ? integration_type.slug === this.selectedIntegrationType.slug
            : false;
      },

      prevStep() {
         if (this.currentStep === 1) return;

         this.currentStep = this.currentStep - 1;
      },

      nextStep() {
         this.currentStep = this.currentStep + 1;
      },

      checkConnectionField() {
         if (!this.formDataIntegrationField.isValid({ disableDirtyCheck: true })) return;
         this.currentStep++;
      },
   },
};
</script>
<style scoped>
.border-primary {
   color: #2f71f2 !important;
}
.brand-item:hover {
   border: 1px solid #2f71f2 !important;
}
.script-pre {
   height: 200px;
}
.integration-type-text {
   font-size: 18px;
}
.integration-card {
   max-height: 100px;
}
</style>
