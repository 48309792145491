import BaseModel from './BaseModel';

export default class Invite extends BaseModel {
   getEmail() {
      return this.get('email');
   }
   getId() {
      return this.get('id');
   }
}
