<template>
   <ObiModal as="form" ref="modal">
      <template #header>
         <ObiTitle icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiRow class="mt-2">
         <ObiCol col="6">
            <ObiFloatingInput
               label="MAC Adresi"
               :disabled="isLoading"
               placeholder="MAC Adresi"
               v-model="filterMac"
               @input="formatMAC"
            />
         </ObiCol>
         <ObiCol col="6">
            <ObiFloatingInput label="IP Adresi" :disabled="isLoading" placeholder="IP Adresi" v-model="filterIp" />
         </ObiCol>
      </ObiRow>
      <ObiText class="obi-failed-login mb-2" v-for="(log, index) in dataFiltered" :key="index">
         <ObiText class="d-flex justify-content-around align-items-center w-100">
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field text-center">{{ $t('FAILEDLOGIN.MAC_ADDRESS') }}</ObiText>
               <ObiText class="obi-failed-login-value text-center" v-html="log.client_mac" />
            </ObiText>
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field text-center">{{ $t('FAILEDLOGIN.IP_ADDRESS') }}</ObiText>
               <ObiText class="obi-failed-login-value text-center" v-html="log.client_ip" />
            </ObiText>
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field text-center">{{ $t('FAILEDLOGIN.TRY_COUNT') }}</ObiText>
               <ObiText class="obi-failed-login-value text-center" v-html="log.attempts.length" />
            </ObiText>
         </ObiText>
         <ObiText class="d-flex justify-content-around align-items-center w-100 bg-dark text-white mt-2 p-2">
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field">{{ $t('FAILEDLOGIN.PARAMS') }}</ObiText>
            </ObiText>
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field">{{ $t('FAILEDLOGIN.DATE') }}</ObiText>
            </ObiText>
         </ObiText>
         <ObiText
            class="d-flex justify-content-around align-items-center w-100 bg-secondary text-white bottom-white"
            v-for="(attempt, index) in log.attempts"
            :key="index"
         >
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field" v-if="attempt.provider_slug === 'room-number'">
                  <ObiText>{{ $t('FAILEDLOGIN.ROOM_NUMBER') }}: {{ attempt.params.room_number }} </ObiText>
                  <ObiText v-if="attempt.params.departure_day === null && attempt.params.departure_month === null"
                     >{{ $t('FAILEDLOGIN.BIRTH_DATE') }}: {{ attempt.params.birth_day }}/{{
                        attempt.params.birth_month
                     }}/{{ attempt.params.birth_year }}</ObiText
                  >
                  <ObiText v-if="attempt.params.departure_day !== null && attempt.params.departure_month !== null"
                     >{{ $t('FAILEDLOGIN.DEPARTURE_DATE') }}: {{ attempt.params.departure_day }}/{{
                        attempt.params.departure_month
                     }}</ObiText
                  >
               </ObiText>
               <ObiText class="obi-failed-login-field" v-if="attempt.provider_slug === 'username'">
                  <ObiText>{{ $t('FAILEDLOGIN.USERNAME') }}: {{ attempt.params.username }} </ObiText>
                  <ObiText>{{ $t('FAILEDLOGIN.PASSWORD') }}: {{ attempt.params.password }} </ObiText>
               </ObiText>
               <ObiText class="obi-failed-login-field" v-if="attempt.provider_slug === 'citizen-id'">
                  <ObiText>{{ $t('FAILEDLOGIN.TCK') }}: {{ attempt.params.citizen_id }} </ObiText>
                  <ObiText>{{ $t('FAILEDLOGIN.FIRSTNAME') }}: {{ attempt.params.firstname }} </ObiText>
                  <ObiText>{{ $t('FAILEDLOGIN.LASTNAME') }}: {{ attempt.params.lastname }} </ObiText>
                  <ObiText
                     >{{ $t('FAILEDLOGIN.BIRTH_DATE') }}: {{ attempt.params.birth_day }}/{{
                        attempt.params.birth_month
                     }}/{{ attempt.params.birth_year }}</ObiText
                  >
               </ObiText>
               <ObiText class="obi-failed-login-field" v-if="attempt.provider_slug === 'sms'">
                  <ObiText>{{ $t('FAILEDLOGIN.PHONE') }}: {{ attempt.params.phone }} </ObiText>
                  <ObiText>{{ $t('FAILEDLOGIN.CODE') }}: {{ attempt.params.code }} </ObiText>
               </ObiText>
            </ObiText>
            <ObiText class="obi-failed-login-content">
               <ObiText class="obi-failed-login-field" v-html="trFormatDateLong(attempt.created_at)" />
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiModal>
</template>

<script>
import IconVisitor from '@/components/Icons/general/IconVisitor';
import { trFormatDateLong } from '@/libs/date-utils';

export default {
   name: 'ModalFailedLogin',
   i18n: {
      messages: {
         tr: {
            title: 'Hatalı Girişler',
            subtitle: 'Aşağıdaki listede bilgileri doğrulanamayan giriş denemelerini görüntüleyebilirsiniz.',
            form_title: 'Kullanıcı Bilgileri',
            fields: {
               provider: 'Giriş Yöntemi',
               created_at: 'Tarih',
               state: 'Durum',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Failed Logins',
            subtitle: 'You can view unverified login attempts in the list below.',
            form_title: 'User Information',
            fields: {
               provider: 'Login Method',
               created_at: 'Date',
               state: 'Status',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },

   data() {
      return {
         IconVisitor,
         isLoading: false,
         filterMac: null,
         filterIp: null,
         dataLogin: [],
         tableHeaders: [
            {
               text: this.$t('fields.provider'),
               value: 'provider_slug',
               sortable: true,
               width: 200,
            },
            {
               text: this.$t('fields.state'),
               value: 'state',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: false,
               class: 'text-end',
               width: 200,
            },
         ],
      };
   },
   computed: {
      dataFiltered() {
         let result = this.dataLogin;
         if (this.filterMac !== null) {
            result = result.filter((item) => item.client_mac.indexOf(this.filterMac) !== -1);
         }

         if (this.filterIp !== null) {
            result = result.filter((item) => item.client_ip.indexOf(this.filterIp) !== -1);
         }

         return result;
      },
   },
   methods: {
      trFormatDateLong,
      show(data) {
         this.dataLogin = data;
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      formatMAC(value) {
         if (value.length <= 17) {
            this.filterMac = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.filterMac = this.filterMac.substr(0, 17);
         }
      },
      getProviderText(provider) {
         switch (provider) {
            case 'room-number':
               return 'Oda Numarası';
            case 'username':
               return 'Kullanıcı Adı';
            case 'sms':
               return 'SMS';
            case 'citizen-id':
               return 'TC Kimlik';
            default:
               return 'provider';
         }
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';

.obi-failed-login {
   padding: 20px;
   display: flex;
   flex-direction: column;
   border-radius: 8px;
   align-items: center;
   background-color: #f3f8fe;
   justify-content: space-between;

   .bottom-white {
      border-bottom: 1px solid #fff;
   }

   .obi-failed-login-content {
      display: grid;
   }

   .obi-failed-login-value {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-failed-loginfield {
      font-size: 0.8rem;
   }

   .obi-failed-login-status-icon {
      width: 12px;
      height: 12px;
      border-radius: 12px;

      &.online {
         background: #31ba3f;
      }

      &.offline {
         background: #ff8e8e;
      }
   }

   .obi-failed-login-action {
   }
}
</style>
