import BaseModel from './BaseModel';

export default class DeviceMac extends BaseModel {
   getId() {
      return this.get('id');
   }

   getMac() {
      return this.get('mac');
   }

   getNetworks() {
      return this.get('networks');
   }

   getComment() {
      return this.get('comment');
   }

   getType() {
      return this.get('type');
   }
}
