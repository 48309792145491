import Form from '@/libs/form';

const fields = ['name', 'location_id', 'params', 'integration_id'];

const rules = {
   name: 'required',
   location_id: 'required',
};

export default Form.create(fields).validate(rules);
