import Form from '@/libs/form';

const fields = [
   'profile_id',
   'name',
   'birth_day',
   'birth_month',
   'birth_year',
   'provider_type',
   'provider_id',
   'username',
   'password',
   'room_number',
   'session_start_at',
   'session_end_at',
   'special_profile',
   'max_upload',
   'max_download',
   'shared_user',
   'session_duration',
   'total_paid_day',
];

const rules = {
   name: 'required',
   provider_type: 'required',
   provider_id: 'required_if:provider_type,citizen-id',
   room_number: 'required_if:provider_type,room-number',
   username: 'required_if:provider_type,username',
   password: 'required_if:provider_type,username',
   birth_day: 'required_if:provider_type,room-number,citizen-id',
   birth_month: 'required_if:provider_type,room-number,citizen-id',
   birth_year: 'required_if:provider_type,room-number,citizen-id',
};

export default Form.create(fields).validate(rules);
