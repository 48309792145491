<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiRow>
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.title')"
               :disabled="isLoading"
               :placeholder="$t('fields.title')"
               v-model="formData.title"
               :errors="formData.errors.get('title')"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.description')"
               :disabled="isLoading"
               :placeholder="$t('fields.description')"
               v-model="formData.description"
               :errors="formData.errors.get('description')"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               :label="$t('fields.prefix')"
               :disabled="isLoading"
               :placeholder="$t('fields.prefix')"
               v-model="formData.prefix"
               :errors="formData.errors.get('prefix')"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               type="number"
               min="1"
               :label="$t('fields.number_start')"
               :disabled="isLoading"
               :placeholder="$t('fields.number_start')"
               v-model="formData.number_start"
               :errors="formData.errors.get('number_start')"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiFloatingInput
               compact
               type="number"
               min="1"
               :label="$t('fields.number_end')"
               :disabled="isLoading"
               :placeholder="$t('fields.number_end')"
               v-model="formData.number_end"
               :errors="formData.errors.get('number_end')"
            />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import QrMenuApi from '@/api/QrMenuApi';

import IconLayers from '@/components/Icons/general/IconLayers.svg';

import formData from '@/views/pages/QrMenu/FormData/form-data-desk';

export default {
   name: 'ModalCategoryUpdate',
   i18n: {
      messages: {
         tr: {
            title: 'Masa Ekleyin',
            subtitle: 'Masa bilgilerini girerek yeni masa ekleyebilirsiniz',
            form_title: 'Masa Bilgilerini Giriniz',
            fields: {
               title: 'Masa Adı',
               description: 'Açıklama',
               prefix: 'Ön Ek (Örn: Bahçe)',
               number_start: 'Başlangıç No',
               number_end: 'Bitiş No',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Table',
            subtitle: 'You can add a new table by entering table information.',
            form_title: 'Enter Table Information',
            fields: {
               title: 'Table Name',
               description: 'Description',
               prefix: 'Prefix (e.g., Garden)',
               number_start: 'Start Number',
               number_end: 'End Number',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formData,
      };
   },
   methods: {
      show() {
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formData.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await QrMenuApi.desks.store(this.formData.toObject());

            this.formData.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
