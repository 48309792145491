import BaseModel from './BaseModel';

export default class DeviceLog extends BaseModel {
   getId() {
      return this.get('id');
   }

   getLogTime() {
      return this.get('log_time');
   }

   getDownloadUrl() {
      return this.get('download_url');
   }

   getLogType() {
      switch (this.get('type')) {
         case 'proxy':
            return 'Proxy';
         case 'dhcp':
            return 'DHCP';
         default:
            return 'Firewall';
      }
   }
}
