<template>
   <ObiPage>
      <ObiToolbar :title="$t('page_title')" hideSearch hideActionButtons hideDatePicker />

      <ObiCard class="my-4">
         <template #body class="mt-4">
            <ObiTabBar v-if="!isLoading">
               <template #tabs>
                  <ObiTabs :items="tabItems" @tabChanged="onTabChanged" v-model="selectedTab" />
               </template>
               <template #actions>
                  <ObiButton
                     class="px-4"
                     @click="save()"
                     :loading="isSaving"
                     v-if="showHeaderSaveButton"
                     :text="$t('actions.save')"
                  />
               </template>

               <router-view :formDataSettings="formDataSettings" />
            </ObiTabBar>
         </template>
      </ObiCard>
   </ObiPage>
</template>

<script>
import formDataSettings from '@/views/pages/Settings/FormData/form-data-settings';
import ErrorHandler from '@/libs/error-handler';
import TenantApi from '@/api/TenantApi';
import set from 'lodash/set';
import Notify from '@/libs/notify';

import merge from 'lodash/merge';

export default {
   i18n: {
      messages: {
         tr: {
            page_title: 'Bayilik Ekranı',
            actions: {
               save: 'Kaydet',
            },
            alert: {
               fill_empty_areas: 'Boş alanları doldurun.',
            },
         },
         en: {
            page_title: 'Dealership Screen',
            actions: {
               save: 'Save',
            },
            alert: {
               fill_empty_areas: 'Fill in the empty fields.',
            },
         },
      },
   },
   computed: {
      showHeaderSaveButton() {
         return this.$route.name === 'settings-edit';
      },
   },
   data() {
      return {
         selectedTab: null,
         formDataSettings,
         isLoading: true,
         isSaving: false,
         tabItems: [
            {
               id: 'settings-edit',
               label: 'Genel',
            },
            {
               id: 'settings-users',
               label: 'Yöneticiler',
            },
         ],
      };
   },
   async mounted() {
      await this.loadTenant();
   },
   methods: {
      merge,
      set,
      async loadTenant() {
         this.isLoading = true;
         try {
            const res = await TenantApi.get();
            merge(this.formDataSettings, res);
         } catch (err) {
            ErrorHandler.handle(err);
         }
         this.isLoading = false;
      },
      async onTabChanged(tabItem) {
         if (this.$route.name === tabItem) return;

         await this.$router.push({ name: tabItem, params: { ...this.$route.params } });
      },
      async save() {
         this.isSaving = true;
         try {
            if (formDataSettings.isValid()) {
               await TenantApi.post(this.formDataSettings.toObject());
               Notify.success(this.$t('alert.success'));
            } else {
               Notify.warn(this.$t('alert.warning'), this.$t('alert.fill_empty_areas'));
            }
         } catch (err) {
            ErrorHandler.handle(err);
         }
         this.isSaving = false;
      },
   },
   async beforeMount() {
      this.selectedTab = this.$route.name;
   },
};
</script>

<style lang="scss"></style>
