<template>
   <ObiModal as="form" ref="productCreateModal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiRow>
         <ObiTitle class="mb-2" :title="$t('fields.categories')" />
         <multiselect
            placeholder="Kategori Seçiniz"
            selectLabel=""
            :close-on-select="false"
            :clear-on-select="false"
            v-model="formData.category_ids"
            :options="dataCategories"
            :multiple="true"
            label="title"
            track-by="title"
         />
      </ObiRow>

      <ObiRow class="border-2 mb-2">
         <ObiTabBar class="language-tab">
            <template #tabs>
               <ObiTabs :items="languages" v-model="selectedLanguage" />
            </template>
         </ObiTabBar>
      </ObiRow>

      <ObiText v-if="selectedLanguage == 'tr'">
         <ObiRow>
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.title')" />
               <ObiFloatingInput
                  compact
                  :label="$t('fields.title')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.title')"
                  v-model="formData.title"
                  :errors="formData.errors.get('title')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.description')" />
               <ObiFloatingInput
                  compact
                  :label="$t('fields.description')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.description')"
                  v-model="formData.description"
                  :errors="formData.errors.get('description')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.description_long')" />
               <ObiFloatingInput
                  compact
                  :label="$t('fields.description_long')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.description_long')"
                  v-model="formData.description_long"
                  :errors="formData.errors.get('description_long')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.ingredients')" />
               <ObiTagsInput
                  :disabled="isLoading"
                  v-model="formData.ingredients"
                  :errors="formData.errors.get('fields.ingredients')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.allergens')" />
               <ObiTagsInput
                  :disabled="isLoading"
                  v-model="formData.allergens"
                  :errors="formData.errors.get('fields.allergens')"
               />
            </ObiCol>
         </ObiRow>
      </ObiText>

      <ObiText v-if="selectedLanguage == 'en'">
         <ObiRow>
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.title_en')" />
               <ObiFloatingInput
                  compact
                  :label="$t('fields.title_en')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.title_en')"
                  v-model="formData.title_en"
                  :errors="formData.errors.get('title_en')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.description_en')" />
               <ObiFloatingInput
                  compact
                  :label="$t('fields.description_en')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.description_en')"
                  v-model="formData.description_en"
                  :errors="formData.errors.get('description_en')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.description_long_en')" />
               <ObiFloatingInput
                  compact
                  :label="$t('fields.description_long_en')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.description_long_en')"
                  v-model="formData.description_long_en"
                  :errors="formData.errors.get('description_long_en')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.ingredients_en')" />
               <ObiTagsInput
                  :disabled="isLoading"
                  v-model="formData.ingredients_en"
                  :errors="formData.errors.get('fields.ingredients_en')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-2">
            <ObiCol sm="12">
               <ObiTitle class="mb-2" :title="$t('fields.allergens_en')" />
               <ObiTagsInput
                  :disabled="isLoading"
                  v-model="formData.allergens_en"
                  :errors="formData.errors.get('fields.allergens_en')"
               />
            </ObiCol>
         </ObiRow>
      </ObiText>

      <ObiRow class="mt-2">
         <ObiCol sm="6">
            <ObiTitle class="mb-2" :title="$t('fields.product_time')" />
            <ObiFloatingInput
               compact
               type="number"
               min="1"
               :label="$t('fields.product_time')"
               :disabled="isLoading"
               :placeholder="$t('fields.product_time')"
               v-model="formData.product_time"
               :errors="formData.errors.get('product_time')"
            />
         </ObiCol>
         <ObiCol sm="6">
            <ObiTitle class="mb-2" :title="$t('fields.calorie')" />
            <ObiFloatingInput
               compact
               type="number"
               min="1"
               :label="$t('fields.calorie')"
               :disabled="isLoading"
               :placeholder="$t('fields.calorie')"
               v-model="formData.calorie"
               :errors="formData.errors.get('calorie')"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiTitle :title="$t('fields.status')"> </ObiTitle>
            <ObiButtonSwitch :items="statusTypes" v-model="formData.status" />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css';
import ErrorHandler from '@/libs/error-handler';
import QrMenuApi from '@/api/QrMenuApi';

import IconLayers from '@/components/Icons/general/IconLayers.svg';

import formData from '@/views/pages/QrMenu/FormData/form-data-product';
import Multiselect from 'vue-multiselect';

export default {
   name: 'ModalProductCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Ürün Ekleyin',
            subtitle: 'Ürün bilgilerini girerek yeni Ürün ekleyebilirsiniz',
            form_title: 'Ürün Bilgilerini Giriniz',
            fields: {
               ingredients: 'İçindekiler (TR)',
               allergens: 'Alerjenler (TR)',
               title: 'Ürün Başlığı (TR)',
               description: 'Kısa Açıklama (TR)',
               description_long: 'Uzun Açıklama (TR)',
               title_en: 'Ürün Başlığı (EN)',
               description_en: 'Kısa Açıklama (EN)',
               description_long_en: 'Uzun Açıklama (EN)',
               ingredients_en: 'İçindekiler (EN)',
               allergens_en: 'Alerjenler (EN)',
               amount: 'Fiyat',
               product_time: 'Hazırlanış Süresi (dk)',
               calorie: 'Kalori',
               status: 'Durum',
               categories: 'Kategoriler',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Product',
            subtitle: 'You can add a new product by entering product details.',
            form_title: 'Enter Product Information',
            fields: {
               ingredients: 'Ingredients (TR)',
               allergens: 'Allergens (TR)',
               title: 'Product Title (TR)',
               description: 'Short Description (TR)',
               description_long: 'Long Description (TR)',
               title_en: 'Product Title (EN)',
               description_en: 'Short Description (EN)',
               description_long_en: 'Long Description (EN)',
               ingredients_en: 'Ingredients (EN)',
               allergens_en: 'Allergens (EN)',
               amount: 'Price',
               product_time: 'Preparation Time (min)',
               calorie: 'Calories',
               status: 'Status',
               categories: 'Categories',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   components: { Multiselect },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formData,
         dataCategories: [],
         statusTypes: [
            {
               label: 'Göster',
               id: 1,
            },
            {
               label: 'Gösterme',
               id: 0,
            },
         ],
         selectedLanguage: 'tr',
         languages: [
            { id: 'tr', label: 'Türkçe' },
            { id: 'en', label: 'İngilizce' },
         ],
      };
   },
   methods: {
      async show() {
         this.$refs.productCreateModal.show();
         await this.loadCategories();
      },
      hide() {
         this.$refs.productCreateModal.hide();
      },

      async loadCategories() {
         this.isLoadingUsers = true;

         try {
            const { data } = await QrMenuApi.categories.index();
            this.dataCategories = data.map((item) => {
               return { id: item.id, title: item.title };
            });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingUsers = false;
         }
      },

      async submitForm() {
         if (!this.formData.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const object = this.formData.toObject();
            object.category_ids = object.category_ids.map((cat) => cat.id);
            const response = await QrMenuApi.products.store(object);

            this.formData.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
