<template>
   <div class="obi-dashboard">
      <ObiHeader />
      <ObiContent>
         <template slot="sidebar">
            <ObiSidebar />
         </template>

         <template slot="content">
            <router-view />
         </template>
      </ObiContent>
   </div>
</template>
<script>
export default {
   data() {
      return {
         routeIsDashboard: this.$router.currentRoute.name === 'dashboard-index',
      };
   },
   watch: {
      $route(newVal) {
         this.routeIsDashboard = newVal.name === 'dashboard-index';
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-dashboard {
   height: 100%;
   display: grid;
   grid-template-rows: auto 1fr;
}
</style>
