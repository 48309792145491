var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ObiPage',[_c('ObiText',{staticClass:"obi-content"},[_c('ObiRow',{staticClass:"mb-4"},[_c('ObiCol',{attrs:{"col":"8"}},[_c('ObiCard',{staticClass:"h-100",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ObiTitle',{attrs:{"title":_vm.$t('visitors.title')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ObiButtonSwitch',{attrs:{"items":_vm.logFlowIntervalOptions},on:{"change":function($event){return _vm.refreshData()}},model:{value:(_vm.logInterval),callback:function ($$v) {_vm.logInterval=$$v},expression:"logInterval"}})]},proxy:true}])})]},proxy:true}])},[(_vm.isLoadingVisitsStatistics && !_vm.firstLoadFinished)?_c('ObiLoading'):_c('div',[(!_vm.dataVisitorBandwidth || _vm.dataVisitorBandwidth.valuesSum === 0)?_c('ObiNotFound',{attrs:{"type":"user","image-height":"150","title":_vm.$t('visitors.statistic_not_enough')}}):_c('ObiLineChart',{attrs:{"chart-data":_vm.dataVisitorBandwidth,"height":100,"options":_vm.chartOptions}})],1)],1)],1),_c('ObiCol',{attrs:{"col":"4"}},[_c('ObiCard',{staticClass:"h-100",attrs:{"elevation":"0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ObiTitle',{attrs:{"title":_vm.$t('devices.title')}})]},proxy:true}])},[(_vm.isLoadingDevice && !_vm.firstLoadFinished)?_c('ObiLoading'):_c('div',{staticClass:"device-list"},[(!_vm.isLoadingDevice && !_vm.popularWebsites)?_c('ObiNotFound',{attrs:{"compact":"","type":"device","image-height":"150","title":_vm.$t('devices.not_found_title'),"description":_vm.$t('devices.not_found_description')}}):_c('ObiText',[(!_vm.isLoadingDevice && _vm.popularWebsites)?_c('ObiTable',{attrs:{"headers":[
                           {
                              text: 'Domain',
                              value: 'domain',
                              sortable: true,
                           },
                           {
                              text: 'İndirilen',
                              value: 'recv',
                              sortable: true,
                           },
                           {
                              text: 'Yüklenen',
                              value: 'sent',
                              sortable: true,
                           },
                           {
                              text: 'Toplam',
                              value: 'total',
                              sortable: true,
                           },
                        ],"data":_vm.popularWebsites.get('data')},scopedSlots:_vm._u([{key:"recv",fn:function({ item: row }){return [_vm._v(_vm._s(_vm.convertByteReadable(row.get('recv'))))]}},{key:"sent",fn:function({ item: row }){return [_vm._v(_vm._s(_vm.convertByteReadable(row.get('sent')))+" ")]}},{key:"total",fn:function({ item: row }){return [_vm._v(_vm._s(_vm.convertByteReadable(row.get('total')))+" ")]}}],null,false,1496187848)}):_vm._e()],1)],1)],1)],1)],1),_c('ObiRow',[_c('ObiCol',{attrs:{"col":"4"}},[_c('ObiCard',{staticClass:"h-100",attrs:{"elevation":"0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ObiTitle',{attrs:{"title":"Popüler Uygulamalar"}})]},proxy:true}])},[(_vm.isLoadingDevice && !_vm.firstLoadFinished)?_c('ObiLoading'):_c('div',{staticClass:"device-list"},[(!_vm.isLoadingDevice && !_vm.popularApplication)?_c('ObiNotFound',{attrs:{"compact":"","type":"device","image-height":"150","title":_vm.$t('devices.not_found_title'),"description":_vm.$t('devices.not_found_description')}}):_c('ObiText',[(!_vm.isLoadingDevice && _vm.popularApplication)?_c('ObiTable',{attrs:{"headers":[
                           {
                              text: 'Domain',
                              value: 'domain',
                              sortable: true,
                           },
                           {
                              text: 'İndirilen',
                              value: 'recv',
                              sortable: true,
                           },
                           {
                              text: 'Yüklenen',
                              value: 'sent',
                              sortable: true,
                           },
                           {
                              text: 'Toplam',
                              value: 'total',
                              sortable: true,
                           },
                        ],"data":_vm.popularApplication.get('data')},scopedSlots:_vm._u([{key:"recv",fn:function({ item: row }){return [_vm._v(_vm._s(_vm.convertByteReadable(row.get('recv'))))]}},{key:"sent",fn:function({ item: row }){return [_vm._v(_vm._s(_vm.convertByteReadable(row.get('sent')))+" ")]}},{key:"total",fn:function({ item: row }){return [_vm._v(_vm._s(_vm.convertByteReadable(row.get('total')))+" ")]}}],null,false,1496187848)}):_vm._e()],1)],1)],1)],1)],1)],1),_c('ModalFailedLogin',{ref:"modalFailedLogin"}),_c('ModalOnlineUser',{ref:"modalOnlineUser"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }