import BaseModel from './BaseModel';

export default class MacSession extends BaseModel {
   getClientMac() {
      return this.get('client_mac');
   }
   getClientProvider() {
      return this.get('client.provider_id');
   }
   getAccounting() {
      return this.get('accounting');
   }
   getDeviceId() {
      return this.get('device_id');
   }

   getTotal() {
      return this.get('total');
   }
}
