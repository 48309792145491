import Form from '@/libs/form';

const fields = ['first_name', 'last_name', 'email', 'password', 'deviceIds', 'permissions'];

const rules = {
   email: 'required|email',
};

export default Form.create(fields)
   .defaults({
      deviceIds: [],
      permissions: {
         devices: ['view', 'store', 'update', 'delete'],
         portals: ['view', 'store', 'update', 'delete'],
         clients: ['view', 'store', 'update', 'delete'],
         integrations: ['view', 'store', 'update', 'delete'],
         'basic-stats': ['view'],
      },
   })
   .validate(rules);
