<template>
   <ObiPage>
      <ObiToolbar :title="$t('page_title')" hideSearch hideActionButtons hideDatePicker />

      <ObiText class="obi-pe my-2">
         <ObiText class="obi-pe-pane pane-configuration">
            <ObiText class="pane-body">
               <ObiText class="obi-eg">
                  <ObiText class="obi-eg-nav">
                     <ObiNav class="--for-obi-eg" vertical pills :items="navItems" v-model="selectedItem" />
                  </ObiText>
                  <ObiText class="obi-eg-body">
                     <router-view />
                  </ObiText>
               </ObiText>
            </ObiText>
         </ObiText>
      </ObiText>
   </ObiPage>
</template>

<script>
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
   name: 'ObiPortalGeneralTab',
   components: {},
   i18n: {
      messages: {
         tr: {
            page_title: 'Raporlar',
         },
         en: {
            page_title: 'Reports',
         },
      },
   },

   props: {},

   beforeMount() {},

   computed: {},

   watch: {
      selectedItem: {
         deep: true,
         handler() {
            this.$router.push({ name: this.selectedItem, params: { ...this.$route.params } });
         },
      },
   },

   data() {
      return {
         selectedItem: 'multiple-sessions-by-mac',
         navItems: [
            {
               id: 'multiple-sessions-by-mac',
               label: 'MAC Adresine Göre Çoklu Oturumlar',
            },
            {
               id: 'sales-report',
               label: 'Satış Raporları',
            },
            {
               id: 'session-history',
               label: 'Oturum Geçmişi',
            },
         ],
      };
   },

   async mounted() {},

   methods: {},
};
</script>

<style lang="scss" scoped>
.obi-eg {
   height: 100%;
   display: grid;
   grid-template-columns: 15rem 1fr;

   .obi-eg-body {
      height: 100vh;
      padding: 2rem;
      background-color: #f8f8f8;
      border-radius: 0 5px 5px 5px;
      min-height: calc(100vh - 410px);
   }
}
</style>
