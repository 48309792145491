<template>
   <ObiModal as="form" ref="modal" lg>
      <template #header>
         <ObiTitle icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiRow class="mt-2">
         <ObiCol col="4">
            <ObiFloatingInput
               label="MAC Adresi"
               :disabled="isLoading"
               placeholder="MAC Adresi"
               v-model="filterMac"
               @input="formatMAC"
            />
         </ObiCol>
         <ObiCol col="4">
            <ObiFloatingInput label="IP Adresi" :disabled="isLoading" placeholder="IP Adresi" v-model="filterIp" />
         </ObiCol>
         <ObiCol col="4">
            <multiselect
               style="z-index: 999;"
               placeholder="Cihaz"
               selectLabel=""
               :close-on-select="false"
               :clear-on-select="false"
               v-model="filterDeviceIds"
               :options="dataDevices"
               :multiple="true"
               label="name"
               track-by="name"
            />
         </ObiCol>
         <ObiCol col="12">
            <ObiButton
               :disabled="isLoadingOnlineUser"
               class="me-2"
               block
               color="primary"
               @click="filterOnline"
               text="Filtrele"
            />
         </ObiCol>
      </ObiRow>
      <ObiCard class="mt-4">
         <template #body>
            <ObiTable
               style="z-index: 10;"
               :headers="tableHeaders"
               :data="dataFiltered"
               v-if="!isLoading && dataFiltered"
            >
               <template #client="{ item: online }">
                  <ObiText v-if="online.getClient()">
                     {{ online.getClient().name }}
                     <b v-if="online.getClient().provider_type == 'room-number'"
                        >({{ online.getClient().room_number }})
                     </b>
                  </ObiText>
               </template>
               <template #session_start_time="{ item: online }">
                  <ObiText v-if="online.getClient()">
                     {{ trFormatDateLong(online.getSessionStartTime()) }}
                  </ObiText>
               </template>
               <template #device_name="{ item: online }">
                  <ObiText>
                     {{ online.getDevice() ? online.getDevice().name : null }}
                  </ObiText>
               </template>
               <template #actions="{ item: online }">
                  <ObiButton
                     v-if="online.device"
                     class="me-2"
                     size="sm"
                     color="primary"
                     as="router-link"
                     :text="$t('actions.detail')"
                     :to="{ name: 'devices-show', params: { deviceId: online.getDevice().id } }"
                  />
                  <ObiButton
                     v-if="false"
                     :loading="isKickingUser(online.getRequestHid())"
                     class="me-2"
                     size="sm"
                     color="danger"
                     @click="kickUser(online.getRequestHid())"
                     :text="$t('actions.kick')"
                  />
               </template>
            </ObiTable>
         </template>
         <ObiLoading v-if="isLoading" />
         <template #footer>
            <ObiPagination :meta="dataLogin.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>
   </ObiModal>
</template>

<script>
import IconVisitor from '@/components/Icons/general/IconVisitor';
import IconExternalLink from '@/components/Icons/general/IconExternalLink.svg';

import OnlineVisitor from '@/api/Models/OnlineVisitor';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import { trFormatDateLong } from '@/libs/date-utils';
import PanelStateApi from '@/api/PanelStateApi';

import ErrorHandler from '@/libs/error-handler';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import Multiselect from 'vue-multiselect';

export default {
   name: 'ModalOnlineUser',
   i18n: {
      messages: {
         tr: {
            title: 'Online Kullanıcılar',
            subtitle: 'Aşağıdaki listede aktif kullanıcıları görüntüleyebilirsiniz.',
            form_title: 'Kullanıcı Bilgileri',
            fields: {
               provider: 'Giriş Yöntemi',
               created_at: 'Tarih',
               state: 'Durum',
               name: 'Adı',
               client_mac: 'MAC',
               client_ip: 'IP',
               actions: 'İşlemler',
               session_start_time: 'Başlama Tarihi',
               device_name: 'Cihaz',
            },
            actions: {
               detail: 'Detay',
               kick: 'Oturum Sonlandır',
               block: 'Blokla',
            },
         },
         en: {
            title: 'Online Users',
            subtitle: 'You can view active users in the list below.',
            form_title: 'User Information',
            fields: {
               provider: 'Login Method',
               created_at: 'Date',
               state: 'Status',
               name: 'Name',
               client_mac: 'MAC',
               client_ip: 'IP',
               actions: 'Actions',
               session_start_time: 'Start Time',
               device_name: 'Device',
            },
            actions: {
               detail: 'Detail',
               kick: 'End Session',
               block: 'Block',
            },
         },
      },
   },
   components: {
      Multiselect,
   },
   data() {
      return {
         IconVisitor,
         IconExternalLink,
         dataDevices: [],
         isLoading: false,
         filterDeviceIds: null,
         kickingUser: null,
         blockingUser: null,
         isLoadingOnlineUser: false,
         filterMac: null,
         filterIp: null,
         filterName: null,
         page: 1,
         per_page: 200,
         dataLogin: PaginationResponse.create().map(OnlineVisitor),
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'client',
               width: 200,
               sortable: true,
            },
            {
               text: this.$t('fields.client_mac'),
               value: 'client_mac',
               sortable: true,
            },
            {
               text: this.$t('fields.client_ip'),
               value: 'client_ip',
               sortable: true,
            },
            {
               text: this.$t('fields.device_name'),
               value: 'device_name',
               sortable: true,
            },
            {
               text: this.$t('fields.session_start_time'),
               value: 'session_start_time',
               sortable: true,
            },
         ],
      };
   },
   computed: {
      dataFiltered() {
         let result = this.dataLogin.get('data');

         /*
         if (this.filterMac !== null) {
            result = result.filter((item) => item.getMac().indexOf(this.filterMac) !== -1);
         }

         if (this.filterIp !== null) {
            result = result.filter((item) => item.getIp().indexOf(this.filterIp) !== -1);
         }

         if (this.filterName !== null) {
            result = result.filter(
               (item) =>
                  item.getClient() &&
                  item
                     .getClient()
                     .name.toLowerCase()
                     .indexOf(this.filterName.toLowerCase()) !== -1
            );
         }*/

         return result;
      },
   },

   methods: {
      trFormatDateLong,
      show(data) {
         this.dataLogin.merge(data);
         this.loadDevices();
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      isKickingUser(mac) {
         return this.kickingUser === mac ?? false;
      },
      isBlockingUser(mac) {
         return this.blockingUser === mac ?? false;
      },
      formatMAC(value) {
         if (value.length <= 17) {
            this.filterMac = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.filterMac = this.filterMac.substr(0, 17);
         }
      },
      async kickUser(hid) {
         this.kickingUser = hid;

         try {
            await PanelStateApi.kick(hid);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.kickingUser = null;
         }
      },

      async loadDevices() {
         try {
            this.isLoading = true;

            const params = { size: 1000 };
            const { data } = await PanelDeviceApi.index(params);

            this.dataDevices = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async onPageChanged(page) {
         this.page = page;
         this.filterOnline();
      },
      async filterOnline() {
         this.isLoading = true;

         try {
            const response = await PanelStateApi.onlines({
               mac: this.filterMac,
               ip: this.filterIp,
               page: this.page,
               deviceIds: this.filterDeviceIds.map((x) => x.id),
               per_page: this.per_page,
            });
            console.log(response);
            this.dataLogin.merge(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/variables';

.obi-online-user {
   padding: 10px;
   border-radius: 8px;
   background-color: #f3f8fe;

   .bottom-white {
      border-bottom: 1px solid #fff;
   }

   .obi-online-user-content-wrapper {
      display: flex;
      gap: 20px;
   }

   .obi-online-user-content {
      display: grid;
   }

   .obi-online-user-value {
      color: $primary;
      font-size: 1.25rem;
      @include text-truncate;
   }

   .obi-online-user-field {
      font-size: 0.8rem;
   }
}
</style>
