<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiTitle class="mb-3" color="primary" :title="$t('form_title')" />

      <ObiRow>
         <ObiCol sm="12">
            <ObiFloatingInput
               :label="$t('fields.name')"
               :disabled="isLoading"
               :placeholder="$t('fields.name')"
               v-model="formData.name"
               :errors="formData.errors.get('name')"
            />
         </ObiCol>
         <ObiCol sm="6">
            <ObiFloatingInput
               :label="$t('fields.start_ip')"
               :disabled="isLoading"
               :placeholder="$t('fields.start_ip')"
               v-model="formData.start_ip"
               :errors="formData.errors.get('start_ip')"
            />
         </ObiCol>
         <ObiCol sm="6">
            <ObiFloatingInput
               compact
               :label="$t('fields.subnet')"
               :disabled="isLoading"
               :placeholder="$t('fields.subnet')"
               v-model="formData.subnet"
               :errors="formData.errors.get('subnet')"
            />
         </ObiCol>
         <ObiCol sm="6">
            <ObiSelect
               :label="$t('fields.portal')"
               :disabled="isLoadingPortals"
               v-model="formData.portal_id"
               :options="portalSelectOptions"
               :placeholder="$t('fields.portal')"
               :errors="formData.errors.get('portal_id')"
            />
         </ObiCol>
         <ObiCol sm="6">
            <ObiSelect
               :label="$t('fields.profile')"
               :disabled="isLoadingProfiles"
               v-model="formData.profile_id"
               :options="profileSelectOptions"
               :placeholder="$t('fields.profile')"
               :errors="formData.errors.get('profile_id')"
            />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelDeviceApi from '@/api/PanelDeviceApi';
//import DeviceNetwork from '@/api/Models/DeviceNetwork';

import IconLayers from '@/components/Icons/general/IconLayers.svg';
import formData from '@/views/pages/Device/FormData/form-data-network';

import LocationUserProfile from '@/api/Models/LocationUserProfile';

import PanelLocationApi from '@/api/PanelLocationApi';

import Portal from '@/api/Models/Portal';
import PanelPortalApi from '@/api/PanelPortalApi';

import PaginationResponse from '@/api/DTO/PaginationResponse';

export default {
   name: 'ModalNetworkUpdate',
   i18n: {
      messages: {
         tr: {
            title: 'Network Güncelle',
            subtitle: 'Aşağıdaki bilgileri girerek network tanımını güncelleyebilirsiniz',
            form_title: 'Network Bilgilerini Giriniz',
            fields: {
               name: 'Network Adı',
               start_ip: 'IP',
               subnet: 'Subnet',
               portal: 'Oturum Portalı',
               profile: 'Oturum Porfili',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Update Network',
            subtitle: 'You can update the network definition by entering the information below',
            form_title: 'Enter Network Information',
            fields: {
               name: 'Network Name',
               start_ip: 'IP',
               subnet: 'Subnet',
               portal: 'Session Portal',
               profile: 'Session Profile',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formData,
         networkId: null,
         isLoadingPortals: false,
         isLoadingProfiles: false,
         dataProfiles: PaginationResponse.create().map(LocationUserProfile),
         dataPortals: PaginationResponse.create().map(Portal),
      };
   },
   computed: {
      portalSelectOptions() {
         return this.dataPortals.get('data').map((portal) => ({ label: portal.getName(), value: portal.getId() }));
      },
      profileSelectOptions() {
         return this.dataProfiles.get('data').map((profile) => ({ label: profile.getName(), value: profile.getId() }));
      },
   },
   methods: {
      show(network) {
         this.networkId = network.get('id');
         this.formData.merge(network.only('id', 'name', 'start_ip', 'subnet', 'portal_id', 'profile_id'));
         this.loadPortals();
         this.loadLocationUserProfiles();
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },

      async loadPortals() {
         this.isLoadingPortals = true;

         try {
            this.dataPortals.merge(await PanelPortalApi.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingPortals = false;
         }
      },
      async loadLocationUserProfiles() {
         this.isLoadingProfiles = true;

         try {
            this.dataProfiles.merge(await PanelLocationApi.userProfiles.index());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingProfiles = false;
         }
      },
      async submitForm() {
         if (!this.formData.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const response = await PanelDeviceApi.networks.update(deviceId, this.networkId, this.formData.toObject());

            this.formData.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
