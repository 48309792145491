import BaseModel from './BaseModel';

export default class Integration extends BaseModel {
   getId() {
      return this.get('id');
   }

   getName() {
      return this.get('name');
   }

   getLastWorkedAt() {
      return this.get('last_worked_at');
   }

   getLogo() {
      return this.get('type') ? this.get('type').logo : null;
   }
}
