import Form from '@/libs/form';

const fields = ['name', 'start_ip', 'subnet', 'portal_id', 'profile_id'];

const rules = {
   name: 'required',
   start_ip: 'required',
   subnet: 'required',
};

export default Form.create(fields).validate(rules);
