import Form from '@/libs/form';

const fields = ['total_user'];

const rules = {
   name: 'required',
   provider_type: 'required',
   provider_id: 'required_if:provider_type,citizen-id',
   room_number: 'required_if:provider_type,room-number',
   username: 'required_if:provider_type,username',
   password: 'required_if:provider_type,username',
   birth_day: 'required_if:provider_type,room-number,citizen-id',
   birth_month: 'required_if:provider_type,room-number,citizen-id',
   birth_year: 'required_if:provider_type,room-number,citizen-id',
};

export default Form.create(fields).validate(rules);
