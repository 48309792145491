// import Store from '@/store';
import Http from '../libs/http';

class AuthApi extends Http {
   login(payload) {
      return super.post('user/login', payload);
   }

   register(payload) {
      return super.post('user/register', payload);
   }

   // async logout() {
   //    const token = await Store.dispatch('auth/getAccessToken');
   //
   //    return super.delete(`tokens/${token}`);
   // }

   me() {
      return super.get('panel/user/me');
   }

   updateMe(payload) {
      return super.put('panel/user/me', payload);
   }

   forgot(payload) {
      return super.post(`user/forgot-password`, payload);
   }

   reset(payload) {
      return super.post(`user/reset-password`, payload);
   }

   changeTeam(teamId, payload) {
      return super.post(`panel/user/team/change/${teamId}`, payload);
   }

   acceptInvite(token, payload) {
      return super.post(`panel/user/invite/accept/${token}`, payload);
   }

   denyInvite(token, payload) {
      return super.post(`panel/user/invite/deny/${token}`, payload);
   }
}

export default new AuthApi();
