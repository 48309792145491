import Http from '../libs/http';

class QrMenuProductApi extends Http {
   index() {
      return super.get('panel/qrmenu/product');
   }

   store(payload) {
      return super.post('panel/qrmenu/product', payload);
   }

   update(id, payload) {
      return super.put(`panel/qrmenu/product/${id}`, payload);
   }

   delete(id) {
      return super.delete(`panel/qrmenu/product/${id}`);
   }
}

export default new QrMenuProductApi();
