<template>
   <ObiPage>
      <ObiText class="obi-content">
         <ObiRow class="mb-4">
            <ObiCol col="8">
               <ObiCard class="h-100">
                  <template #header>
                     <ObiTitle :title="$t('visitors.title')">
                        <template #actions>
                           <ObiButtonSwitch
                              :items="logFlowIntervalOptions"
                              v-model="logInterval"
                              @change="refreshData()"
                           />
                        </template>
                     </ObiTitle>
                  </template>
                  <ObiLoading v-if="isLoadingVisitsStatistics && !firstLoadFinished" />
                  <div v-else>
                     <ObiNotFound
                        v-if="!dataVisitorBandwidth || dataVisitorBandwidth.valuesSum === 0"
                        type="user"
                        image-height="150"
                        :title="$t('visitors.statistic_not_enough')"
                     />
                     <ObiLineChart v-else :chart-data="dataVisitorBandwidth" :height="100" :options="chartOptions" />
                  </div>
               </ObiCard>
            </ObiCol>
            <ObiCol col="4">
               <ObiCard class="h-100" elevation="0">
                  <template #header>
                     <ObiTitle :title="$t('devices.title')" />
                  </template>

                  <ObiLoading v-if="isLoadingDevice && !firstLoadFinished" />

                  <div class="device-list" v-else>
                     <ObiNotFound
                        compact
                        type="device"
                        image-height="150"
                        :title="$t('devices.not_found_title')"
                        :description="$t('devices.not_found_description')"
                        v-if="!isLoadingDevice && !popularWebsites"
                     />
                     <ObiText v-else>
                        <ObiTable
                           :headers="[
                              {
                                 text: 'Domain',
                                 value: 'domain',
                                 sortable: true,
                              },
                              {
                                 text: 'İndirilen',
                                 value: 'recv',
                                 sortable: true,
                              },
                              {
                                 text: 'Yüklenen',
                                 value: 'sent',
                                 sortable: true,
                              },
                              {
                                 text: 'Toplam',
                                 value: 'total',
                                 sortable: true,
                              },
                           ]"
                           :data="popularWebsites.get('data')"
                           v-if="!isLoadingDevice && popularWebsites"
                        >
                           <template #recv="{ item: row }">{{ convertByteReadable(row.get('recv')) }}</template>
                           <template #sent="{ item: row }">{{ convertByteReadable(row.get('sent')) }} </template>
                           <template #total="{ item: row }">{{ convertByteReadable(row.get('total')) }} </template>
                        </ObiTable>
                     </ObiText>
                  </div>
               </ObiCard>
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol col="4">
               <ObiCard class="h-100" elevation="0">
                  <template #header>
                     <ObiTitle title="Popüler Uygulamalar" />
                  </template>

                  <ObiLoading v-if="isLoadingDevice && !firstLoadFinished" />

                  <div class="device-list" v-else>
                     <ObiNotFound
                        compact
                        type="device"
                        image-height="150"
                        :title="$t('devices.not_found_title')"
                        :description="$t('devices.not_found_description')"
                        v-if="!isLoadingDevice && !popularApplication"
                     />
                     <ObiText v-else>
                        <ObiTable
                           :headers="[
                              {
                                 text: 'Domain',
                                 value: 'domain',
                                 sortable: true,
                              },
                              {
                                 text: 'İndirilen',
                                 value: 'recv',
                                 sortable: true,
                              },
                              {
                                 text: 'Yüklenen',
                                 value: 'sent',
                                 sortable: true,
                              },
                              {
                                 text: 'Toplam',
                                 value: 'total',
                                 sortable: true,
                              },
                           ]"
                           :data="popularApplication.get('data')"
                           v-if="!isLoadingDevice && popularApplication"
                        >
                           <template #recv="{ item: row }">{{ convertByteReadable(row.get('recv')) }}</template>
                           <template #sent="{ item: row }">{{ convertByteReadable(row.get('sent')) }} </template>
                           <template #total="{ item: row }">{{ convertByteReadable(row.get('total')) }} </template>
                        </ObiTable>
                     </ObiText>
                  </div>
               </ObiCard>
            </ObiCol>
         </ObiRow>
      </ObiText>

      <ModalFailedLogin ref="modalFailedLogin" />
      <ModalOnlineUser ref="modalOnlineUser" />
   </ObiPage>
</template>

<script>
import IconDevice from '@/components/Icons/general/IconDevice';
import IconVisitor from '@/components/Icons/general/IconVisitor';
import IconLocation from '@/components/Icons/general/IconLocation';
import IconIntegration from '@/components/Icons/general/IconIntegration';
import IconNotification from '@/components/Icons/general/IconNotification';
import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import PanelStateApi from '@/api/PanelStateApi';
import { formatDate } from '@/libs/date-utils';
import { convertByteReadable } from '@/libs/text-utils';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import Device from '@/api/Models/Device';
import StatisticOverview from '@/api/Models/StatisticOverview';

import ModalFailedLogin from '@/views/pages/Dashboard/_Modals/FailedLogin';
import ModalOnlineUser from '@/views/pages/Dashboard/_Modals/OnlineUser';

export default {
   name: 'PageDashboardIndex',

   props: {
      formDataDevice: {
         type: Form,
      },
   },

   components: {
      ModalFailedLogin,
      ModalOnlineUser,
   },
   i18n: {
      messages: {
         tr: {
            page_title: 'Genel Bakış',
            platforms_statistic_title: 'Popüler Platfromlar',
            browsers_statistic_title: 'Popüler Tarayıcılar',
            devices_statistic_title: 'Popüler Cihaz Türleri',
            visitors: {
               title: 'Log Akışı',
               statistic_not_enough: 'İstatistik için henüz yeterli veriye sahip değiliz :(',
            },
            devices: {
               title: 'Popüler Siteler',
               create: 'Yeni Cihaz',
               not_found_title: 'Cihaz Bulunamadı',
               not_found_description:
                  'Hiç cihaz eklenmedi. Yeni cihaz eklemek için sağ üstteki Yeni Cihaz butonunu kullanabilirsiniz.',
            },
            metrics: {
               visitors_title: 'Kullanıcılar',
               devices_title: 'Cihazlar',
               locations_title: 'Konumlar',
               notifications_title: 'Bildirimler',
               integrations_title: 'Entegrasyonlar',
               online_visitor: 'Online Kullanıcı',
               unsuccessful_logins: 'Başarısız Girişler',
               returned_users: 'Tekrar Oturumlar',
            },
         },
         en: {
            page_title: 'Overview',
            platforms_statistic_title: 'Popular Platforms',
            browsers_statistic_title: 'Popular Browsers',
            devices_statistic_title: 'Popular Device Types',
            visitors: {
               title: 'Log Flow',
               statistic_not_enough: 'We do not have enough data for statistics yet :(',
            },
            devices: {
               title: 'Popular Sites',
               create: 'New Device',
               not_found_title: 'Device Not Found',
               not_found_description:
                  'No devices have been added. You can use the New Device button in the upper right corner to add a new device.',
            },
            metrics: {
               visitors_title: 'Users',
               devices_title: 'Devices',
               locations_title: 'Locations',
               notifications_title: 'Notifications',
               integrations_title: 'Integrations',
               online_visitor: 'Online User',
               unsuccessful_logins: 'Unsuccessful Logins',
               returned_users: 'Returning Sessions',
            },
         },
      },
   },

   computed: {
      /**
       * @returns {StatisticOverview}
       */
      dataStatistics() {
         return this.dataStatisticsOverview.getModel();
      },
   },

   data() {
      return {
         IconDevice,
         IconVisitor,
         IconLocation,
         IconIntegration,
         IconNotification,
         dataDevices: PaginationResponse.create().map(Device),
         popularWebsites: PaginationResponse.create().map(Device),
         popularApplication: PaginationResponse.create().map(Device),
         dataStatisticsOverview: SingleResourceResponse.create().map(StatisticOverview),
         timer: null,
         firstLoadFinished: false,
         logInterval: 'minute',
         isLoadingDevice: false,
         isLoadingVisitsStatistics: false,
         isLoadingStatisticsOverview: false,
         isLoadingBrowsersStatistics: false,
         isLoadingPlatformsStatistics: false,
         isLoadingDevicesStatistics: false,
         isLoadingFailedLogin: false,
         isLoadingOnlineUser: false,
         dataVisitorBandwidth: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataPlatformsStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataBrowsersStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataDevicesStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         chartOptions: {
            responsive: true,
            legend: {
               display: false,
            },
            scales: {
               xAxes: [
                  {
                     gridLines: {
                        display: false,
                        drawBorder: false,
                     },
                  },
               ],
               yAxes: [
                  {
                     ticks: {
                        display: false,
                     },
                     gridLines: {
                        display: false,
                        drawBorder: false,
                     },
                  },
               ],
            },
         },
         customColors: [
            '#54B6DB',
            '#0099D3',
            '#009DCB',
            '#6FBEE5',
            '#007194',
            '#60BAE3',
            '#9CD0E8',
            '#6C89C3',
            '#54B6DB',
            '#2F71F2',
            '#0F9BDC',
            '#7CBFB7',
            '#0F9BDC',
            '#254B7C',
            '#60BAE3',
            '#9CD0E8',
            '#8FB1CA',
            '#227C97',
            '#4270A1',
            '#1A78AC',
            '#8CAEC9',
            '#6697CF',
            '#AFE0EE',
            '#84929D',
         ],
         logFlowIntervalOptions: [
            {
               label: 'Gün',
               id: 'day',
            },
            {
               label: 'Saat',
               id: 'hour',
            },
            {
               label: 'Dakika',
               id: 'minute',
            },
         ],
      };
   },
   formatDate,

   async mounted() {
      await this.loadDeviceDetail();
      await this.refreshData();

      this.timer = setInterval(() => {
         this.refreshData();
         this.firstLoadFinished = true;
      }, 1000 * 60);
   },

   beforeDestroy() {
      clearInterval(this.timer);
   },

   methods: {
      convertByteReadable,
      async applyFilter() {
         this.firstLoadFinished = false;
         await this.refreshData();
      },
      async refreshData() {
         this.loadHistogram();
         this.loadPopularWebsite();
         this.loadPopularApplication();
      },

      async loadDeviceDetail() {
         this.isLoading = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const { data } = await PanelDeviceApi.show(deviceId);

            if (data.connection === null) {
               data.connection = {};
            }

            this.formDataDevice.merge(data);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      generateRandomColor(size) {
         let colors = [];
         if (size >= this.customColors.length) {
            for (let index = 0; index < size - this.customColors.length; index++) {
               this.customColors.push('#2F71F2');
            }
         }
         for (let index = 0; index < size; index++) {
            colors.push(this.customColors[index]);
         }
         return colors;
      },

      prepareChartsData(statsData, thickness, useCustomColors) {
         let result = { labels: [], datasets: [] };

         var valuesSum = 0;
         var labels = [];
         var values = [];
         statsData.forEach((element) => {
            valuesSum += element.doc_count;
            labels.push(formatDate(element.key_as_string, 'HH:mm'));
            values.push(element.doc_count);
         });

         result.valuesSum = valuesSum;

         result.labels = labels;
         result.datasets = [
            {
               barThickness: thickness,
               backgroundColor: useCustomColors ? this.generateRandomColor(statsData.length) : '#2F71F2',
               data: values,
            },
         ];

         return result;
      },

      async loadHistogram() {
         this.isLoadingVisitsStatistics = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const startAt = formatDate(this.$route.query.start_at, 'YYYY-MM-DDTHH:mm:ss');
            const endAt = formatDate(this.$route.query.end_at, 'YYYY-MM-DDTHH:mm:ss');
            const params = { interval: this.logInterval, gte: startAt, lte: endAt };

            const statsData = await PanelDeviceApi.logs.histogram(deviceId, params);

            this.dataVisitorBandwidth = this.prepareChartsData(statsData, 20, false);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingVisitsStatistics = false;
         }
      },

      async loadPopularWebsite() {
         this.isLoadingVisitsStatistics = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const startAt = formatDate(this.$route.query.start_at, 'YYYY-MM-DDTHH:mm:ss');
            const endAt = formatDate(this.$route.query.end_at, 'YYYY-MM-DDTHH:mm:ss');
            const params = { interval: this.logInterval, gte: startAt, lte: endAt };

            const statsData = await PanelDeviceApi.logs.popularWebsite(deviceId, params);

            this.popularWebsites.merge({ data: statsData });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingVisitsStatistics = false;
         }
      },

      async loadPopularApplication() {
         this.isLoadingVisitsStatistics = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const startAt = formatDate(this.$route.query.start_at, 'YYYY-MM-DDTHH:mm:ss');
            const endAt = formatDate(this.$route.query.end_at, 'YYYY-MM-DDTHH:mm:ss');
            const params = { interval: this.logInterval, gte: startAt, lte: endAt };

            const statsData = await PanelDeviceApi.logs.popularApplication(deviceId, params);

            this.popularApplication.merge({ data: statsData });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingVisitsStatistics = false;
         }
      },

      statisticMetric(metricSource) {
         if (!this[metricSource]) return 0;

         return this[metricSource].get('meta.total');
      },

      statisticLoadingState(loadingKey) {
         if (!this[loadingKey]) return false;

         return this[loadingKey];
      },
      async showFailedLogin() {
         this.isLoadingFailedLogin = true;

         try {
            const params = this.$route.query;
            const { data } = await PanelStateApi.failedLogins(params);
            this.$refs.modalFailedLogin.show(data);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingFailedLogin = false;
         }
      },
      async showOnlineUser() {
         this.isLoadingOnlineUser = true;

         try {
            const params = this.$route.query;
            params.per_page = 200;
            const response = await PanelStateApi.onlines(params);
            this.$refs.modalOnlineUser.show(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingOnlineUser = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-content {
   padding: 1rem;
   background-color: #f8f8f8;
   height: 100%;
}

.device-list {
   max-height: 300px;
   overflow: auto;
}

.stat-item-wrap {
   border-radius: 10px;

   .stat-item {
      gap: 12px;
      display: flex;
      padding-top: 30px;
      align-items: center;
      padding-bottom: 30px;
      flex-direction: column;

      &:hover {
         color: #fff;
         background-color: #2f71f2;
      }

      .stat-item-metric {
         font-size: 24px;
      }
   }
}
</style>
