import Http from '@/libs/http';

class PanelStatisticApi extends Http {
   overview(params) {
      return super.get('panel/statistics/overview', { params });
   }

   platforms(params) {
      return super.get('panel/statistics/platforms', { params });
   }

   browsers(params) {
      return super.get('panel/statistics/browsers', { params });
   }

   devices(params) {
      return super.get('panel/statistics/devices', { params });
   }

   visits(params) {
      return super.get('panel/statistics/visits', { params });
   }
}

export default new PanelStatisticApi();
