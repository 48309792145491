<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm" :height="400">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>
      <ObiText style="min-height: 400px;">
         <ObiRow>
            <ObiCol sm="12">
               <ObiFloatingInput
                  compact
                  :label="$t('fields.email')"
                  :disabled="isLoading"
                  :placeholder="$t('fields.email')"
                  v-model="formDataUser.email"
                  :errors="formDataUser.errors.get('email')"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <multiselect
                  placeholder="Cihaz Seçiniz"
                  selectLabel=""
                  :close-on-select="false"
                  :clear-on-select="false"
                  v-model="formDataUser.deviceIds"
                  :options="dataDevices"
                  :multiple="true"
                  label="name"
                  track-by="name"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <ObiTitle title="Yetkiler" class="mt-2 mb-2" />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <ObiTitle title="Cihazlar" class="mb-2" />
               <ObiButtonSwitch
                  v-model="formDataUser.permissions.devices"
                  multi
                  :items="permissionOptions.filter((x) => x.scope.includes('devices'))"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <ObiTitle title="Portallar" class="mb-2" />
               <ObiButtonSwitch
                  v-model="formDataUser.permissions.portals"
                  multi
                  :items="permissionOptions.filter((x) => x.scope.includes('portals'))"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <ObiTitle title="Misafirler" class="mb-2" />
               <ObiButtonSwitch
                  v-model="formDataUser.permissions.clients"
                  multi
                  :items="permissionOptions.filter((x) => x.scope.includes('clients'))"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <ObiTitle title="Entegrasyonlar" class="mb-2" />
               <ObiButtonSwitch
                  v-model="formDataUser.permissions.integrations"
                  multi
                  :items="permissionOptions.filter((x) => x.scope.includes('integrations'))"
               />
            </ObiCol>
         </ObiRow>
         <ObiRow>
            <ObiCol sm="12" class="mt-4">
               <ObiTitle title="Genel Bakış" class="mb-2" />
               <ObiButtonSwitch
                  v-model="formDataUser.permissions['basic-stats']"
                  multi
                  :items="permissionOptions.filter((x) => x.scope.includes('dashboard'))"
               />
            </ObiCol>
         </ObiRow>
      </ObiText>
      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.invite')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelLocationApi from '@/api/PanelLocationApi';

import IconLayers from '@/components/Icons/general/IconLayers.svg';

import formDataUser from '@/views/pages/Location/FormData/form-data-user';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import Multiselect from 'vue-multiselect';

export default {
   name: 'ModalLocationCreate',
   i18n: {
      messages: {
         tr: {
            title: 'Yönetici Davet Edin',
            subtitle: 'E-posta adresini girerek yeni yönetici davet edebilirsiniz',
            form_title: 'Kullanıcı Bilgilerini Giriniz',
            fields: {
               first_name: 'Ad',
               last_name: 'Soyad',
               email: 'E-Posta Adresi',
               password: 'Şifre',
            },
            actions: {
               invite: 'Davet Et',
            },
         },
         en: {
            title: 'Invite Administrator',
            subtitle: 'You can invite a new administrator by entering their email address',
            form_title: 'Enter User Information',
            fields: {
               first_name: 'First Name',
               last_name: 'Last Name',
               email: 'Email Address',
               password: 'Password',
            },
            actions: {
               invite: 'Invite',
            },
         },
      },
   },
   components: {
      Multiselect,
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         formDataUser,
         dataDevices: [],
         permissionOptions: [
            {
               label: 'Görme',
               id: 'view',
               scope: ['devices', 'portals', 'integrations', 'reports', 'dashboard', 'clients'],
            },
            {
               label: 'Oluşturma',
               id: 'store',
               scope: ['devices', 'portals', 'integrations', 'clients'],
            },
            {
               label: 'Güncelleme',
               id: 'update',
               scope: ['devices', 'portals', 'integrations', 'clients'],
            },
            {
               label: 'Silme',
               id: 'delete',
               scope: ['devices', 'portals', 'integrations', 'clients'],
            },
         ],
      };
   },
   methods: {
      show() {
         this.$refs.modal.show();
         this.loadDevices();
      },
      hide() {
         this.$refs.modal.hide();
      },

      async loadDevices() {
         try {
            this.isLoading = true;

            const params = { size: 1000 };
            const { data } = await PanelDeviceApi.index(params);

            this.dataDevices = data;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async submitForm() {
         if (!this.formDataUser.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const locationId = this.$route.params.locationId;
            this.formDataUser.deviceIds = this.formDataUser.deviceIds.map((item) => item.id);

            const response = await PanelLocationApi.users.invite(locationId, this.formDataUser.toObject());

            this.formDataUser.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
