<template>
   <div></div>
   <!--   <v-container fluid>-->
   <!--      <v-card :loading="isLoading" class="h-100 d-flex flex-column justify-space-between">-->
   <!--         <v-card-text>-->
   <!--            <v-row>-->
   <!--               <v-col sm="5">-->
   <!--                  <v-card elevation="0" class="bt br bb bl">-->
   <!--                     <v-card-title class="d-flex justify-space-between">-->
   <!--                        <span :class="{ 'text-muted': !formDataSessions.time_limit_enabled }">Zaman Limiti</span>-->
   <!--                        <v-switch hide-details class="mt-0" v-model="formDataSessions.time_limit_enabled" />-->
   <!--                     </v-card-title>-->
   <!--                     <v-card-text>-->
   <!--                        <div class="text-muted">-->
   <!--                           {{-->
   <!--                              $t(-->
   <!--                                 'Konuklarınızın 24 saatlik dönem için kullanabileceği maksimum oturum süresini ayarlayın.'-->
   <!--                              )-->
   <!--                           }}-->
   <!--                        </div>-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-slider-->
   <!--                           v-model="formDataSessions.time_limit"-->
   <!--                           :disabled="!formDataSessions.time_limit_enabled"-->
   <!--                           max="30"-->
   <!--                           min="1"-->
   <!--                           hide-details-->
   <!--                           append-icon="mdi-plus"-->
   <!--                           step="1"-->
   <!--                           thumb-label-->
   <!--                           prepend-icon="mdi-minus"-->
   <!--                        />-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-row align="center" justify="space-between">-->
   <!--                           <v-col>-->
   <!--                              <button class="btn"-->
   <!--                                 large-->
   <!--                                 outlined-->
   <!--                                 depressed-->
   <!--                                 color="primary"-->
   <!--                                 v-html="-->
   <!--                                    `${formDataSessions.time_limit} ${$t(`units.${formDataSessions.time_limit_unit}`)}`-->
   <!--                                 "-->
   <!--                                 :disabled="!formDataSessions.time_limit_enabled"-->
   <!--                              ></button>-->
   <!--                           </v-col>-->
   <!--                           <v-col>-->
   <!--                              <button class="btn"-toggle-->
   <!--                                 class="v-checkbox-group button class="btn"-toggle-custom"-->
   <!--                                 tile-->
   <!--                                 group-->
   <!--                                 v-model="formDataSessions.time_limit_unit"-->
   <!--                              >-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="minute"-->
   <!--                                    :disabled="!formDataSessions.time_limit_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>Dakika</span>-->
   <!--                                 </button>-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="hour"-->
   <!--                                    :disabled="!formDataSessions.time_limit_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>Saat</span>-->
   <!--                                 </button>-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="day"-->
   <!--                                    :disabled="!formDataSessions.time_limit_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>Gün</span>-->
   <!--                                 </button>-->
   <!--                              </button class="btn"-toggle>-->
   <!--                           </v-col>-->
   <!--                        </v-row>-->
   <!--                     </v-card-text>-->
   <!--                  </v-card>-->
   <!--               </v-col>-->
   <!--               <v-col sm="5" offset-sm="2">-->
   <!--                  <v-card elevation="0" class="bt br bb bl">-->
   <!--                     <v-card-title class="d-flex justify-space-between">-->
   <!--                        <span :class="{ 'text-muted': !formDataSessions.daily_bandwidth_enabled }"-->
   <!--                           >Günlük Bant Genişliği</span-->
   <!--                        >-->
   <!--                        <v-switch hide-details class="mt-0" v-model="formDataSessions.daily_bandwidth_enabled" />-->
   <!--                     </v-card-title>-->
   <!--                     <v-card-text>-->
   <!--                        <div class="text-muted">-->
   <!--                           {{-->
   <!--                              $t(-->
   <!--                                 'Konuklarınızın 24 saatlik dönem için kullanabileceği maksimum oturum süresini ayarlayın.'-->
   <!--                              )-->
   <!--                           }}-->
   <!--                        </div>-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-slider-->
   <!--                           v-model="formDataSessions.daily_bandwidth"-->
   <!--                           :disabled="!formDataSessions.daily_bandwidth_enabled"-->
   <!--                           max="1024"-->
   <!--                           min="100"-->
   <!--                           hide-details-->
   <!--                           append-icon="mdi-plus"-->
   <!--                           step="1"-->
   <!--                           thumb-label-->
   <!--                           prepend-icon="mdi-minus"-->
   <!--                        />-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-row align="center" justify="space-between">-->
   <!--                           <v-col>-->
   <!--                              <button class="btn"-->
   <!--                                 large-->
   <!--                                 outlined-->
   <!--                                 depressed-->
   <!--                                 color="primary"-->
   <!--                                 v-html="`${formDataSessions.daily_bandwidth} ${formDataSessions.daily_bandwidth_unit}`"-->
   <!--                                 :disabled="!formDataSessions.daily_bandwidth_enabled"-->
   <!--                              ></button>-->
   <!--                           </v-col>-->
   <!--                           <v-col>-->
   <!--                              <button class="btn"-toggle-->
   <!--                                 class="v-checkbox-group button class="btn"-toggle-custom"-->
   <!--                                 tile-->
   <!--                                 group-->
   <!--                                 v-model="formDataSessions.daily_bandwidth_unit"-->
   <!--                              >-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="KB"-->
   <!--                                    :disabled="!formDataSessions.daily_bandwidth_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>KB</span>-->
   <!--                                 </button>-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="MB"-->
   <!--                                    :disabled="!formDataSessions.daily_bandwidth_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>MB</span>-->
   <!--                                 </button>-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="GB"-->
   <!--                                    :disabled="!formDataSessions.daily_bandwidth_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>GB</span>-->
   <!--                                 </button>-->
   <!--                                 <button class="btn"-->
   <!--                                    depressed-->
   <!--                                    large-->
   <!--                                    :ripple="false"-->
   <!--                                    value="TB"-->
   <!--                                    :disabled="!formDataSessions.daily_bandwidth_enabled"-->
   <!--                                 >-->
   <!--                                    <v-icon>mdi-checkbox-marked</v-icon>-->
   <!--                                    <span>TB</span>-->
   <!--                                 </button>-->
   <!--                              </button class="btn"-toggle>-->
   <!--                           </v-col>-->
   <!--                        </v-row>-->
   <!--                     </v-card-text>-->
   <!--                  </v-card>-->
   <!--               </v-col>-->
   <!--               <v-col sm="5">-->
   <!--                  <v-card elevation="0" class="bt br bb bl">-->
   <!--                     <v-card-title class="d-flex justify-space-between">-->
   <!--                        <span :class="{ 'text-muted': !formDataSessions.download_limit_enabled }"-->
   <!--                           >Maksimum İndirme Limiti</span-->
   <!--                        >-->
   <!--                        <v-switch hide-details class="mt-0" v-model="formDataSessions.download_limit_enabled" />-->
   <!--                     </v-card-title>-->
   <!--                     <v-card-text>-->
   <!--                        <div class="text-muted">-->
   <!--                           {{-->
   <!--                              $t(-->
   <!--                                 'Konuklarınızın 24 saatlik dönem için kullanabileceği maksimum oturum süresini ayarlayın.'-->
   <!--                              )-->
   <!--                           }}-->
   <!--                        </div>-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-slider-->
   <!--                           v-model="formDataSessions.download_limit"-->
   <!--                           :disabled="!formDataSessions.download_limit_enabled"-->
   <!--                           max="100"-->
   <!--                           min="1"-->
   <!--                           hide-details-->
   <!--                           append-icon="mdi-plus"-->
   <!--                           step="1"-->
   <!--                           thumb-label-->
   <!--                           prepend-icon="mdi-minus"-->
   <!--                        />-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <button class="btn"-->
   <!--                           large-->
   <!--                           outlined-->
   <!--                           depressed-->
   <!--                           color="primary"-->
   <!--                           v-html="`${formDataSessions.download_limit} Mbps`"-->
   <!--                           :disabled="!formDataSessions.download_limit_enabled"-->
   <!--                        ></button>-->
   <!--                     </v-card-text>-->
   <!--                  </v-card>-->
   <!--               </v-col>-->
   <!--               <v-col sm="5" offset-sm="2">-->
   <!--                  <v-card elevation="0" class="bt br bb bl">-->
   <!--                     <v-card-title class="d-flex justify-space-between">-->
   <!--                        <span :class="{ 'text-muted': !formDataSessions.upload_limit_enabled }"-->
   <!--                           >Maksimum Yükleme Limiti</span-->
   <!--                        >-->
   <!--                        <v-switch hide-details class="mt-0" v-model="formDataSessions.upload_limit_enabled" />-->
   <!--                     </v-card-title>-->
   <!--                     <v-card-text>-->
   <!--                        <div class="text-muted">-->
   <!--                           {{-->
   <!--                              $t(-->
   <!--                                 'Konuklarınızın 24 saatlik dönem için kullanabileceği maksimum oturum süresini ayarlayın.'-->
   <!--                              )-->
   <!--                           }}-->
   <!--                        </div>-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-slider-->
   <!--                           v-model="formDataSessions.upload_limit"-->
   <!--                           :disabled="!formDataSessions.upload_limit_enabled"-->
   <!--                           max="100"-->
   <!--                           min="1"-->
   <!--                           hide-details-->
   <!--                           append-icon="mdi-plus"-->
   <!--                           step="1"-->
   <!--                           thumb-label-->
   <!--                           prepend-icon="mdi-minus"-->
   <!--                        />-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <button class="btn"-->
   <!--                           large-->
   <!--                           outlined-->
   <!--                           depressed-->
   <!--                           color="primary"-->
   <!--                           v-html="`${formDataSessions.upload_limit} Mbps`"-->
   <!--                           :disabled="!formDataSessions.upload_limit_enabled"-->
   <!--                        ></button>-->
   <!--                     </v-card-text>-->
   <!--                  </v-card>-->
   <!--               </v-col>-->
   <!--               <v-col sm="5">-->
   <!--                  <v-card elevation="0" class="bt br bb bl">-->
   <!--                     <v-card-title class="d-flex justify-space-between">-->
   <!--                        <span :class="{ 'text-muted': !formDataSessions.mac_whitelist_enabled }">MAC Whitelist</span>-->
   <!--                        <v-switch hide-details class="mt-0" v-model="formDataSessions.mac_whitelist_enabled" />-->
   <!--                     </v-card-title>-->
   <!--                     <v-card-text>-->
   <!--                        <div class="text-muted">-->
   <!--                           {{-->
   <!--                              $t(-->
   <!--                                 'Konuk Cihaz MAC Adresleri (örneğin A1:B2:C3:D4:E5:F6) açık İnternet erişimini almak için bu listeye eklenebilir.'-->
   <!--                              )-->
   <!--                           }}-->
   <!--                        </div>-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-text-field-->
   <!--                           outlined-->
   <!--                           hide-details-->
   <!--                           v-model="formData.mac_whitelist_input"-->
   <!--                           :error-messages="formData.errors.name"-->
   <!--                           :disabled="!formDataSessions.mac_whitelist_enabled"-->
   <!--                        >-->
   <!--                           <template v-slot:append>-->
   <!--                              <button class="btn"-->
   <!--                                 depressed-->
   <!--                                 outlined-->
   <!--                                 color="primary"-->
   <!--                                 v-html="$t('Add')"-->
   <!--                                 :disabled="!formDataSessions.mac_whitelist_enabled"-->
   <!--                                 @click="-->
   <!--                                    formDataSessions.mac_whitelist.push(formData.mac_whitelist_input);-->
   <!--                                    formData.mac_whitelist_input = '';-->
   <!--                                 "-->
   <!--                              ></button>-->
   <!--                           </template>-->
   <!--                        </v-text-field>-->

   <!--                        <div v-if="formDataSessions.mac_whitelist.length">-->
   <!--                           <v-input-->
   <!--                              outlined-->
   <!--                              hide-details-->
   <!--                              :key="index"-->
   <!--                              class="v-input&#45;&#45;noncustom align-center bt br bb bl px-3 rounded mt-3"-->
   <!--                              v-for="(line, index) in formDataSessions.mac_whitelist"-->
   <!--                           >-->
   <!--                              <template v-slot:append>-->
   <!--                                 <button class="btn"-->
   <!--                                    icon-->
   <!--                                    depressed-->
   <!--                                    color="red"-->
   <!--                                    :disabled="!formDataSessions.mac_whitelist_enabled"-->
   <!--                                    @click="formDataSessions.mac_whitelist.splice(index, 1)"-->
   <!--                                 >-->
   <!--                                    <v-icon color="red">mdi-delete</v-icon>-->
   <!--                                 </button>-->
   <!--                              </template>-->
   <!--                              {{ line }}-->
   <!--                           </v-input>-->
   <!--                        </div>-->
   <!--                        <v-alert dense type="info" class="mt-3" v-else>Listeye Kimse Eklenmedi!</v-alert>-->
   <!--                     </v-card-text>-->
   <!--                  </v-card>-->
   <!--               </v-col>-->
   <!--               <v-col sm="5" offset-sm="2">-->
   <!--                  <v-card elevation="0" class="bt br bb bl">-->
   <!--                     <v-card-title class="d-flex justify-space-between">-->
   <!--                        <span :class="{ 'text-muted': !formDataSessions.mac_blacklist_enabled }">MAC Blacklist</span>-->
   <!--                        <v-switch hide-details class="mt-0" v-model="formDataSessions.mac_blacklist_enabled" />-->
   <!--                     </v-card-title>-->
   <!--                     <v-card-text>-->
   <!--                        <div class="text-muted">-->
   <!--                           {{-->
   <!--                              $t(-->
   <!--                                 'Konuk Cihaz MAC Adresleri (ör. A1:B2:C3:D4:E5:F6) bu listeye eklenebilir ve ardından İnternet erişimi engellenir.'-->
   <!--                              )-->
   <!--                           }}-->
   <!--                        </div>-->
   <!--                     </v-card-text>-->
   <!--                     <v-card-text>-->
   <!--                        <v-text-field-->
   <!--                           outlined-->
   <!--                           hide-details-->
   <!--                           v-model="formData.mac_blacklist_input"-->
   <!--                           :error-messages="formData.errors.name"-->
   <!--                           :disabled="!formDataSessions.mac_whitelist_enabled"-->
   <!--                        >-->
   <!--                           <template v-slot:append>-->
   <!--                              <button class="btn"-->
   <!--                                 depressed-->
   <!--                                 outlined-->
   <!--                                 color="primary"-->
   <!--                                 v-html="$t('Add')"-->
   <!--                                 :disabled="!formDataSessions.mac_blacklist_enabled"-->
   <!--                                 @click="-->
   <!--                                    formDataSessions.mac_blacklist.push(formData.mac_blacklist_input);-->
   <!--                                    formData.mac_blacklist_input = '';-->
   <!--                                 "-->
   <!--                              ></button>-->
   <!--                           </template>-->
   <!--                        </v-text-field>-->

   <!--                        <div v-if="formDataSessions.mac_blacklist.length">-->
   <!--                           <v-input-->
   <!--                              outlined-->
   <!--                              hide-details-->
   <!--                              :key="index"-->
   <!--                              class="v-input&#45;&#45;noncustom align-center bt br bb bl px-3 rounded mt-3"-->
   <!--                              v-for="(line, index) in formDataSessions.mac_blacklist"-->
   <!--                           >-->
   <!--                              <template v-slot:append>-->
   <!--                                 <button class="btn"-->
   <!--                                    icon-->
   <!--                                    depressed-->
   <!--                                    color="red"-->
   <!--                                    :disabled="!formDataSessions.mac_blacklist_enabled"-->
   <!--                                    @click="formDataSessions.mac_blacklist.splice(index, 1)"-->
   <!--                                 >-->
   <!--                                    <v-icon color="red">mdi-delete</v-icon>-->
   <!--                                 </button>-->
   <!--                              </template>-->
   <!--                              {{ line }}-->
   <!--                           </v-input>-->
   <!--                        </div>-->
   <!--                        <v-alert dense type="info" class="mt-3" v-else>Listeye Kimse Eklenmedi!</v-alert>-->
   <!--                     </v-card-text>-->
   <!--                  </v-card>-->
   <!--               </v-col>-->
   <!--            </v-row>-->
   <!--         </v-card-text>-->
   <!--      </v-card>-->
   <!--   </v-container>-->
</template>

<script>
import IconLocation from '@/components/Icons/general/IconLocation';

import Form from '@/libs/form';
// import ErrorHandler from '@/libs/error-handler';
// import PanelLocationApi from '@/api/PanelLocationApi';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';

export default {
   props: {
      formDataSessions: {
         type: Form,
      },
   },
   data() {
      return {
         IconLocation,
         isLoading: false,
         formData: Form.create(['mac_whitelist_input', 'mac_blacklist_input']).validate({
            mac_whitelist_input: 'string',
            mac_blacklist_input: 'string',
         }),
         dataLocation: SingleResourceResponse.create(),
      };
   },
   async mounted() {
      // await this.loadLocationDetail();
   },
   methods: {
      // async loadLocationDetail() {
      //    this.isLoading = true;
      //
      //    try {
      //       const locationId = this.$route.params.locationId;
      //
      //       const { data } = await PanelLocationApi.show(locationId);
      //
      //       this.dataLocation.merge(data);
      //
      //       const formData = this.dataLocation.only('name', 'country', 'city', 'district');
      //       this.formDataLocation.merge(formData);
      //    } catch (err) {
      //       ErrorHandler.handle(err);
      //    } finally {
      //       this.isLoading = false;
      //    }
      // }
   },
};
</script>

<style lang="scss" scoped></style>
