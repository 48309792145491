<template>
   <ObiCard>
      <template #header>
         <ObiRow class="mt-2">
            <ObiCol col="6">
               <ObiFloatingInput
                  label="MAC Adresi"
                  :disabled="isLoading"
                  placeholder="MAC Adresi"
                  v-model="filterMac"
                  @input="formatMAC"
               />
            </ObiCol>
            <ObiCol col="6">
               <ObiButton
                  :disabled="isLoading"
                  class="btn-lg"
                  block
                  color="primary"
                  @click="getMultipleSessions(1)"
                  text="Filtrele"
               />
            </ObiCol>
         </ObiRow>
      </template>
      <template #body>
         <ObiTable v-if="!isLoading" :data="dataMacSessions.get('data')" :headers="multipleSessionsTableHeaders">
            <template #actions="{ item: session }">
               <ObiButton
                  :loading="isBlockingUser(session.getClientMac())"
                  class="me-2"
                  size="sm"
                  color="danger"
                  @click="blockMac(session)"
                  :text="$t('actions.block')"
               />
            </template>
         </ObiTable>
         <ObiLoading v-if="isLoading" />
      </template>
      <template #footer>
         <ObiPagination :meta="dataMacSessions.get('meta')" @page-changed="getMultipleSessions" />
      </template>
   </ObiCard>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelReportApi from '@/api/PanelReportApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import MacSession from '@/api/Models/MacSession';

export default {
   name: 'MacSession',
   components: {},

   i18n: {
      messages: {
         tr: {
            page_title: 'Raporlar',
            fields: {
               client_mac: 'Mac Adresi',
               total_sessions: 'Toplam Oturum',
               actions: 'İşlemler',
            },
            actions: {
               block: 'Blokla',
            },
         },
         en: {
            page_title: 'Reports',
            fields: {
               client_mac: 'Mac Address',
               total_sessions: 'Total Sessions',
               actions: 'Actions',
            },
            actions: {
               block: 'Block',
            },
         },
      },
   },

   data() {
      return {
         dataMacSessions: PaginationResponse.create().map(MacSession),
         isLoading: false,
         filterMac: null,
         blockingUsers: [],
         multipleSessionsTableHeaders: [
            {
               text: this.$t('fields.client_mac'),
               value: 'client_mac',
               sortable: true,
            },
            {
               text: this.$t('fields.total_sessions'),
               value: 'total',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               width: 300,
            },
         ],
      };
   },
   async mounted() {
      await this.getMultipleSessions(1);
   },

   methods: {
      isBlockingUser(mac) {
         return this.blockingUsers.indexOf(mac) > -1 ? true : false;
      },
      async getMultipleSessions(page) {
         this.isLoading = true;
         try {
            const response = await PanelReportApi.multipleSessions({
               per_page: 100,
               page: page,
               client_mac: this.filterMac,
            });
            this.dataMacSessions.merge(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async blockMac(session) {
         this.blockingUsers.push(session.getClientMac());
         try {
            await PanelDeviceApi.macs.store(session.getDeviceId(), {
               mac: session.getClientMac(),
               comment: 'çoklu oturum tespiti',
               type: 'blocked',
            });
            this.dataMacSessions.get('data').splice(this.dataMacSessions.get('data').indexOf(session), 1);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.blockingUsers.splice(this.blockingUsers.indexOf(session.getClientMac()), 1);
         }
      },
      formatMAC(value) {
         if (value.length <= 17) {
            this.filterMac = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.filterMac = this.filterMac.substr(0, 17);
         }
      },
   },
};
</script>
