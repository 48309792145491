<template>
   <ObiText>
      <div v-if="formDataDevice">
         <ObiRow v-if="formDataDevice && !isLoading">
            <ObiCol col="4">
               <ObiFloatingInput
                  class="mb-2"
                  v-model="formDataDevice.name"
                  :label="$t('fields.name')"
                  :errors="formDataDevice.errors.get('name')"
               />
               <ObiFloatingInput
                  class="mb-2"
                  v-model="formDataDevice.mac_address"
                  :label="$t('fields.mac_address')"
                  :errors="formDataDevice.errors.get('mac_address')"
                  @input="formatMAC"
               />
               <ObiFloatingInput
                  class="mb-2"
                  v-model="formDataDevice.serial_number"
                  :label="$t('fields.serial_number')"
                  :errors="formDataDevice.errors.get('serial_number')"
               />
            </ObiCol>

            <ObiCol col="4">
               <ObiFloatingInput
                  class="mb-2"
                  v-model="formDataDevice.connection.host"
                  :label="$t('fields.connection_host')"
                  :errors="formDataDevice.errors.get('connection_host')"
               />

               <ObiFloatingInput
                  class="mb-2"
                  v-model="formDataDevice.connection.port"
                  :label="$t('fields.connection_port')"
                  :errors="formDataDevice.errors.get('connection_port')"
               />
            </ObiCol>
            <ObiCol>
               <ObiFloatingInput
                  class="mb-2"
                  v-model="formDataDevice.connection.username"
                  :label="$t('fields.connection_username')"
                  :errors="formDataDevice.errors.get('connection_username')"
               />

               <ObiFloatingInput
                  class="mb-2"
                  :type="showPassword ? 'text' : 'password'"
                  :icon="showPassword ? IconEye : IconEyeOff"
                  @icon_clicked="showPassword = !showPassword"
                  v-model="formDataDevice.connection.password"
                  :label="$t('fields.connection_password')"
                  :errors="formDataDevice.errors.get('connection_password')"
               />
            </ObiCol>
         </ObiRow>

         <ObiRow class="mt-4" v-if="isAdmin && formDataDevice && !isLoading">
            <ObiCol col="2">
               <ObiTitle class="mb-2" color="primary" :title="$t('fields.logging_enabled')" />
               <ObiButtonSwitch :items="loggingOptions" v-model="formDataDevice.logging_enabled" />
            </ObiCol>
         </ObiRow>
         <ObiRow class="mt-4" v-if="formDataDevice && formDataDevice.has_mikrolink_subscription && !isLoading">
            <ObiCol col="2">
               <ObiTitle class="mb-2" color="primary" :title="$t('fields.hotspot_enabled')" />
               <ObiButtonSwitch
                  :items="loggingOptions"
                  v-model="formDataDevice.hotspot_status"
                  @input="onHotspotStatusChanged()"
               />
            </ObiCol>
         </ObiRow>
      </div>

      <ObiLoading v-if="isLoading" />
   </ObiText>
</template>

<script>
import IconDevice from '@/components/Icons/general/IconDevice.svg';

import { mapGetters } from 'vuex';
import Form from '@/libs/form';
import ErrorHandler from '@/libs/error-handler';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';

import PaginationResponse from '@/api/DTO/PaginationResponse';

import Portal from '@/api/Models/Portal';
import PanelPortalApi from '@/api/PanelPortalApi';

import Location from '@/api/Models/Location';
import PanelLocationApi from '@/api/PanelLocationApi';

import IconEye from '@/components/Icons/form/IconEye';
import IconEyeOff from '@/components/Icons/form/IconEyeOff';

export default {
   i18n: {
      messages: {
         tr: {
            fields: {
               name: 'Cihaz Adı',
               portal: 'Karşılama Portalı',
               location: 'Konum',
               mac_address: 'MAC Adresi',
               logging_enabled: 'Loglama Durumu',
               hotspot_enabled: 'Hotspot Durumu',
               connection_host: 'Bağlantı Host/IP',
               connection_port: 'Bağlantı Portu',
               connection_username: 'Kullanıcı Adı',
               connection_password: 'Parola',
               serial_number: 'Seri Numarası',
               is_trial: 'Demo?',
               trial_day: 'Demo Gün Sayısı',
            },
         },
         en: {
            fields: {
               name: 'Device Name',
               portal: 'Welcome Portal',
               location: 'Location',
               mac_address: 'MAC Address',
               logging_enabled: 'Logging Status',
               hotspot_enabled: 'Hotspot Status',
               connection_host: 'Connection Host/IP',
               connection_port: 'Connection Port',
               connection_username: 'Username',
               connection_password: 'Password',
               serial_number: 'Serial Number',
               is_trial: 'Is Demo?',
               trial_day: 'Demo Days',
            },
         },
      },
   },

   props: {
      formDataDevice: {
         type: Form,
      },
   },

   data() {
      return {
         IconDevice,
         IconEye,
         IconEyeOff,
         isLoading: false,
         showPassword: false,
         dataDevice: SingleResourceResponse.create(),
         dataPortals: PaginationResponse.create().map(Portal),
         dataLocations: PaginationResponse.create().map(Location),
         loggingOptions: [
            {
               label: 'Aktif',
               id: true,
            },
            {
               label: 'Pasif',
               id: false,
            },
         ],
         trialOptions: [
            {
               label: 'Evet',
               id: true,
            },
            {
               label: 'Hayır',
               id: false,
            },
         ],
      };
   },

   computed: {
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      portalSelectOptions() {
         return this.dataPortals.get('data').map((device) => ({ label: device.getName(), value: device.getId() }));
      },
      locationSelectOptions() {
         return this.dataLocations
            .get('data')
            .map((location) => ({ label: location.getName(), value: location.getId() }));
      },
   },

   async mounted() {
      await this.loadDeviceDetail();
   },

   methods: {
      submitDevicePut() {
         return 'asd';
      },

      async loadLocations() {
         this.isLoading = true;

         try {
            this.dataLocations.merge(await PanelLocationApi.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async onHotspotStatusChanged() {
         this.isLoading = true;

         try {
            const deviceId = this.$route.params.deviceId;
            if (this.formDataDevice.hotspot_status === true) {
               await PanelDeviceApi.enableHotspot(deviceId);
            } else {
               await PanelDeviceApi.disableHotspot(deviceId);
            }
            await this.loadDeviceDetail();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async loadPortals() {
         this.isLoading = true;

         try {
            this.dataPortals.merge(await PanelPortalApi.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async loadDeviceDetail() {
         this.isLoading = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const { data } = await PanelDeviceApi.show(deviceId);

            if (data.connection === null) {
               data.connection = {};
            }

            this.dataDevice.merge(data);

            this.formDataDevice.merge(data);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },

      async submitDeviceUpdate() {
         if (!this.formDataDevice.isValid()) return;

         this.isLoading = true;

         try {
            const deviceId = this.$route.params.deviceId;

            await PanelDeviceApi.update(deviceId, this.formDataDevice.toObject());

            await this.$router.push({ name: 'devices-index' });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      formatMAC(value) {
         if (this.formDataDevice.mac_address.length <= 17) {
            this.formDataDevice.mac_address = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.formDataDevice.mac_address = this.formDataDevice.mac_address.substr(0, 17);
         }
      },
   },
};
</script>

<style lang="scss" scoped></style>
