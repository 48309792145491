<template>
   <ObiPage>
      <ObiCard>
         <template #header>
            <ObiTitle class="mb-4" :title="$t('page_title')">
               <template #actions>
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiTitle class="mb-2" color="primary" :title="$t('fields.log_range')" />
                        <ctk-date-time-picker
                           ref="datePicker"
                           :no-value-to-custom-elem="true"
                           locale="tr"
                           range
                           color="#2F71F2"
                           button-color="#2F71F2"
                           v-model="formDataQuery.log_range"
                           format="DD.MM.YYYY"
                           outputFormat="YYYY-MM-DD hh:mm:ss"
                           :custom-shortcuts="shortcuts"
                           @validate="loadDeviceLogs()"
                        >
                           <ObiInput
                              clean
                              compact
                              readonly
                              icon="mdi mdi-calendar"
                              :placeholder="getSelectedDateRange"
                           />
                        </ctk-date-time-picker>
                     </ObiCol>
                     <ObiCol col="6">
                        <ObiTitle class="mb-2" color="primary" :title="$t('fields.logging_types')" />
                        <ObiButtonSwitch
                           multi
                           :items="typeOptions"
                           v-model="formDataQuery.types"
                           @input="loadDeviceLogs"
                        />
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTitle>
         </template>
         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataDeviceLogs.get('data')"
               v-if="!isLoadingLogs && dataDeviceLogs.get('meta.total')"
               style="min-height: 600px;"
            >
               <template #log_time="{ item: log }">{{ log.getLogTime() }}</template>
               <template #type="{ item: log }">{{ log.getLogType() }}</template>
               <template #created_at="{ item: log }">{{ trFormatDateLong(log.getCreatedAt()) }}</template>
               <template #actions="{ item: log }">
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiButton
                           size="sm"
                           color="lighter"
                           :text="$t('actions.download')"
                           @click="downloadLogFile(log.getDownloadUrl())"
                        />
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoadingLogs && !dataDeviceLogs.get('meta.total')" icon="device" />
            <ObiLoading v-if="isLoadingLogs" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataDeviceLogs.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>
   </ObiPage>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';

import { trFormatDateLong } from '@/libs/date-utils';

import DeviceLog from '@/api/Models/DeviceLog';

import PanelDeviceApi from '@/api/PanelDeviceApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconDevice from '@/components/Icons/general/IconDevice';
//import logFormData from '@/views/pages/Device/FormData/form-data-log';

export default {
   components: {},
   i18n: {
      messages: {
         tr: {
            page_title: 'Log Dosyaları',
            fields: {
               name: 'Log Tarihi',
               created_at: 'Oluşturulma Tarihi',
               type: 'Log Türü',
               actions: 'İşlemler',
               logging_types: 'Log Türleri',
               log_range: 'Tarih Aralığı',
            },
            actions: {
               create: 'Log Ekle',
               download: 'İndir',
               cancel: 'İptal Et',
               delete: 'Sil',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
         en: {
            page_title: 'Log Files',
            fields: {
               name: 'Log Date',
               created_at: 'Creation Date',
               type: 'Log Type',
               actions: 'Actions',
               logging_types: 'Log Types',
               log_range: 'Date Range',
            },
            actions: {
               create: 'Add Log',
               download: 'Download',
               cancel: 'Cancel',
               delete: 'Delete',
               yes: 'Yes',
               no: 'No',
            },
         },
      },
   },
   computed: {
      getSelectedDateRange() {
         return this.formDataQuery.log_range
            ? `${this.formDataQuery.log_range.start}-${this.formDataQuery.log_range.end}`
            : 'Bugün';
      },
   },
   data() {
      return {
         IconDevice,
         isLoadingLogs: false,
         isLoadingStore: false,
         showAddUserModal: false,
         isDeleting: false,
         editingLog: null,
         dataDeviceLogs: PaginationResponse.create().map(DeviceLog),
         shortcuts: [
            { key: 'thisWeek', label: 'Bu Hafta', value: 'isoWeek' },
            { key: 'lastWeek', label: 'Geçen Hafta', value: '-isoWeek' },
            { key: 'last7Days', label: 'Son 7 Gün', value: 7 },
            { key: 'last30Days', label: 'Son 30 Gün', value: 30 },
            { key: 'thisMonth', label: 'Bu Ay', value: 'month' },
            { key: 'lastMonth', label: 'Geçen Ay', value: '-month' },
            { key: 'thisYear', label: 'Bu Yıl', value: 'year' },
            { key: 'lastYear', label: 'Geçen Yıl', value: '-year' },
         ],
         formDataQuery: {
            types: ['firewall', 'dhcp', 'proxy'],
         },
         tableHeaders: [
            {
               text: this.$t('fields.name'),
               value: 'log_time',
               sortable: true,
            },
            {
               text: this.$t('fields.type'),
               value: 'type',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
         typeOptions: [
            {
               label: 'DHCP',
               id: 'dhcp',
            },
            {
               label: 'Proxy',
               id: 'proxy',
            },
            {
               label: 'Firewall',
               id: 'firewall',
            },
         ],
      };
   },

   async mounted() {
      await this.loadDeviceLogs();
   },

   methods: {
      trFormatDateLong,

      async onPageChanged(page) {
         await this.loadDeviceLogs({ page });
      },

      async loadDeviceLogs() {
         this.isLoadingLogs = true;

         try {
            const deviceId = this.$route.params.deviceId;
            const params = this.formDataQuery;

            this.dataDeviceLogs.merge(await PanelDeviceApi.logs.index(deviceId, params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingLogs = false;
         }
      },
      async downloadLogFile(logFile) {
         window.open(logFile, '_blank');
      },
      async onChanged(item) {
         console.log(item);
      },
   },
};
</script>

<style lang="scss" scoped></style>
