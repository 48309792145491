var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ObiPage',[_c('ObiCard',{staticClass:"my-4 no-border",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ObiTitle',{attrs:{"icon":_vm.IconDevice,"title":_vm.$t('page_title')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ObiButton',{attrs:{"color":"lighter","text":_vm.$t('actions.create'),"icon":"mdi mdi-plus"},on:{"click":function($event){return _vm.$refs.modalDeviceCreate.show()}}})]},proxy:true}])})]},proxy:true},{key:"body",fn:function(){return [(!_vm.isLoading && _vm.dataDevices.get('meta.total'))?_c('ObiTable',{attrs:{"headers":_vm.tableHeaders,"data":_vm.dataDevices.get('data')},on:{"rowClick":function($event){_vm.$router.push({ name: 'devices-show', params: { deviceId: $event.getId() } })}},scopedSlots:_vm._u([{key:"brand",fn:function({ item: device }){return [_c('ObiImage',{attrs:{"width":"65","src":device.getBrand().getLogo()}})]}},{key:"location",fn:function({ item: device }){return [_vm._v(_vm._s(device.getLocationFormatted()))]}},{key:"created_at",fn:function({ item: device }){return [_vm._v(_vm._s(device.getMacAddress()))]}},{key:"actions",fn:function({ item: device }){return [_c('ObiButton',{attrs:{"size":"sm","color":"primary","outline":"","text":_vm.$t('actions.edit')},on:{"click":function($event){$event.stopPropagation();_vm.$router.push({ name: 'devices-show', params: { deviceId: device.getId() } })}}}),_c('ObiButton',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                     loader: true,
                     okText: 'Evet',
                     cancelText: 'Hayır',
                     ok: (dialog) => _vm.deleteDevice(dialog, device),
                     message: 'Cihazı silmek istediğinize emin misiniz?',
                  }),expression:"{\n                     loader: true,\n                     okText: 'Evet',\n                     cancelText: 'Hayır',\n                     ok: (dialog) => deleteDevice(dialog, device),\n                     message: 'Cihazı silmek istediğinize emin misiniz?',\n                  }"}],staticClass:"ms-2",attrs:{"size":"sm","color":"danger","outline":"","text":_vm.$t('actions.delete')}})]}}],null,false,2922822226)}):(!_vm.isLoading && !_vm.dataDevices.get('meta.total'))?_c('Obi404',{attrs:{"icon":"devices"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ObiButton',{attrs:{"size":"lg","outline":"","wider":"","text":_vm.$t('actions.create')},on:{"click":function($event){return _vm.$refs.modalDeviceCreate.show()}}})]},proxy:true}])}):_vm._e(),(_vm.isLoading)?_c('ObiLoading'):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('ObiPagination',{attrs:{"meta":_vm.dataDevices.get('meta')},on:{"page-changed":_vm.onPageChanged}})]},proxy:true}])}),_c('ModalDeviceCreate',{ref:"modalDeviceCreate",on:{"success":_vm.loadDevices}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }