import LayoutDashboard from '@/views/layouts/Dashboard';
import LayoutChildPage from '@/views/layouts/ChildPage';
import DashboardIndex from '@/views/pages/Dashboard/Index';

import LocationEdit from '@/views/pages/Location/Edit';
import LocationIndex from '@/views/pages/Location/Index';
import LocationEditGeneral from '@/views/pages/Location/Edit/General';
import LocationEditSessions from '@/views/pages/Location/Edit/Sessions';
import LocationEditUserProfiles from '@/views/pages/Location/Edit/UserProfile';
import LocationEditUsers from '@/views/pages/Location/Edit/Users';

import DeviceEdit from '@/views/pages/Device/Edit';
import DeviceShow from '@/views/pages/Device/Edit/Dashboard';
import DeviceEditGeneral from '@/views/pages/Device/Edit/General';
import DeviceEditUsers from '@/views/pages/Device/Edit/Users';
import DeviceEditNetworks from '@/views/pages/Device/Edit/Networks';
import DeviceEditLogs from '@/views/pages/Device/Edit/Logs';
import DeviceEditMACs from '@/views/pages/Device/Edit/Macs';
import DeviceEditRules from '@/views/pages/Device/Edit/Rules';
import DeviceEditInfo from '@/views/pages/Device/Edit/Info';
import DeviceIndex from '@/views/pages/Device/Index';
import DeviceLicense from '@/views/pages/Device/Edit/License';

import PortalEdit from '@/views/pages/Portal/Edit';
import PortalIndex from '@/views/pages/Portal/Index';
import PortalEditGeneral from '@/views/pages/Portal/Edit/General';
import PortalEditRules from '@/views/pages/Portal/Edit/Rules';
import PortalEditSurveys from '@/views/pages/Portal/Edit/Surveys';
import PortalEditSignInMethods from '@/views/pages/Portal/Edit/SignInMethods';
import PortalEditAdvertisements from '@/views/pages/Portal/Edit/Advertisements';
import PortalEditAdvanced from '@/views/pages/Portal/Edit/Advanced';

import AccountIndex from '@/views/pages/Account/Index';
import AccountSettings from '@/views/pages/Account/Edit/Settings';
import AccountSecurity from '@/views/pages/Account/Edit/Security';

import SettingsIndex from '@/views/pages/Settings/index';
import SettingsEditGeneral from '@/views/pages/Settings/Edit/General';
import SettingsEditUsers from '@/views/pages/Settings/Edit/Users';

import IntegrationIndex from '@/views/pages/Integration/Index';
// import IntegrationCreate from '@/views/pages/Integration/Create';

import VisitorIndex from '@/views/pages/Visitor/Index';

import ReportIndex from '@/views/pages/Reports/Index';
import SaleReport from '@/views/pages/Reports/Component/SaleReport';
import MacSession from '@/views/pages/Reports/Component/MacSession';
import SessionHistory from '@/views/pages/Reports/Component/SessionHistory';

import QrMenuIndex from '@/views/pages/QrMenu/Index';
import QrMenuCategories from '@/views/pages/QrMenu/Edit/Categories';
import QrMenuDesks from '@/views/pages/QrMenu/Edit/Desks';
import QrMenuProducts from '@/views/pages/QrMenu/Edit/Products';

const routesDashboard = {
   path: '/',
   name: 'dashboard-index',
   component: DashboardIndex,
};

const routesLocation = {
   path: 'locations',
   component: LayoutChildPage,
   children: [
      {
         path: '/',
         name: 'locations-index',
         component: LocationIndex,
      },
      {
         path: ':locationId/edit',
         component: LocationEdit,
         children: [
            {
               path: '/',
               name: 'locations-edit',
               component: LocationEditGeneral,
            },
            {
               path: 'sessions',
               name: 'locations-edit-sessions',
               component: LocationEditSessions,
            },
            {
               path: 'users',
               name: 'locations-edit-users',
               component: LocationEditUsers,
            },
            {
               path: 'user-profiles',
               name: 'locations-user-profiles',
               component: LocationEditUserProfiles,
            },
         ],
      },
   ],
};

const routesDevice = {
   path: 'devices',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'devices-index',
         component: DeviceIndex,
      },
      {
         path: ':deviceId',
         component: DeviceEdit,
         children: [
            {
               path: '',
               name: 'devices-show',
               component: DeviceShow,
            },
            {
               path: 'edit',
               name: 'devices-edit',
               component: DeviceEditGeneral,
            },
            {
               path: 'users',
               name: 'devices-users',
               component: DeviceEditUsers,
            },
            {
               path: 'networks',
               name: 'devices-networks',
               component: DeviceEditNetworks,
            },
            {
               path: 'logs',
               name: 'devices-logs',
               component: DeviceEditLogs,
            },
            {
               path: 'macs',
               name: 'mac-addresses',
               component: DeviceEditMACs,
            },
            {
               path: 'info',
               name: 'devices-info',
               component: DeviceEditInfo,
            },
            {
               path: 'rules',
               name: 'devices-rules',
               component: DeviceEditRules,
            },
            {
               path: 'license',
               name: 'devices-license',
               component: DeviceLicense,
            },
         ],
      },
   ],
};

const routesPortal = {
   path: 'portals',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'portals-index',
         component: PortalIndex,
      },
      {
         path: ':portalId/edit',
         component: PortalEdit,
         children: [
            {
               path: '/',
               name: 'portals-edit',
               component: PortalEditGeneral,
            },
            {
               path: 'sign-in-methods',
               name: 'portals-edit-sign-in-methods',
               component: PortalEditSignInMethods,
            },
            {
               path: 'surveys',
               name: 'portals-edit-surveys',
               component: PortalEditSurveys,
            },
            {
               path: 'rules',
               name: 'portals-edit-rules',
               component: PortalEditRules,
            },
            {
               path: 'advertisements',
               name: 'portals-edit-advertisements',
               component: PortalEditAdvertisements,
            },
            {
               path: 'advanced',
               name: 'portals-edit-advanced',
               component: PortalEditAdvanced,
            },
         ],
      },
   ],
};

const routesIntegration = {
   path: 'integrations',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'integrations-index',
         component: IntegrationIndex,
      },
   ],
};

const routeQrMenu = {
   path: 'qrmenu',
   component: LayoutChildPage,
   children: [
      {
         path: '/',
         name: 'qrmenu-index',
         component: QrMenuIndex,
         children: [
            {
               path: 'categories',
               name: 'qrmenu-categories',
               component: QrMenuCategories,
            },
            {
               path: 'desks',
               name: 'qrmenu-desks',
               component: QrMenuDesks,
            },
            {
               path: 'products',
               name: 'qrmenu-products',
               component: QrMenuProducts,
            },
         ],
      },
   ],
};

const reportsIntegration = {
   path: 'reports',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'reports-index',
         component: ReportIndex,
         children: [
            {
               path: 'mac-sessions',
               name: 'multiple-sessions-by-mac',
               component: MacSession,
            },
            {
               path: 'sales-report',
               name: 'sales-report',
               component: SaleReport,
            },
            {
               path: 'session-history',
               name: 'session-history',
               component: SessionHistory,
            },
         ],
      },
   ],
};

const routesVisitor = {
   path: 'visitors',
   component: LayoutChildPage,
   children: [
      {
         path: '',
         name: 'visitors-index',
         component: VisitorIndex,
      },
   ],
};

const routesAccount = {
   path: 'account',
   component: LayoutChildPage,
   children: [
      {
         path: '/',
         name: 'account-index',
         component: AccountIndex,
         children: [
            {
               path: 'settings',
               name: 'account-settings',
               component: AccountSettings,
            },
            {
               path: 'security',
               name: 'account-security',
               component: AccountSecurity,
            },
         ],
      },
   ],
};

const routesSettings = {
   path: 'settings',
   component: LayoutChildPage,
   children: [
      {
         path: '/',
         name: 'settings-index',
         component: SettingsIndex,
         children: [
            {
               path: '/',
               name: 'settings-edit',
               component: SettingsEditGeneral,
            },
            {
               path: '/users',
               name: 'settings-users',
               component: SettingsEditUsers,
            },
         ],
      },
   ],
};

export default {
   path: '/',
   component: LayoutDashboard,
   children: [
      routesIntegration,
      routesDashboard,
      routesLocation,
      routesDevice,
      routesPortal,
      routesVisitor,
      routesAccount,
      routesSettings,
      reportsIntegration,
      routeQrMenu,
   ],
};
