<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiFloatingInput
         :label="$t('fields.mac')"
         :placeholder="$t('fields.mac')"
         v-model="formData.mac"
         @input="formatMAC"
      />

      <ObiFloatingInput :label="$t('fields.comment')" :placeholder="$t('fields.comment')" v-model="formData.comment" />

      <ObiTitle title="Uygulanacak Networkler" class="mb-2" v-if="availableNetworks && availableNetworks.length > 0" />
      <ObiButtonSwitch
         multi
         :items="availableNetworks"
         v-model="formData.networks"
         v-if="availableNetworks && availableNetworks.length > 0"
      />

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :disabled="!isSaveActive"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import Portal from '@/api/Models/Portal';
import PanelDeviceApi from '@/api/PanelDeviceApi';

export default {
   name: 'ModalLocationCreate',
   i18n: {
      messages: {
         tr: {
            title: 'MAC Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni mac ekleyin',
            fields: {
               mac: 'MAC Adresi',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               portal: 'Portal',
               comment: 'Açıklama',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add MAC',
            subtitle: 'Add a new MAC address by entering the information below',
            fields: {
               mac: 'MAC Address',
               country: 'Country',
               city: 'City',
               district: 'District',
               portal: 'Portal',
               comment: 'Comment',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },

   data() {
      return {
         isLoading: false,
         formData: { networks: [] },
         availableNetworks: [],
         dataDeviceNetworks: [],
         dataPortals: PaginationResponse.create().map(Portal),
      };
   },

   computed: {
      isSaveActive() {
         if (
            this.formData.mac &&
            this.formData.mac.length > 0 &&
            ((this.dataDeviceNetworks && this.dataDeviceNetworks.length === 0) ||
               (this.formData.networks && this.formData.networks.length > 0))
         ) {
            return true;
         }

         return false;
      },
   },
   async mounted() {
      this.loadDeviceNetworks();
   },
   methods: {
      show(type) {
         this.formData = { networks: [], type: type, mac: null };

         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         this.isLoading = true;

         try {
            const response = await PanelDeviceApi.macs.store(this.$route.params.deviceId, this.formData);

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
      async loadDeviceNetworks() {
         this.isLoading = true;

         try {
            const deviceId = this.$route.params.deviceId;

            const networks = await PanelDeviceApi.networks.index(deviceId);

            this.dataDeviceNetworks = networks.data;
            this.availableNetworks = [];
            this.formData.networks = [];

            networks.data.forEach((network) => {
               this.availableNetworks.push({ id: network.id, label: network.name });
               this.formData.networks.push(network.id);
            });
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      formatMAC(value) {
         if (value.length <= 17) {
            this.formData.mac = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.formData.mac = this.formData.mac.substr(0, 17);
         }
      },
   },
};
</script>
