import Http from '../libs/http';
import QrMenuCategoryApi from '@/api/QrMenuCategoryApi';
import QrMenuDeskApi from '@/api/QrMenuDeskApi';
import QrMenuProductApi from '@/api/QrMenuProductApi';

class QrMenuApi extends Http {
   get categories() {
      return QrMenuCategoryApi;
   }
   get desks() {
      return QrMenuDeskApi;
   }
   get products() {
      return QrMenuProductApi;
   }
}

export default new QrMenuApi();
