import Form from '@/libs/form';

const fields = ['id', 'title', 'description', 'number_start', 'number_end', 'prefix', 'number'];

const rules = {
   title: 'required',
};

export default Form.create(fields)
   .validate(rules)
   .defaults({ number_start: 1, number_end: 1, number: 1 });
