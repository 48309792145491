<template>
   <ObiPage>
      <ObiToolbar :icon="IconDevice" :title="formDataDevice.name" hideSearch>
         <template #title>
            <ObiText class="d-flex">
               <ObiImage width="200" v-if="formDataDevice" :src="formDataDevice.brand.logo" />
               <ObiTitle class="ms-4" :title="formDataDevice.name" />
            </ObiText>
         </template>
         <template #actions>
            <ObiButton
               v-if="formDataDevice && formDataDevice.brand && formDataDevice.brand.slug === 'mikrotik'"
               :text="$t('actions.download_login_html')"
               :loading="isFileDownloading"
               @click="downloadMikrotikLogin"
               class="me-2"
            />
            <ObiButton
               v-if="formDataDevice && formDataDevice.brand && formDataDevice.brand.slug === 'sophos'"
               :text="$t('actions.download_login_html')"
               :loading="isFileDownloading"
               @click="downloadSophosLogin"
               class="me-2"
            />
            <ObiButton
               class="px-4"
               :loading="isSaving"
               :text="$t('actions.save')"
               @click="submitDevicePut"
               v-if="showHeaderSaveButton"
            />
         </template>
      </ObiToolbar>

      <ObiCard class="my-4" v-if="!isLoading">
         <template #header v-if="formDataDevice.trial_remain > 0">
            <ObiAlert dark justify="center" v-if="formDataDevice.trial_remain > 0">
               <ObiText>
                  <h3>
                     Bu cihaz Demo sürecindedir. <br />Kalan Demo Günü: <b>{{ formDataDevice.trial_remain }}</b>
                  </h3>
               </ObiText>
            </ObiAlert>
            <ObiAlert
               danger
               justify="center"
               v-if="formDataDevice.trial_remain <= 0 && formDataDevice.license_remain <= 0"
            >
               <ObiText>
                  <h3>Demo süresi tamamlandığından, Bu cihaz için verilen hizmet durdurulmuştur.</h3>
               </ObiText>
            </ObiAlert>
            <ObiAlert
               warning
               justify="center"
               v-if="
                  isAdmin &&
                     (formDataDevice.is_trial ||
                        (formDataDevice.trial_remain <= 0 && formDataDevice.license_remain <= 0))
               "
            >
               <ObiText>
                  <h3>Lisanslamak için Lisans süresi seçin.</h3>
                  <ObiText class="d-flex">
                     <v-popover>
                        <ObiButton class="ms-2" color="success" text="1 Yıl" />

                        <template slot="popover">
                           <ObiText class="mb-2 mt-2">Cihaz 1 yıl Lisanslanacaktır!</ObiText>
                           <ObiButton
                              class="ms-2"
                              size="sm"
                              color="danger"
                              text="Lisansla"
                              v-close-popover
                              @click="applyLicense(1)"
                           />
                           <ObiButton class="ms-2" size="sm" color="warning" text="İptal" v-close-popover />
                        </template>
                     </v-popover>
                     <v-popover>
                        <ObiButton class="ms-2" color="success" text="2 Yıl" />

                        <template slot="popover">
                           <ObiText class="mb-2 mt-2">Cihaz 2 yıl Lisanslanacaktır!</ObiText>
                           <ObiButton
                              class="ms-2"
                              size="sm"
                              color="danger"
                              text="Lisansla"
                              v-close-popover
                              @click="applyLicense(2)"
                           />
                           <ObiButton class="ms-2" size="sm" color="warning" text="İptal" v-close-popover />
                        </template>
                     </v-popover>
                     <v-popover>
                        <ObiButton class="ms-2" color="success" text="3 Yıl" />

                        <template slot="popover">
                           <ObiText class="mb-2 mt-2">Cihaz 3 yıl Lisanslanacaktır!</ObiText>
                           <ObiButton
                              class="ms-2"
                              size="sm"
                              color="danger"
                              text="Lisansla"
                              v-close-popover
                              @click="applyLicense(3)"
                           />
                           <ObiButton class="ms-2" size="sm" color="warning" text="İptal" v-close-popover />
                        </template>
                     </v-popover>
                  </ObiText>
               </ObiText>
            </ObiAlert>
         </template>
         <template #body class="mt-4">
            <ObiTabBar>
               <template #tabs>
                  <ObiTabs :items="tabItems" @tabChanged="onTabChanged" v-model="selectedTab" />
               </template>

               <router-view :formDataDevice="formDataDevice" />
            </ObiTabBar>
         </template>

         <template #footer> </template>
      </ObiCard>

      <ObiLoading v-if="isLoading" />
   </ObiPage>
</template>

<script>
import { mapGetters } from 'vuex';
import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import PanelDeviceApi from '@/api/PanelDeviceApi';

import IconDevice from '@/components/Icons/general/IconDevice';

import formDataDevice from '@/views/pages/Device/FormData/form-data-device';

export default {
   i18n: {
      messages: {
         tr: {
            page_title: 'Cihaz Yönetimi',
            actions: {
               save: 'Kaydet',
               download_login_html: 'Login.html İndir',
            },
            tabItems: {
               general: 'Genel',
               info: 'Bilgiler',
               networks: 'Network Tanımları',
               logs: 'Log Dosyaları',
               mac_address: 'MAC Yönetimi',
            },
         },
         en: {
            page_title: 'Device Management',
            actions: {
               save: 'Save',
               download_login_html: 'Download Login.html',
            },
            tabItems: {
               general: 'General',
               info: 'Information',
               networks: 'Networks',
               logs: 'Log Files',
               mac_address: 'MAC Management',
            },
         },
      },
   },
   computed: {
      ...mapGetters({ isAdmin: 'auth/isAdmin' }),
      showHeaderSaveButton() {
         return this.$route.name === 'devices-edit';
      },
   },
   data() {
      return {
         selectedTab: null,
         IconDevice,
         formDataDevice,
         isFileDownloading: false,
         isSaving: false,
         isLoading: false,
         tabItems: [
            {
               id: 'devices-show',
               label: this.$t('tabItems.general'),
            },
            {
               id: 'devices-edit',
               label: this.$t('tabItems.info'),
            },
            {
               id: 'devices-networks',
               label: this.$t('tabItems.networks'),
            },
            {
               id: 'devices-logs',
               label: this.$t('tabItems.logs'),
            },
            {
               id: 'mac-addresses',
               label: this.$t('tabItems.mac_address'),
            },
            // {
            //    id: 'devices-rules',
            //    label: 'Kurallar'
            // },
            // {
            //    id: 'devices-users',
            //    label: 'Kullanıcılar'
            // }
         ],
      };
   },
   beforeMount() {
      this.selectedTab = this.$route.name;
   },
   methods: {
      async submitDevicePut() {
         const routeName = this.$route.name;

         if (routeName === 'devices-edit') {
            await this.submitDeviceGeneral();
         } else if (routeName === 'devices-edit-sessions') {
            await this.submitDeviceSessions();
         }
      },
      async submitDeviceGeneral() {
         if (!this.formDataDevice.isValid()) return;

         this.isSaving = true;

         try {
            const deviceId = this.$route.params.deviceId;

            await PanelDeviceApi.update(deviceId, this.formDataDevice.toObject());

            Notify.success(this.$t('Cihaz bilgileri güncellendi!'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isSaving = false;
         }
      },

      async onTabChanged(tabItem) {
         if (this.$route.name === tabItem) return;

         await this.$router.push({ name: tabItem, params: { ...this.$route.params } });
      },

      async downloadSophosLogin() {
         this.isFileDownloading = true;
         try {
            const response = await PanelDeviceApi.sophosDownloadLoginHtml(this.$route.params.deviceId, null);

            var fileURL = window.URL.createObjectURL(new Blob([response]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'login.html');
            document.body.appendChild(fileLink);
            fileLink.click();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isFileDownloading = false;
         }
      },
      async downloadMikrotikLogin() {
         this.isFileDownloading = true;
         try {
            const response = await PanelDeviceApi.downloadLoginHtml(this.$route.params.deviceId, null);

            var fileURL = window.URL.createObjectURL(new Blob([response]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'login.html');
            document.body.appendChild(fileLink);
            fileLink.click();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isFileDownloading = false;
         }
      },
      async applyLicense(year) {
         this.isLoading = true;
         try {
            await PanelDeviceApi.applyLicense(this.$route.params.deviceId, { year: year });
            Notify.success(this.$t('Cihaz lisanslandı'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>

<style lang="scss"></style>
