<template>
   <ObiPage>
      <ObiCard>
         <template #header>
            <ObiTitle class="mb-4" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalCategoryCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>
         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataCategory.get('data')"
               v-if="!isLoadingUsers && dataCategory.get('meta.total')"
            >
               <template #status="{ item: category }">
                  <span v-if="category.get('status') === 1">Gösteriliyor</span>
                  <span v-else>Gösterilmiyor</span>
               </template>
               <template #created_at="{ item: user }">{{ trFormatDate(user.getCreatedAt()) }}</template>
               <template #actions="{ item: user }">
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiButton
                           size="sm"
                           color="lighter"
                           :text="$t('actions.edit')"
                           @click="$refs.modalCategoryUpdate.show(user)"
                        />
                     </ObiCol>

                     <ObiCol col="6">
                        <v-popover>
                           <ObiButton :loading="isRevokingUser" size="sm" color="danger" :text="$t('actions.revoke')" />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_to_revoke')" />
                              <ObiButton
                                 class="ms-2"
                                 :loading="isRevokingUser"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 @click="destroy(user)"
                                 v-close-popover
                              />
                              <ObiButton
                                 class="ms-2"
                                 :loading="isRevokingUser"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoadingUsers && !dataCategory.get('meta.total')" icon="locations">
               <template #actions>
                  <ObiButton
                     size="lg"
                     outline
                     wider
                     :text="$t('actions.create')"
                     @click="$refs.modalCategoryCreate.show()"
                  />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoadingUsers" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataCategory.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <CategoryCreate ref="modalCategoryCreate" @success="loadCategories" />
      <CategoryUpdate ref="modalCategoryUpdate" @success="loadCategories" />
   </ObiPage>
</template>

<script>
import map from 'lodash/map';
import join from 'lodash/join';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import { trFormatDate } from '@/libs/date-utils';

import QrMenuApi from '@/api/QrMenuApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconLocation from '@/components/Icons/general/IconLocation';
import CategoryCreate from '@/views/pages/QrMenu/_Modals/CategoryCreate';
import CategoryUpdate from '@/views/pages/QrMenu/_Modals/CategoryUpdate';
import QrMenuCategory from '@/api/Models/QrMenuCategory';
import Invite from '@/api/Models/Invite';

export default {
   components: {
      CategoryCreate,
      CategoryUpdate,
   },

   i18n: {
      messages: {
         tr: {
            page_title: 'Ürün Kategorileri',
            invites_page_title: 'Bekleyen Davetiyeler',
            invites_404_title: 'Bekleyen Davetiyeniz Yok',
            are_you_sure_to_revoke: 'Kategoriyi silmek istediğinize emin misiniz?',
            fields: {
               parent: 'Üst Kategori',
               title: 'Başlık (TR)',
               description: 'Açıklama (TR)',
               title_en: 'Başlık (EN)',
               description_en: 'Açıklama (EN)',
               status: 'Durum',
               created_at: 'Oluşturulma Tarihi',
               actions: 'İşlemler',
               invited_at: 'Davet Tarihi',
            },
            actions: {
               create: 'Kategori Ekle',
               edit: 'Düzenle',
               resend: 'Tekrar Gönder',
               cancel: 'İptal Et',
               revoke: 'Kaldır',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
      },
   },
   data() {
      return {
         IconLocation,
         isLoadingUsers: false,
         isLoadingStore: false,
         showAddUserModal: false,
         isWorkingInvites: false,
         isLoadingInvites: false,
         isRevokingUser: false,
         dataCategory: PaginationResponse.create().map(QrMenuCategory),
         dataInvitedUsers: PaginationResponse.create().map(Invite),
         tableHeaders: [
            {
               text: this.$t('fields.title'),
               value: 'title',
               sortable: true,
            },
            {
               text: this.$t('fields.description'),
               value: 'description',
               sortable: true,
            },
            {
               text: this.$t('fields.status'),
               value: 'status',
               sortable: true,
            },
            {
               text: this.$t('fields.created_at'),
               value: 'created_at',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
      };
   },

   async mounted() {
      this.loadCategories();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadCategories({ page });
      },

      async loadCategories() {
         this.isLoadingUsers = true;

         try {
            this.dataCategory.merge(await QrMenuApi.categories.index());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingUsers = false;
         }
      },

      async destroy(user) {
         this.isRevokingUser = true;

         try {
            await QrMenuApi.categories.delete(user.getId());
            Notify.success(this.$t('Kategori silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isRevokingUser = false;
            this.loadCategories();
         }
      },

      async hidePopup() {
         this.$refs['revoke_popper'].doClose();
      },

      formatRoles(roles) {
         return join(
            map(roles, (role) => role.getName()),
            ', '
         );
      },
   },
};
</script>

<style lang="scss" scoped></style>
