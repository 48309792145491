<template>
   <ObiPage>
      <ObiCard>
         <template #header>
            <ObiTitle class="mb-4" :title="$t('page_title')">
               <template #actions>
                  <ObiButton
                     color="lighter"
                     :text="$t('actions.create')"
                     icon="mdi mdi-plus"
                     @click="$refs.modalCreate.show()"
                  />
               </template>
            </ObiTitle>
         </template>
         <template #body>
            <ObiTable
               :headers="tableHeaders"
               :data="dataList.get('data')"
               v-if="!isLoadingData && dataList.get('meta.total')"
            >
               <template #status="{ item: category }">
                  <span v-if="category.get('status') === 1">Gösteriliyor</span>
                  <span v-else>Gösterilmiyor</span>
               </template>
               <template #category_ids="{ item: category }">
                  {{
                     category
                        .get('category_ids')
                        .map((item) => item.title)
                        .toString()
                  }}
               </template>
               <template #created_at="{ item: user }">{{ trFormatDate(user.getCreatedAt()) }}</template>
               <template #actions="{ item: user }">
                  <ObiRow>
                     <ObiCol col="6">
                        <ObiButton
                           size="sm"
                           color="lighter"
                           :text="$t('actions.edit')"
                           @click="$refs.modalUpdate.show(user)"
                        />
                     </ObiCol>

                     <ObiCol col="6">
                        <v-popover>
                           <ObiButton :loading="isRevokingUser" size="sm" color="danger" :text="$t('actions.revoke')" />

                           <template slot="popover">
                              <ObiText class="mb-2 mt-2" v-html="$t('are_you_sure_to_revoke')" />
                              <ObiButton
                                 class="ms-2"
                                 :loading="isRevokingUser"
                                 size="sm"
                                 color="danger"
                                 :text="$t('actions.yes')"
                                 @click="destroy(user)"
                                 v-close-popover
                              />
                              <ObiButton
                                 class="ms-2"
                                 :loading="isRevokingUser"
                                 size="sm"
                                 color="warning"
                                 :text="$t('actions.no')"
                                 v-close-popover
                              />
                           </template>
                        </v-popover>
                     </ObiCol>
                  </ObiRow>
               </template>
            </ObiTable>
            <Obi404 v-else-if="!isLoadingData && !dataList.get('meta.total')" icon="locations">
               <template #actions>
                  <ObiButton size="lg" outline wider :text="$t('actions.create')" @click="$refs.modalCreate.show()" />
               </template>
            </Obi404>
            <ObiLoading v-if="isLoadingData" />
         </template>

         <template #footer>
            <ObiPagination :meta="dataList.get('meta')" @page-changed="onPageChanged" />
         </template>
      </ObiCard>

      <ModalCreate ref="modalCreate" @success="loadData" />
      <ModalUpdate ref="modalUpdate" @success="loadData" />
   </ObiPage>
</template>

<script>
import map from 'lodash/map';
import join from 'lodash/join';

import Notify from '@/libs/notify';
import ErrorHandler from '@/libs/error-handler';

import { trFormatDate } from '@/libs/date-utils';

import QrMenuApi from '@/api/QrMenuApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';

import IconLocation from '@/components/Icons/general/IconLocation';
import ModalCreate from '@/views/pages/QrMenu/_Modals/ProductCreate';
import ModalUpdate from '@/views/pages/QrMenu/_Modals/ProductUpdate';
import ModelObject from '@/api/Models/QrMenuProduct';

export default {
   components: {
      ModalCreate,
      ModalUpdate,
   },

   i18n: {
      messages: {
         tr: {
            page_title: 'Ürünler',
            are_you_sure_to_revoke: 'Ürünü silmek istediğinize emin misiniz?',
            fields: {
               parent: 'Üst Ürün',
               title: 'Başlık',
               description: 'Açıklama',
               created_at: 'Oluşturulma Tarihi',
               actions: 'İşlemler',
               status: 'Durum',
               category_ids: 'Kategoriler',
            },
            actions: {
               create: 'Ürün Ekle',
               edit: 'Düzenle',
               resend: 'Tekrar Gönder',
               cancel: 'İptal Et',
               revoke: 'Kaldır',
               yes: 'Evet',
               no: 'Hayır',
            },
         },
      },
   },
   data() {
      return {
         IconLocation,
         isLoadingData: false,
         isLoadingStore: false,
         showAddUserModal: false,
         isWorkingInvites: false,
         isLoadingInvites: false,
         isRevokingUser: false,
         dataList: PaginationResponse.create().map(ModelObject),
         tableHeaders: [
            {
               text: this.$t('fields.title'),
               value: 'title',
               sortable: true,
            },
            {
               text: this.$t('fields.description'),
               value: 'description',
               sortable: true,
            },
            {
               text: this.$t('fields.category_ids'),
               value: 'category_ids',
               sortable: true,
            },
            {
               text: this.$t('fields.status'),
               value: 'status',
               sortable: true,
            },
            {
               text: this.$t('fields.actions'),
               value: 'actions',
               sortable: false,
               class: 'text-center',
               width: 200,
            },
         ],
      };
   },

   async mounted() {
      this.loadData();
   },

   methods: {
      trFormatDate,

      async onPageChanged(page) {
         await this.loadData({ page });
      },

      async loadData() {
         this.isLoadingData = true;

         try {
            this.dataList.merge(await QrMenuApi.products.index());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingData = false;
         }
      },

      async destroy(desk) {
         this.isRevokingUser = true;

         try {
            await QrMenuApi.products.delete(desk.getId());
            Notify.success(this.$t('Ürün silindi'));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isRevokingUser = false;
            this.loadData();
         }
      },

      async hidePopup() {
         this.$refs['revoke_popper'].doClose();
      },

      formatRoles(roles) {
         return join(
            map(roles, (role) => role.getName()),
            ', '
         );
      },
   },
};
</script>

<style lang="scss" scoped></style>
