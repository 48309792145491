<template>
   <ObiRow>
      <ObiCol>
         <ObiCardMetric class="h-100" icon="" :loading="isLoadingCurrentYear" title="Bu Yıl" :metric="salesYear" />
      </ObiCol>
      <ObiCol>
         <ObiCardMetric class="h-100" icon="" :loading="isLoadingTodaySales" title="Bugün" :metric="salesToday" />
      </ObiCol>
      <ObiCol>
         <ObiCardMetric
            class="h-100"
            icon=""
            :loading="isLoadingCurrentWeek"
            title="Bu Hafta"
            :metric="salesCurrentWeek"
         />
      </ObiCol>
      <ObiCol>
         <ObiCardMetric
            class="h-100"
            icon=""
            :loading="isLoadingCurrentMonth"
            title="Bu Ay"
            :metric="salesCurrentMonth"
         />
      </ObiCol>

      <ObiCol col="12 mt-4">
         <ObiCard>
            <template #header>
               <ObiTitle title="Aylara Göre Satışlar" />
            </template>
            <ObiLoading v-if="isLoadingPerMonth" />
            <div v-else>
               <ObiNotFound
                  v-if="!dataSalesPerWeek || dataSalesPerWeek.valuesSum === 0"
                  type="user"
                  image-height="150"
                  title="Yeterli Veri yok"
               />
               <ObiBarChart v-else :chart-data="dataSalesPerWeek" :height="100" :options="chartOptions" />
            </div>
         </ObiCard>
      </ObiCol>
   </ObiRow>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelReportApi from '@/api/PanelReportApi';

export default {
   name: 'SaleReport',
   components: {},
   data() {
      return {
         isLoadingCurrentYear: false,
         isLoadingTodaySales: false,
         isLoadingCurrentWeek: false,
         isLoadingCurrentMonth: false,
         isLoadingPerMonth: false,
         salesByMonth: 0,
         salesCurrentMonth: 0,
         salesCurrentWeek: 0,
         salesToday: 0,
         salesYear: 0,
         dataSalesPerWeek: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         chartOptions: {
            responsive: true,
            legend: {
               display: false,
            },
            scales: {
               xAxes: [
                  {
                     gridLines: {
                        display: false,
                        drawBorder: false,
                     },
                  },
               ],
               yAxes: [
                  {
                     ticks: {
                        display: false,
                     },
                     gridLines: {
                        display: false,
                        drawBorder: false,
                     },
                  },
               ],
            },
         },
         customColors: [
            '#54B6DB',
            '#0099D3',
            '#009DCB',
            '#6FBEE5',
            '#007194',
            '#60BAE3',
            '#9CD0E8',
            '#6C89C3',
            '#54B6DB',
            '#2F71F2',
            '#0F9BDC',
            '#7CBFB7',
            '#0F9BDC',
            '#254B7C',
            '#60BAE3',
            '#9CD0E8',
            '#8FB1CA',
            '#227C97',
            '#4270A1',
            '#1A78AC',
            '#8CAEC9',
            '#6697CF',
            '#AFE0EE',
            '#84929D',
         ],
      };
   },
   async mounted() {
      this.getSalesCurrentYear();
      this.getSalesPerMonth();
      this.getSalesCurrentMonth();
      this.getSalesCurrentWeek();
      this.getSalesToday();
   },

   methods: {
      generateRandomColor(size) {
         let colors = [];
         if (size >= this.customColors.length) {
            for (let index = 0; index < size - this.customColors.length; index++) {
               this.customColors.push('#2F71F2');
            }
         }
         for (let index = 0; index < size; index++) {
            colors.push(this.customColors[index]);
         }
         return colors;
      },
      prepareChartsData(statsData, thickness, useCustomColors) {
         let result = { labels: [], datasets: [] };

         var valuesSum = 0;
         statsData.values.forEach((element) => {
            valuesSum += element;
         });

         result.valuesSum = valuesSum;

         result.labels = statsData.labels;
         result.datasets = [
            {
               barThickness: thickness,
               backgroundColor: useCustomColors ? this.generateRandomColor(statsData.values.length) : '#2F71F2',
               data: statsData.values,
            },
         ];

         return result;
      },
      async getSalesPerMonth() {
         this.isLoadingPerMonth = true;
         try {
            const response = await PanelReportApi.salesByMonth();
            if (response && response.length > 0) {
               this.dataSalesPerWeek = this.prepareChartsData(response, 15, true);
            }
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingPerMonth = false;
         }
      },
      async getSalesCurrentMonth() {
         this.isLoadingCurrentMonth = true;
         try {
            const response = await PanelReportApi.salesCurrentMonth();
            this.salesCurrentMonth = response.total;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingCurrentMonth = false;
         }
      },

      async getSalesCurrentWeek() {
         this.isLoadingCurrentWeek = true;
         try {
            const response = await PanelReportApi.salesCurrentWeek();
            this.salesCurrentWeek = response.total;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingCurrentWeek = false;
         }
      },
      async getSalesCurrentYear() {
         this.isLoadingCurrentYear = true;
         try {
            const response = await PanelReportApi.salesYear();
            this.salesYear = response.total;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingCurrentYear = false;
         }
      },
      async getSalesToday() {
         this.isLoadingTodaySales = true;
         try {
            const response = await PanelReportApi.salesToday();
            this.salesToday = response.total;
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingTodaySales = false;
         }
      },
   },
};
</script>
