import Form from '@/libs/form';

const fields = [
   'firstname',
   'lastname',
   'birth_date',
   'gender',
   'nationality',
   'room_number',
   'phone',
   'idNumber',
   'session_start_at',
   'session_end_at',
];

const rules = {
   firstname: 'required',
   lastname: 'required',
   birth_date: 'required',
   session_start_at: 'required',
   session_end_at: 'required',
   room_number: 'required',
};

export default Form.create(fields).validate(rules);
