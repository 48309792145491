<template>
   <ObiCard>
      <template #header>
         <ObiRow class="mt-2">
            <ObiCol col="3">
               <ObiTitle class="mb-2" color="primary" title="MAC Adresi" />
               <ObiFloatingInput
                  label="MAC Adresi"
                  :disabled="isLoading"
                  placeholder="MAC Adresi"
                  v-model="filterMac"
                  @input="formatMAC"
               />
            </ObiCol>
            <ObiCol col="3">
               <ObiTitle class="mb-2" color="primary" title="IP Adresi" />
               <ObiFloatingInput label="IP Adresi" :disabled="isLoading" placeholder="IP Adresi" v-model="user_ip" />
            </ObiCol>
            <ObiCol col="2">
               <ObiTitle class="mb-2" color="primary" title="Oturum Tarihi" />
               <ctk-date-time-picker
                  ref="datePicker"
                  :no-value-to-custom-elem="true"
                  locale="tr"
                  range
                  color="#2F71F2"
                  button-color="#2F71F2"
                  v-model="session_date"
                  format="DD.MM.YYYY"
                  outputFormat="YYYY-MM-DD hh:mm:ss"
                  :custom-shortcuts="shortcuts"
                  @validate="loadDeviceLogs()"
               >
                  <ObiInput clean compact readonly icon="mdi mdi-calendar" :placeholder="getSelectedDateRange" />
               </ctk-date-time-picker>
            </ObiCol>
            <ObiCol col="2">
               <ObiTitle class="mb-2" color="primary" title="&nbsp;" />
               <ObiButton
                  :disabled="isLoading"
                  class="btn-lg"
                  block
                  color="primary"
                  @click="getSessions(1)"
                  text="Filtrele"
               />
            </ObiCol>
            <ObiCol col="2">
               <ObiTitle class="mb-2" color="primary" title="&nbsp;" />
               <ObiButton
                  :disabled="isLoading"
                  class="btn-lg"
                  block
                  color="primary"
                  @click="downloadReport()"
                  text="İndir"
               />
            </ObiCol>
         </ObiRow>
      </template>
      <template #body>
         <ObiTable v-if="!isLoading" :data="dataMacSessions.get('data')" :headers="multipleSessionsTableHeaders">
            <template #acctstoptime="{ item: session }">{{
               session.getAccounting() !== null ? trFormatDateLong(session.getAccounting().acctstoptime) : null
            }}</template>
            <template #acctstarttime="{ item: session }">{{
               session.getAccounting() !== null ? trFormatDateLong(session.getAccounting().acctstarttime) : null
            }}</template>
            <template #acctoutputoctets="{ item: session }"
               >{{
                  session.getAccounting() !== null
                     ? parseFloat(session.getAccounting().acctoutputoctets / 1024 / 1024).toFixed(2)
                     : 0
               }}: mb</template
            >
            <template #acctinputoctets="{ item: session }"
               >{{
                  session.getAccounting() !== null
                     ? parseFloat(session.getAccounting().acctinputoctets / 1024 / 1024).toFixed(2)
                     : 0
               }}
               mb</template
            >
            <template #phone="{ item: session }">{{ session.getClientProvider() }}</template>
            <template #actions="{ item: session }">
               <ObiButton
                  :loading="isBlockingUser(session.getClientMac())"
                  class="me-2"
                  size="sm"
                  color="danger"
                  @click="blockMac(session)"
                  :text="$t('actions.block')"
               />
            </template>
         </ObiTable>
         <ObiLoading v-if="isLoading" />
      </template>
      <template #footer>
         <ObiPagination :meta="dataMacSessions.get('meta')" @page-changed="getSessions" />
      </template>
   </ObiCard>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelReportApi from '@/api/PanelReportApi';
import PaginationResponse from '@/api/DTO/PaginationResponse';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import MacSession from '@/api/Models/MacSession';
import { trFormatDateLong } from '@/libs/date-utils';

export default {
   name: 'MacSession',
   components: {},

   i18n: {
      messages: {
         tr: {
            page_title: 'Raporlar',
            fields: {
               client_mac: 'Mac Adresi',
               total_sessions: 'Toplam Oturum',
               actions: 'İşlemler',
            },
            actions: {
               block: 'Blokla',
            },
         },
         en: {
            page_title: 'Reports',
            fields: {
               client_mac: 'MAC Address',
               total_sessions: 'Total Sessions',
               actions: 'Operations',
            },
            actions: {
               block: 'Block',
            },
         },
      },
   },
   computed: {
      getSelectedDateRange() {
         return this.session_date ? `${this.session_date.start}-${this.session_date.end}` : 'Bugün';
      },
   },

   data() {
      return {
         dataMacSessions: PaginationResponse.create().map(MacSession),
         isLoading: false,
         filterMac: null,
         user_ip: null,
         session_date: null,
         blockingUsers: [],
         shortcuts: [
            { key: 'thisWeek', label: 'Bu Hafta', value: 'isoWeek' },
            { key: 'lastWeek', label: 'Geçen Hafta', value: '-isoWeek' },
            { key: 'last7Days', label: 'Son 7 Gün', value: 7 },
            { key: 'last30Days', label: 'Son 30 Gün', value: 30 },
            { key: 'thisMonth', label: 'Bu Ay', value: 'month' },
            { key: 'lastMonth', label: 'Geçen Ay', value: '-month' },
            { key: 'thisYear', label: 'Bu Yıl', value: 'year' },
            { key: 'lastYear', label: 'Geçen Yıl', value: '-year' },
         ],
         multipleSessionsTableHeaders: [
            {
               text: 'Kullanıcı',
               value: 'client.name',
               sortable: true,
            },
            {
               text: 'Telefon',
               value: 'phone',
               sortable: true,
            },
            {
               text: this.$t('fields.client_mac'),
               value: 'client_mac',
               sortable: true,
            },
            {
               text: 'IP Adresi',
               value: 'accounting.framedipaddress',
               sortable: true,
            },
            {
               text: 'Oturum Başlangıç',
               value: 'acctstarttime',
               sortable: false,
               width: 300,
            },
            {
               text: 'Oturum Bitiş',
               value: 'acctstoptime',
               sortable: false,
               width: 300,
            },
            {
               text: 'Toplam Download',
               value: 'acctinputoctets',
               sortable: false,
               width: 300,
            },
            {
               text: 'Toplam Upload',
               value: 'acctoutputoctets',
               sortable: false,
               width: 300,
            },
         ],
      };
   },
   async mounted() {
      await this.getSessions(1);
   },

   methods: {
      trFormatDateLong,
      isBlockingUser(mac) {
         return this.blockingUsers.indexOf(mac) > -1 ? true : false;
      },
      async downloadReport() {
         this.isLoading = true;
         try {
            const response = await PanelReportApi.sessions({
               per_page: 1000000,
               download: true,
               client_mac: this.filterMac,
               user_ip: this.user_ip,
               session_date: this.session_date,
            });

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'session-reports.csv');
            document.body.appendChild(link);
            link.click();
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async getSessions(page) {
         this.isLoading = true;
         try {
            const response = await PanelReportApi.sessions({
               per_page: 100,
               page: page,
               client_mac: this.filterMac,
               user_ip: this.user_ip,
               session_date: this.session_date,
            });
            this.dataMacSessions.merge(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      async blockMac(session) {
         this.blockingUsers.push(session.getClientMac());
         try {
            await PanelDeviceApi.macs.store(session.getDeviceId(), {
               mac: session.getClientMac(),
               comment: 'çoklu oturum tespiti',
               type: 'blocked',
            });
            this.dataMacSessions.get('data').splice(this.dataMacSessions.get('data').indexOf(session), 1);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.blockingUsers.splice(this.blockingUsers.indexOf(session.getClientMac()), 1);
         }
      },
      formatMAC(value) {
         if (value.length <= 17) {
            this.filterMac = (
               value
                  .toUpperCase()
                  .replace(/[^\d|A-Z]/g, '')
                  .match(/.{1,2}/g) || []
            ).join(':');
         } else {
            this.filterMac = this.filterMac.substr(0, 17);
         }
      },
   },
};
</script>
