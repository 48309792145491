<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconLayers" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiTitle class="mb-3" color="primary" :title="$t('form_title')" />

      <ObiRow>
         <ObiCol sm="6">
            <ObiFloatingInput
               :label="$t('fields.name')"
               :disabled="isLoading"
               :placeholder="$t('fields.name')"
               v-model="formData.name"
               :errors="formData.errors.get('name')"
            />
         </ObiCol>
         <ObiCol sm="6">
            <ObiFloatingInput
               :label="$t('fields.shared_users')"
               :disabled="isLoading"
               :placeholder="$t('fields.shared_users')"
               v-model="formData.shared_user"
               :errors="formData.errors.get('shared_users')"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow>
         <ObiCol sm="12">
            <ObiPaidSale
               title="Ücretli Satış"
               :disabled="isLoading"
               v-model="formData.paid_sale"
               :content="$t('paid_sale')"
               :options="currencyOptions"
            />
         </ObiCol>
      </ObiRow>
      <ObiRow>
         <ObiCol sm="12">
            <ObiSessionLimit
               title="Zaman Limiti"
               :disabled="isLoading"
               v-model="formData.session_duration"
               :content="$t('time_limit_content')"
               :options="timeOptions"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiSessionLimit
               title="Upload Hızı"
               :disabled="isLoading"
               v-model="formData.max_upload"
               :content="$t('upload_limit_content')"
               :options="speedOptions"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow class="mt-2">
         <ObiCol sm="12">
            <ObiSessionLimit
               title="Download Hızı"
               :disabled="isLoading"
               v-model="formData.max_download"
               :content="$t('download_limit_content')"
               :options="speedOptions"
            />
         </ObiCol>
      </ObiRow>

      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelLocationApi from '@/api/PanelLocationApi';

import ObiSessionLimit from '@/components/UI/ObiSessionLimit';
import IconLayers from '@/components/Icons/general/IconLayers.svg';

import Form from '@/libs/form';

export default {
   name: 'ModalUserProfileCreate',
   components: {
      ObiSessionLimit,
   },
   i18n: {
      messages: {
         tr: {
            title: 'Yeni Kullanıcı Profili Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni Kullanıcı Profili ekleyebilirsiniz',
            form_title: 'Kullanıcı Profili Bilgilerini Giriniz',
            time_limit_content: 'Konuklarınızın maksimum oturum süresini ayarlayın.',
            paid_sale: 'Misafirlerinize internet satışı yapmak için bu seçeneği aktifleştirin.',
            download_limit_content: 'Konuklarınızın maksimum indirme hızını ayarlayın.',
            upload_limit_content: 'Konuklarınızın maksimum yükleme hızını ayarlayın.',
            fields: {
               name: 'Profil Adı',
               shared_users: 'Eş Zamanlı Cihaz Sayısı',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add New User Profile',
            subtitle: 'You can add a new User Profile by entering the information below',
            form_title: 'Enter User Profile Information',
            time_limit_content: 'Set the maximum session duration for your guests.',
            paid_sale: 'Activate this option to sell internet to your guests.',
            download_limit_content: 'Set the maximum download speed for your guests.',
            upload_limit_content: 'Set the maximum upload speed for your guests.',
            fields: {
               name: 'Profile Name',
               shared_users: 'Concurrent Device Count',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },
   data() {
      return {
         IconLayers,
         isLoading: false,
         userProfileId: null,
         formData: Form.create(['name', 'session_duration', 'max_upload', 'max_download', 'shared_user', 'paid_sale'])
            .validate({
               name: 'required',
               shared_user: 'required',
            })
            .defaults({
               paid_sale: {
                  is_active: false,
                  option: 'euro',
                  value: 1,
               },
               session_duration: {
                  is_active: false,
                  option: 'day',
                  value: 1,
               },
               max_upload: {
                  is_active: false,
                  option: 'mbps',
                  value: 1,
               },
               max_download: {
                  is_active: false,
                  option: 'mbps',
                  value: 1,
               },
               shared_user: 1,
            }),
         timeOptions: [
            {
               label: 'Dakika',
               id: 'minute',
               min: 0,
               max: 60,
               step: 5,
            },
            {
               label: 'Saat',
               id: 'hour',
               min: 0,
               max: 24,
               step: 1,
            },
            {
               label: 'Gün',
               id: 'day',
               min: 0,
               max: 365,
               step: 1,
            },
         ],
         currencyOptions: [
            {
               label: 'EURO',
               id: 'eur',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'USD',
               id: 'usd',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'GBP',
               id: 'gbp',
               min: 0,
               max: 6000,
               step: 5,
            },
            {
               label: 'TRY',
               id: 'try',
               min: 0,
               max: 6000,
               step: 5,
            },
         ],
         speedOptions: [
            {
               label: 'Kbps',
               id: 'kbps',
               min: 0,
               max: 1024,
               step: 128,
            },
            {
               label: 'Mbps',
               id: 'mbps',
               min: 1,
               max: 100,
               step: 1,
            },
            {
               label: 'Gbps',
               id: 'gbps',
               min: 1,
               max: 100,
               step: 1,
            },
         ],
      };
   },
   methods: {
      show(userProfile) {
         this.userProfileId = userProfile.get('id');
         if (!userProfile.get('paid_sale')) {
            userProfile.set('paid_sale', {
               is_active: false,
               option: 'euro',
               value: 1,
            });
         }
         this.formData.merge(
            userProfile.only('name', 'session_duration', 'max_upload', 'max_download', 'shared_user', 'paid_sale')
         );
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formData.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelLocationApi.userProfiles.update(this.userProfileId, this.formData.toObject());

            this.formData.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },
   },
};
</script>
