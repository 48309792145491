import BaseModel from './BaseModel';
import Role from '@/api/Models/Role';

export default class User extends BaseModel {
   getId() {
      return this.get('id');
   }

   getFirstName() {
      return this.get('first_name');
   }

   getLastName() {
      return this.get('last_name');
   }

   getEmail() {
      return this.get('email');
   }

   getPhoneNumber() {
      return this.get('phone_number');
   }

   getFullName() {
      return this.get('full_name');
   }

   getToken() {
      return this.get('token');
   }

   getRoles() {
      return Role.collection(this.get('roles'));
   }
}
