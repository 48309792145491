<template>
   <ObiPage>
      <ObiToolbar :title="$t('page_title')" @actionSearch="applyFilter" hideSearch hideActionButtons />

      <ObiText class="obi-content">
         <ObiRow class="mb-4">
            <ObiCol col="4">
               <ObiRow class="h-100">
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100 bg-success text-white"
                        icon=""
                        :loading="isLoadingOnlineUser"
                        @click="showOnlineUser"
                        :title="$t('metrics.online_visitor')"
                        :metric="dataStatistics.getOnlineUserCount()"
                     />
                  </ObiCol>
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100 bg-danger text-white"
                        icon=""
                        :loading="isLoadingFailedLogin"
                        @click="showFailedLogin"
                        :title="$t('metrics.unsuccessful_logins')"
                        :metric="dataStatistics.getFailedLoginCount()"
                     />
                  </ObiCol>
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100 bg-info text-white"
                        icon=""
                        :title="$t('metrics.returned_users')"
                        :metric="dataStatistics.getReturnedUserCount()"
                     />
                  </ObiCol>
               </ObiRow>
            </ObiCol>
            <ObiCol col="8">
               <ObiRow class="h-100">
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100"
                        :icon="IconVisitor"
                        :title="$t('metrics.visitors_title')"
                        :metric="dataStatistics.getUserCount()"
                     />
                  </ObiCol>
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100"
                        :icon="IconDevice"
                        :title="$t('metrics.devices_title')"
                        :metric="dataStatistics.getDeviceCount()"
                     />
                  </ObiCol>
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100"
                        :icon="IconNotification"
                        :title="$t('metrics.notifications_title')"
                        :metric="dataStatistics.getNotificationCount()"
                     />
                  </ObiCol>
                  <ObiCol>
                     <ObiCardMetric
                        class="h-100"
                        :icon="IconIntegration"
                        :title="$t('metrics.integrations_title')"
                        :metric="dataStatistics.getIntegrationCount()"
                     />
                  </ObiCol>
               </ObiRow>
            </ObiCol>
         </ObiRow>

         <ObiRow class="mb-4">
            <ObiCol col="8">
               <ObiCard class="h-100">
                  <template #header>
                     <ObiTitle :title="$t('visitors.title')" />
                  </template>
                  <ObiLoading v-if="isLoadingVisitsStatistics && !firstLoadFinished" />
                  <div v-else>
                     <ObiNotFound
                        v-if="!dataVisitorBandwidth || dataVisitorBandwidth.valuesSum === 0"
                        type="user"
                        image-height="150"
                        :title="$t('visitors.statistic_not_enough')"
                     />
                     <ObiBarChart v-else :chart-data="dataVisitorBandwidth" :height="100" :options="chartOptions" />
                  </div>
               </ObiCard>
            </ObiCol>
            <ObiCol col="4">
               <ObiCard class="h-100" elevation="0">
                  <template #header>
                     <ObiTitle :title="$t('devices.title')" />
                  </template>

                  <ObiLoading v-if="isLoadingDevice && !firstLoadFinished" />

                  <div class="device-list" v-else>
                     <ObiNotFound
                        compact
                        type="device"
                        image-height="150"
                        :title="$t('devices.not_found_title')"
                        :description="$t('devices.not_found_description')"
                        v-if="!isLoadingDevice && !dataDevices.get('meta.total')"
                     />
                     <ObiText v-else>
                        <ObiDeviceCardMini
                           class="mb-2"
                           v-for="device in dataDevices.get('data')"
                           :key="device.id"
                           :device="device"
                        />
                     </ObiText>
                  </div>
               </ObiCard>
            </ObiCol>
         </ObiRow>

         <ObiRow class="mb-4">
            <ObiCol col="4">
               <ObiCard class="h-100 p-4">
                  <template #header>
                     <ObiTitle :title="$t('platforms_statistic_title')" />
                  </template>
                  <ObiLoading v-if="isLoadingPlatformsStatistics && !firstLoadFinished" />

                  <div v-else>
                     <ObiNotFound
                        v-if="!dataPlatformsStatistics || dataPlatformsStatistics.valuesSum === 0"
                        type="user"
                        image-height="150"
                        :title="$t('visitors.statistic_not_enough')"
                     />
                     <ObiPieChart v-else :chart-data="dataPlatformsStatistics" :options="chartOptions" />
                  </div>
               </ObiCard>
            </ObiCol>
            <ObiCol col="4">
               <ObiCard class="h-100">
                  <template #header>
                     <ObiTitle :title="$t('browsers_statistic_title')" />
                  </template>
                  <ObiLoading v-if="isLoadingBrowsersStatistics && !firstLoadFinished" />

                  <div v-else>
                     <ObiNotFound
                        v-if="!dataBrowsersStatistics || dataBrowsersStatistics.valuesSum === 0"
                        type="user"
                        image-height="150"
                        :title="$t('visitors.statistic_not_enough')"
                     />
                     <ObiPieChart v-else :chart-data="dataBrowsersStatistics" :options="chartOptions" />
                  </div>
               </ObiCard>
            </ObiCol>
            <ObiCol col="4">
               <ObiCard class="h-100">
                  <template #header>
                     <ObiTitle :title="$t('devices_statistic_title')" />
                  </template>
                  <ObiLoading v-if="isLoadingDevicesStatistics && !firstLoadFinished" />

                  <div v-else>
                     <ObiNotFound
                        v-if="!dataDevicesStatistics || dataDevicesStatistics.valuesSum === 0"
                        type="user"
                        image-height="150"
                        :title="$t('visitors.statistic_not_enough')"
                     />
                     <ObiPieChart v-else :chart-data="dataDevicesStatistics" :options="chartOptions" />
                  </div>
               </ObiCard>
            </ObiCol>
         </ObiRow>
      </ObiText>

      <ModalFailedLogin ref="modalFailedLogin" />
      <ModalOnlineUser ref="modalOnlineUser" />
   </ObiPage>
</template>

<script>
import IconDevice from '@/components/Icons/general/IconDevice';
import IconVisitor from '@/components/Icons/general/IconVisitor';
import IconLocation from '@/components/Icons/general/IconLocation';
import IconIntegration from '@/components/Icons/general/IconIntegration';
import IconNotification from '@/components/Icons/general/IconNotification';

import ErrorHandler from '@/libs/error-handler';
import PanelDeviceApi from '@/api/PanelDeviceApi';
import PanelStatisticApi from '@/api/PanelStatisticApi';
import PanelStateApi from '@/api/PanelStateApi';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import SingleResourceResponse from '@/api/DTO/SingleResourceResponse';
import Device from '@/api/Models/Device';
import StatisticOverview from '@/api/Models/StatisticOverview';
import ObiCardMetric from '@/components/UI/ObiCardMetric';

import ModalFailedLogin from '@/views/pages/Dashboard/_Modals/FailedLogin';
import ModalOnlineUser from '@/views/pages/Dashboard/_Modals/OnlineUser';

export default {
   name: 'PageDashboardIndex',

   components: {
      ObiCardMetric,
      ModalFailedLogin,
      ModalOnlineUser,
   },
   i18n: {
      messages: {
         tr: {
            page_title: 'Genel Bakış',
            platforms_statistic_title: 'Popüler Platfromlar',
            browsers_statistic_title: 'Popüler Tarayıcılar',
            devices_statistic_title: 'Popüler Cihaz Türleri',
            visitors: {
               title: 'Ziyaretçi Girişleri',
               statistic_not_enough: 'İstatistik için henüz yeterli veriye sahip değiliz :(',
            },
            devices: {
               title: 'Cihazlarım',
               create: 'Yeni Cihaz',
               not_found_title: 'Cihaz Bulunamadı',
               not_found_description:
                  'Hiç cihaz eklenmedi. Yeni cihaz eklemek için sağ üstteki Yeni Cihaz butonunu kullanabilirsiniz.',
            },
            metrics: {
               visitors_title: 'Kullanıcılar',
               devices_title: 'Cihazlar',
               locations_title: 'Konumlar',
               notifications_title: 'Bildirimler',
               integrations_title: 'Entegrasyonlar',
               online_visitor: 'Online Kullanıcı',
               unsuccessful_logins: 'Başarısız Girişler',
               returned_users: 'Tekrar Oturumlar',
            },
         },
         en: {
            page_title: 'Overview',
            platforms_statistic_title: 'Popular Platforms',
            browsers_statistic_title: 'Popular Browsers',
            devices_statistic_title: 'Popular Device Types',
            visitors: {
               title: 'Visitor Entries',
               statistic_not_enough: "We don't have enough data for statistics yet :(",
            },
            devices: {
               title: 'My Devices',
               create: 'New Device',
               not_found_title: 'Device Not Found',
               not_found_description:
                  'No devices have been added. You can use the New Device button in the upper right corner to add a new device.',
            },
            metrics: {
               visitors_title: 'Users',
               devices_title: 'Devices',
               locations_title: 'Locations',
               notifications_title: 'Notifications',
               integrations_title: 'Integrations',
               online_visitor: 'Online User',
               unsuccessful_logins: 'Failed Logins',
               returned_users: 'Return Sessions',
            },
         },
      },
   },

   computed: {
      /**
       * @returns {StatisticOverview}
       */
      dataStatistics() {
         return this.dataStatisticsOverview.getModel();
      },
   },

   data() {
      return {
         IconDevice,
         IconVisitor,
         IconLocation,
         IconIntegration,
         IconNotification,
         dataDevices: PaginationResponse.create().map(Device),
         dataStatisticsOverview: SingleResourceResponse.create().map(StatisticOverview),
         timer: null,
         firstLoadFinished: false,
         isLoadingDevice: false,
         isLoadingVisitsStatistics: false,
         isLoadingStatisticsOverview: false,
         isLoadingBrowsersStatistics: false,
         isLoadingPlatformsStatistics: false,
         isLoadingDevicesStatistics: false,
         isLoadingFailedLogin: false,
         isLoadingOnlineUser: false,
         dataVisitorBandwidth: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataPlatformsStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataBrowsersStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         dataDevicesStatistics: {
            labels: [],
            datasets: [],
            valuesSum: 0,
         },
         chartOptions: {
            responsive: true,
            legend: {
               display: false,
            },
            scales: {
               xAxes: [
                  {
                     gridLines: {
                        display: false,
                        drawBorder: false,
                     },
                  },
               ],
               yAxes: [
                  {
                     ticks: {
                        display: false,
                     },
                     gridLines: {
                        display: false,
                        drawBorder: false,
                     },
                  },
               ],
            },
         },
         customColors: [
            '#54B6DB',
            '#0099D3',
            '#009DCB',
            '#6FBEE5',
            '#007194',
            '#60BAE3',
            '#9CD0E8',
            '#6C89C3',
            '#54B6DB',
            '#2F71F2',
            '#0F9BDC',
            '#7CBFB7',
            '#0F9BDC',
            '#254B7C',
            '#60BAE3',
            '#9CD0E8',
            '#8FB1CA',
            '#227C97',
            '#4270A1',
            '#1A78AC',
            '#8CAEC9',
            '#6697CF',
            '#AFE0EE',
            '#84929D',
         ],
      };
   },

   async mounted() {
      await this.refreshData();

      this.timer = setInterval(() => {
         this.refreshData();
         this.firstLoadFinished = true;
      }, 1000 * 60);
   },

   beforeDestroy() {
      clearInterval(this.timer);
   },

   methods: {
      async applyFilter() {
         this.firstLoadFinished = false;
         await this.refreshData();
      },
      async refreshData() {
         this.loadVisitsStatistics();
         this.loadDevices();
         this.loadStatisticsOverview();
         this.loadPlatformStatistics();
         this.loadBrowserStatistics();
         this.loadDeviceStatistics();
      },
      async loadDevices() {
         this.isLoadingDevice = true;

         try {
            this.dataDevices.merge(await PanelDeviceApi.index());
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingDevice = false;
         }
      },

      async loadStatisticsOverview() {
         this.isLoadingStatisticsOverview = true;

         try {
            const params = this.$route.query;

            this.dataStatisticsOverview.merge(await PanelStatisticApi.overview(params));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingStatisticsOverview = false;
         }
      },

      generateRandomColor(size) {
         let colors = [];
         if (size >= this.customColors.length) {
            for (let index = 0; index < size - this.customColors.length; index++) {
               this.customColors.push('#2F71F2');
            }
         }
         for (let index = 0; index < size; index++) {
            colors.push(this.customColors[index]);
         }
         return colors;
      },

      prepareChartsData(statsData, thickness, useCustomColors) {
         let result = { labels: [], datasets: [] };

         var valuesSum = 0;
         statsData.values.forEach((element) => {
            valuesSum += element;
         });

         result.valuesSum = valuesSum;

         result.labels = statsData.labels;
         result.datasets = [
            {
               barThickness: thickness,
               backgroundColor: useCustomColors ? this.generateRandomColor(statsData.values.length) : '#2F71F2',
               data: statsData.values,
            },
         ];

         return result;
      },

      async loadPlatformStatistics() {
         this.isLoadingPlatformsStatistics = true;

         try {
            const params = this.$route.query;
            const statsData = await PanelStatisticApi.platforms(params);
            this.dataPlatformsStatistics = this.prepareChartsData(statsData.data, 5, true);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingPlatformsStatistics = false;
         }
      },

      async loadBrowserStatistics() {
         this.isLoadingBrowsersStatistics = true;

         try {
            const params = this.$route.query;
            const statsData = await PanelStatisticApi.browsers(params);
            this.dataBrowsersStatistics = this.prepareChartsData(statsData.data, 5, true);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingBrowsersStatistics = false;
         }
      },

      async loadDeviceStatistics() {
         this.isLoadingDevicesStatistics = true;

         try {
            const params = this.$route.query;
            const statsData = await PanelStatisticApi.devices(params);
            this.dataDevicesStatistics = this.prepareChartsData(statsData.data, 5, true);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingDevicesStatistics = false;
         }
      },

      async loadVisitsStatistics() {
         this.isLoadingVisitsStatistics = true;

         try {
            const params = this.$route.query;
            const statsData = await PanelStatisticApi.visits(params);
            this.dataVisitorBandwidth = this.prepareChartsData(statsData.data, 20, false);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingVisitsStatistics = false;
         }
      },

      statisticMetric(metricSource) {
         if (!this[metricSource]) return 0;

         return this[metricSource].get('meta.total');
      },

      statisticLoadingState(loadingKey) {
         if (!this[loadingKey]) return false;

         return this[loadingKey];
      },
      async showFailedLogin() {
         this.isLoadingFailedLogin = true;

         try {
            const params = this.$route.query;
            const { data } = await PanelStateApi.failedLogins(params);
            this.$refs.modalFailedLogin.show(data);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingFailedLogin = false;
         }
      },
      async showOnlineUser() {
         this.isLoadingOnlineUser = true;

         try {
            const params = this.$route.query;
            params.per_page = 200;
            const response = await PanelStateApi.onlines(params);
            this.$refs.modalOnlineUser.show(response);
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoadingOnlineUser = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-content {
   padding: 1rem;
   background-color: #f8f8f8;
}

.device-list {
   max-height: 300px;
   overflow: auto;
}

.stat-item-wrap {
   border-radius: 10px;

   .stat-item {
      gap: 12px;
      display: flex;
      padding-top: 30px;
      align-items: center;
      padding-bottom: 30px;
      flex-direction: column;

      &:hover {
         color: #fff;
         background-color: #2f71f2;
      }

      .stat-item-metric {
         font-size: 24px;
      }
   }
}
</style>
