<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconVisitor" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiSelect
         v-if="locationProfileOptions"
         :label="$t('fields.user_profile')"
         v-model="formDataVisitor.profile_id"
         :options="locationProfileOptions"
         :placeholder="$t('fields.user_profile_id')"
         :errors="formDataVisitor.errors.get('user_profile_id')"
      />

      <ObiFloatingInput
         :label="$t('fields.name')"
         :disabled="isLoading"
         :placeholder="$t('fields.name')"
         v-model="formDataVisitor.name"
         :errors="formDataVisitor.errors.get('name')"
      />

      <ObiTitle class="mb-3" color="primary" :title="$t('fields.provider_type')" />
      <ObiCol col="12" class="mb-3"
         ><ObiButtonSwitch :items="dataAuthTypes" v-model="formDataVisitor.provider_type"
      /></ObiCol>

      <ObiFloatingInput
         v-if="formDataVisitor.provider_type == 'citizen-id'"
         :label="$t('fields.citizen_id')"
         :disabled="isLoading"
         :placeholder="$t('fields.citizen_id')"
         v-model="formDataVisitor.provider_id"
         :errors="formDataVisitor.errors.get('provider_id')"
      />

      <ObiFloatingInput
         v-if="formDataVisitor.provider_type == 'room-number'"
         :label="$t('fields.room_number')"
         :disabled="isLoading"
         :placeholder="$t('fields.room_number')"
         v-model="formDataVisitor.room_number"
         :errors="formDataVisitor.errors.get('room_number')"
      />

      <ObiRow v-if="formDataVisitor.provider_type == 'username'">
         <ObiCol col="6">
            <ObiFloatingInput
               :label="$t('fields.username')"
               :disabled="isLoading"
               :placeholder="$t('fields.username')"
               v-model="formDataVisitor.username"
               :errors="formDataVisitor.errors.get('username')"
            />
         </ObiCol>
         <ObiCol col="6">
            <ObiFloatingInput
               :label="$t('fields.password')"
               :disabled="isLoading"
               :placeholder="$t('fields.password')"
               v-model="formDataVisitor.password"
               :errors="formDataVisitor.errors.get('password')"
            />
         </ObiCol>
      </ObiRow>

      <ObiRow v-if="formDataVisitor.provider_type !== 'username'">
         <ObiCol col="4">
            <ObiSelect
               transparent
               :label="$t('fields.birth_day')"
               v-model="formDataVisitor.birth_day"
               :options="days"
               :placeholder="$t('fields.birth_day')"
               :errors="formDataVisitor.errors.get('fields.birth_day')"
            />
         </ObiCol>
         <ObiCol col="4">
            <ObiSelect
               transparent
               :label="$t('fields.birth_month')"
               v-model="formDataVisitor.birth_month"
               :options="months"
               :placeholder="$t('fields.birth_month')"
               :errors="formDataVisitor.errors.get('fields.birth_month')"
            />
         </ObiCol>
         <ObiCol col="4">
            <ObiSelect
               transparent
               :label="$t('fields.birth_year')"
               v-model="formDataVisitor.birth_year"
               :options="years"
               :placeholder="$t('fields.birth_year')"
               :errors="formDataVisitor.errors.get('fields.birth_year')"
            />
         </ObiCol>
      </ObiRow>

      <ObiTitle class="mb-2" color="primary" title="Profil Özelleştirme" />
      <ObiButtonSwitch :items="dataSpecialProfiles" v-model="formDataVisitor.special_profile" />
      <ObiRow class="mt-4" v-if="formDataVisitor.special_profile">
         <ObiCol sm="12">
            <ObiFloatingInput
               :label="$t('shared_users')"
               :disabled="isLoading"
               :placeholder="$t('shared_users')"
               v-model="formDataVisitor.shared_user"
               type="number"
            />
         </ObiCol>
         <ObiCol class="mt-2" sm="12">
            <ObiSessionLimit
               title="Zaman Limiti"
               :disabled="isLoading"
               v-model="formDataVisitor.session_duration"
               :content="$t('time_limit_content')"
               :options="timeOptions"
            />
         </ObiCol>
         <ObiCol class="mt-2" col="12">
            <ObiSessionLimit
               class="mb-2"
               title="Upload Hızı"
               v-model="formDataVisitor.max_upload"
               :content="$t('upload_limit_content')"
               :options="speedOptions"
            />
         </ObiCol>
         <ObiCol class="mt-2" col="12">
            <ObiSessionLimit
               class="mb-2"
               title="Download Hızı"
               v-model="formDataVisitor.max_download"
               :content="$t('download_limit_content')"
               :options="speedOptions"
            />
         </ObiCol>
      </ObiRow>
      <template slot="actions">
         <ObiButton
            wider
            icon-right
            type="submit"
            :loading="isLoading"
            :text="$t('actions.save')"
            icon="mdi mdi-arrow-right"
         />
      </template>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelVisitorApi from '@/api/PanelVisitorApi';

import IconVisitor from '@/components/Icons/general/IconVisitor';

import formDataVisitor from '@/views/pages/Visitor/FormData/form-data-visitor';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import PanelLocationApi from '@/api/PanelLocationApi';
import LocationUserProfile from '@/api/Models/LocationUserProfile';
import ObiSessionLimit from '@/components/UI/ObiSessionLimit';

formDataVisitor.defaults({
   max_upload: {
      is_active: false,
      option: 'mbps',
      value: 1,
   },
   max_download: {
      is_active: false,
      option: 'mbps',
      value: 1,
   },
   session_duration: {
      is_active: false,
      option: 'day',
      value: 1,
   },
   special_profile: false,
});
export default {
   name: 'ModalVisitorCreate',
   components: {
      ObiSessionLimit,
   },
   i18n: {
      messages: {
         tr: {
            title: 'Kullanıcı Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni kullanıcı ekleyin',
            form_title: 'Kullanıcı Bilgileri',
            upload_limit_content: 'Kullanıcının maksimum yükleme hızını ayarlayın.',
            download_limit_content: 'Kullanıcının maksimum indirme hızını ayarlayın.',
            time_limit_content: 'Kullanıcının maksimum oturum süresini ayarlayın.',
            shared_users: 'Eş Zamanlı Cihaz Sayısı',
            fields: {
               name: 'Adı & Soyadı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               location: 'Konum',
               birth_day: 'Doğum Gün',
               birth_month: 'Doğum Ay',
               birth_year: 'Doğum Yıl',
               provider_type: 'Doğrulama Yöntemi',
               citizen_id: 'TCK Numarası',
               room_number: 'Oda Numarası',
               username: 'Kullanıcı Adı',
               password: 'Parola',
               user_profile: 'Oturum Profili',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add User',
            subtitle: 'Enter the information below to add a new user',
            form_title: 'User Information',
            upload_limit_content: 'Set the maximum upload speed for the user.',
            download_limit_content: 'Set the maximum download speed for the user.',
            time_limit_content: 'Set the maximum session duration for the user.',
            shared_users: 'Concurrent Device Count',
            fields: {
               name: 'First & Last Name',
               country: 'Country',
               city: 'City',
               district: 'District',
               location: 'Location',
               birth_day: 'Birth Day',
               birth_month: 'Birth Month',
               birth_year: 'Birth Year',
               provider_type: 'Authentication Method',
               citizen_id: 'Citizen ID Number',
               room_number: 'Room Number',
               username: 'Username',
               password: 'Password',
               user_profile: 'Session Profile',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },

   data() {
      return {
         IconVisitor,
         isLoading: false,
         formDataVisitor,
         dataUserProfiles: PaginationResponse.create().map(LocationUserProfile),
         dataAuthTypes: [
            { id: 'citizen-id', label: 'TC Kimlik Numarası' },
            { id: 'username', label: 'Kullanıcı Adı & Parola' },
            { id: 'room-number', label: 'Oda Numarası' },
         ],
         days: [],
         months: [],
         years: [],
         speedOptions: [
            {
               label: 'Kbps',
               id: 'kbps',
               min: 0,
               max: 1024,
               step: 128,
            },
            {
               label: 'Mbps',
               id: 'mbps',
               min: 1,
               max: 100,
               step: 1,
            },
            {
               label: 'Gbps',
               id: 'gbps',
               min: 1,
               max: 100,
               step: 1,
            },
         ],
         dataSpecialProfiles: [
            { id: true, label: 'Açık' },
            { id: false, label: 'Kapalı' },
         ],
         timeOptions: [
            {
               label: 'Dakika',
               id: 'minute',
               min: 0,
               max: 60,
               step: 5,
            },
            {
               label: 'Saat',
               id: 'hour',
               min: 0,
               max: 24,
               step: 1,
            },
            {
               label: 'Gün',
               id: 'day',
               min: 0,
               max: 365,
               step: 1,
            },
         ],
      };
   },

   computed: {
      locationProfileOptions() {
         return this.dataUserProfiles
            .get('data')
            .map((profile) => ({ label: profile.getName(), value: profile.getId() }));
      },
   },
   async mounted() {
      this.setYears();
      this.loadUserProfile();
   },

   methods: {
      setYears() {
         const startYear = 1930;
         var lastYear = new Date().getFullYear();

         for (lastYear; lastYear >= startYear; lastYear--) {
            this.years.push({ label: lastYear, value: lastYear });
         }

         for (var day = 1; day <= 31; day++) {
            this.days.push({ label: day, value: day });
         }

         for (var month = 1; month <= 12; month++) {
            this.months.push({ label: month, value: month });
         }
      },
      show() {
         this.formDataVisitor.reset();
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formDataVisitor.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelVisitorApi.store(this.formDataVisitor.toObject());

            this.formDataVisitor.reset();

            this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async loadUserProfile() {
         this.isLoading = true;

         try {
            this.dataUserProfiles.merge(await PanelLocationApi.userProfiles.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
   },
};
</script>
