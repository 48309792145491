<template>
   <ObiModal as="form" ref="modal" @submit.prevent="submitForm">
      <template #header>
         <ObiTitle :icon="IconVisitor" icon-stacked :title="$t('title')" :subtitle="$t('subtitle')" />
      </template>

      <ObiFloatingInput
         v-if="!usersCreated"
         label="Toplam Kullanıcı Sayısı"
         :disabled="isLoading"
         placeholder="Toplam Kullanıcı Sayısı"
         v-model="formDataVisitor.total_user"
         :errors="formDataVisitor.errors.get('total_user')"
      />

      <template slot="actions" v-if="!usersCreated">
         <ObiButton wider icon-right type="submit" :loading="isLoading" text="Oluştur" icon="mdi mdi-arrow-right" />
      </template>
      <template slot="actions" v-if="usersCreated">
         <ObiButton
            wider
            icon-right
            @click="printPassword()"
            :loading="isLoading"
            text="Yazdır"
            icon="mdi mdi-arrow-right"
         />
      </template>

      <div v-if="usersCreated" class="row" id="printThisArea">
         <ObiText class="obi-dcm mb-4 col-md-3" v-for="(user, i) in createdUsers" :key="i">
            <ObiText class="obi-dcm-content" style="border: 2px solid; padding: 5px; font-size: 16px;">
               <ObiText class="obi-dcm-image text-center">
                  <img width="240" src="https://cdn.obifi.io/panel/431/2023_05_09_12_55_21_AWviuHoFMOtgnBA.png" />
               </ObiText>
               <ObiText class="text-center mb-4">Güvenli İnternet Erişimi</ObiText>
               <ObiText class="obi-dcm-name">Kullanıcı Adı/Username: {{ user.username }}</ObiText>
               <ObiText class="obi-dcm-name">Parola/Password: {{ user.password }}</ObiText>
            </ObiText>
         </ObiText>
      </div>
   </ObiModal>
</template>

<script>
import ErrorHandler from '@/libs/error-handler';
import PanelVisitorApi from '@/api/PanelVisitorApi';

import IconVisitor from '@/components/Icons/general/IconVisitor';

import formDataVisitor from '@/views/pages/Visitor/FormData/form-data-bulk-visitor';

import PaginationResponse from '@/api/DTO/PaginationResponse';
import PanelLocationApi from '@/api/PanelLocationApi';
import LocationUserProfile from '@/api/Models/LocationUserProfile';

formDataVisitor.defaults({
   max_upload: {
      is_active: false,
      option: 'mbps',
      value: 1,
   },
   max_download: {
      is_active: false,
      option: 'mbps',
      value: 1,
   },
   session_duration: {
      is_active: false,
      option: 'day',
      value: 1,
   },
   special_profile: false,
});
export default {
   name: 'ModalVisitorCreate',
   components: {},
   i18n: {
      messages: {
         tr: {
            title: 'Toplu Kullanıcı Ekle',
            subtitle: 'Aşağıdaki bilgileri girerek yeni kullanıcı ekleyin',
            form_title: 'Kullanıcı Bilgileri',
            upload_limit_content: 'Kullanıcının maksimum yükleme hızını ayarlayın.',
            download_limit_content: 'Kullanıcının maksimum indirme hızını ayarlayın.',
            time_limit_content: 'Kullanıcının maksimum oturum süresini ayarlayın.',
            shared_users: 'Eş Zamanlı Cihaz Sayısı',
            fields: {
               name: 'Adı & Soyadı',
               country: 'Ülke',
               city: 'Şehir',
               district: 'İlçe',
               location: 'Konum',
               birth_day: 'Doğum Gün',
               birth_month: 'Doğum Ay',
               birth_year: 'Doğum Yıl',
               provider_type: 'Doğrulama Yöntemi',
               citizen_id: 'TCK Numarası',
               room_number: 'Oda Numarası',
               username: 'Kullanıcı Adı',
               password: 'Parola',
               user_profile: 'Oturum Profili',
            },
            actions: {
               save: 'Kaydet',
            },
         },
         en: {
            title: 'Add Users in Bulk',
            subtitle: 'Enter the information below to add a new user',
            form_title: 'User Information',
            upload_limit_content: 'Set the maximum upload speed for the user.',
            download_limit_content: 'Set the maximum download speed for the user.',
            time_limit_content: 'Set the maximum session duration for the user.',
            shared_users: 'Concurrent Device Count',
            fields: {
               name: 'First & Last Name',
               country: 'Country',
               city: 'City',
               district: 'District',
               location: 'Location',
               birth_day: 'Birth Day',
               birth_month: 'Birth Month',
               birth_year: 'Birth Year',
               provider_type: 'Authentication Method',
               citizen_id: 'Citizen ID Number',
               room_number: 'Room Number',
               username: 'Username',
               password: 'Password',
               user_profile: 'Session Profile',
            },
            actions: {
               save: 'Save',
            },
         },
      },
   },

   data() {
      return {
         IconVisitor,
         isLoading: false,
         usersCreated: false,
         createdUsers: [],
         formDataVisitor,
         dataUserProfiles: PaginationResponse.create().map(LocationUserProfile),
         dataAuthTypes: [
            { id: 'citizen-id', label: 'TC Kimlik Numarası' },
            { id: 'username', label: 'Kullanıcı Adı & Parola' },
            { id: 'room-number', label: 'Oda Numarası' },
         ],
         days: [],
         months: [],
         years: [],
         speedOptions: [
            {
               label: 'Kbps',
               id: 'kbps',
               min: 0,
               max: 1024,
               step: 128,
            },
            {
               label: 'Mbps',
               id: 'mbps',
               min: 1,
               max: 100,
               step: 1,
            },
            {
               label: 'Gbps',
               id: 'gbps',
               min: 1,
               max: 100,
               step: 1,
            },
         ],
         dataSpecialProfiles: [
            { id: true, label: 'Açık' },
            { id: false, label: 'Kapalı' },
         ],
         timeOptions: [
            {
               label: 'Dakika',
               id: 'minute',
               min: 0,
               max: 60,
               step: 5,
            },
            {
               label: 'Saat',
               id: 'hour',
               min: 0,
               max: 24,
               step: 1,
            },
            {
               label: 'Gün',
               id: 'day',
               min: 0,
               max: 365,
               step: 1,
            },
         ],
      };
   },

   computed: {
      locationProfileOptions() {
         return this.dataUserProfiles
            .get('data')
            .map((profile) => ({ label: profile.getName(), value: profile.getId() }));
      },
   },
   async mounted() {
      this.setYears();
      this.loadUserProfile();
   },

   methods: {
      setYears() {
         const startYear = 1930;
         var lastYear = new Date().getFullYear();

         for (lastYear; lastYear >= startYear; lastYear--) {
            this.years.push({ label: lastYear, value: lastYear });
         }

         for (var day = 1; day <= 31; day++) {
            this.days.push({ label: day, value: day });
         }

         for (var month = 1; month <= 12; month++) {
            this.months.push({ label: month, value: month });
         }
      },
      show() {
         this.formDataVisitor.reset();
         this.$refs.modal.show();
      },
      hide() {
         this.$refs.modal.hide();
      },
      async submitForm() {
         if (!this.formDataVisitor.isValid({ disableDirtyCheck: true })) return;

         this.isLoading = true;

         try {
            const response = await PanelVisitorApi.bulk(this.formDataVisitor.toObject());

            this.formDataVisitor.reset();
            console.log(response.data);
            this.usersCreated = true;
            this.createdUsers = response.data;
            //this.hide();
            this.$emit('success', response);
         } catch (err) {
            ErrorHandler.handle(err);

            this.$emit('failed', err);
         } finally {
            this.isLoading = false;

            this.$emit('end');
         }
      },

      async loadUserProfile() {
         this.isLoading = true;

         try {
            this.dataUserProfiles.merge(await PanelLocationApi.userProfiles.index({ per_page: 999 }));
         } catch (err) {
            ErrorHandler.handle(err);
         } finally {
            this.isLoading = false;
         }
      },
      printPassword() {
         var innerHtml = window.document.getElementById('printThisArea').innerHTML;
         var newWindow = window.open('', '', 'width=100, height=100');
         var document = newWindow.document.open();
         var pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>Paswords</title>' +
            '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
            '<link href="https://www.anemonhotels.com/endpoint/Content/bootstrap.css" rel="stylesheet" />' +
            '</head>' +
            '<body><div class="row">' +
            innerHtml +
            '</div></body></html>';
         document.write(pageContent);
         document.close();
         newWindow.moveTo(0, 0);
         newWindow.resizeTo(screen.width, screen.height);
      },
   },
};
</script>
